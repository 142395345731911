import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { faChevronLeft, faChevronRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const profileImage = require('../../assets/defaultprofile.svg').default;
function Notifications() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedata, setPaginateData] = useState({});
    
    const fetchPreviewData = useCallback(async (params = {}) => {
        try {
            const defaultParams = {...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`notifications?${queryString}`);
            if (response && response.success === true) {
                setNotifications(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchPreviewData({ page: newPage });
    };

    const markAllAsRead = async () => {
        try {
            const response = await apiService.postRequest('/notifications/mark-as-read');
            if (response && response.success === true) {
                fetchPreviewData();
                toast("All Marked as read");
            }
        } catch (error) {
            console.error("Error marking all as read:", error);
        }
    };
    
    useEffect(() => {
        fetchPreviewData();
    }, [fetchPreviewData]);

    const markAsRead = async (id = null) => {
        try {
            const payload = id ? { notification_id: id } : { clear_all: true };
            const response = await apiService.postRequest('/notifications/mark-as-read', payload);
            
            if (response && response.success === true) {
                toast(id ? "Marked as read" : "All notifications cleared");
                fetchPreviewData();
            }
        } catch (error) {
            console.error(`Error marking notification${id ? ` ${id}` : 's'} as read:`, error);
        }
    };

    const handleDelete = async (date, id) => {
        try {
            const response = await apiService.deleteRequest(`/notifications/${id}`);
                if (response && response.success === true) {
                    setNotifications(prevNotifications => ({
                        ...prevNotifications,
                        [date]: prevNotifications[date].filter(notification => notification.id !== id)
                    }));
                }
        } catch (error) {
            console.error("Error deleting notification:", error);
        }
    };

  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{xs:12}} container justifyContent="center" className="notification_conatiner">
                <Grid size={{xs:12}} container flexDirection="column" className="jobseekerhead">
                    <h3>Notification</h3>
                </Grid>
                <Grid size={{xs:12}} container justifyContent="space-between" className="notifications_btn gap-3 my-4">
                    <Grid container justifyContent="space-between" className="gap-3">
                    <button onClick={markAllAsRead}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.2 17.7002C11.9 17.7002 11.7 17.6002 11.5 17.4002L7.3 13.2002C6.9 12.8002 6.9 12.2002 7.3 11.8002C7.7 11.4002 8.3 11.4002 8.7 11.8002L12.2 15.3002L20 7.5002C20.4 7.1002 21 7.1002 21.4 7.5002C21.8 7.9002 21.8 8.5002 21.4 8.9002L12.9 17.4002C12.8 17.6002 12.5 17.7002 12.2 17.7002Z" fill=""/>
                            <path d="M7.2 17.7002C6.9 17.7002 6.7 17.6002 6.5 17.4002L2.3 13.2002C1.9 12.8002 1.9 12.2002 2.3 11.8002C2.7 11.4002 3.3 11.4002 3.7 11.8002L7.9 16.0002C8.3 16.4002 8.3 17.0002 7.9 17.4002C7.8 17.6002 7.5 17.7002 7.2 17.7002ZM12.5 12.5002C12.2 12.5002 12 12.4002 11.8 12.2002C11.4 11.8002 11.4 11.2002 11.8 10.8002L15 7.5002C15.4 7.1002 16 7.1002 16.4 7.5002C16.8 7.9002 16.8 8.5002 16.4 8.9002L13.2 12.2002C13 12.4002 12.8 12.5002 12.5 12.5002Z" fill=""/>
                        </svg>
                        Mark as all read
                    </button>
                    <button onClick={() => markAsRead()}>Clear all</button>
                    </Grid>
                    <button onClick={()=>navigate(-1)}>Back</button>
                </Grid>
                {loading ? 
                <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                    <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                </div> :<>
                {notifications && Object.keys(notifications).length > 0 ? (
                Object.keys(notifications).map(date => (
                    <div key={date} className='w-100 d-flex flex-column align-items-center forbackliners'>
                    <small className='datewithshows'>{date}</small>
                    {notifications[date].map(notification => (
                        <Grid size={{xs:12}} key={notification.id} container justifyContent="center" alignItems="center" className="noti_contentgrid mt-2">
                            <Grid size={{xs:11}} container className={notification.is_read === 1 ? "innernoti_content" : "innernoti_content bg_unread_dotalso"} alignItems="center" onClick={notification.is_read === 0 ? () => markAsRead(notification.id) : null}>
                                <Grid size={{sm:1,xs:2}} container justifyContent="center">
                                    <img src={notification.profile || profileImage} alt=''/>
                                </Grid>
                                <Grid size={{sm:11,xs:10}} container flexDirection='column'>
                                    <span><b>{notification.title}</b>{notification.message}</span>
                                    <small>{notification.created_at}</small>
                                </Grid>
                            </Grid>
                            <Grid size={{xs:1}} container justifyContent="center">
                                <button onClick={() => handleDelete(date, notification.id)}><FontAwesomeIcon icon={faXmark} /></button>
                            </Grid>
                        </Grid>
                    ))}
                    </div>
                ))
                ) : (
                    <p>No notifications available.</p>
                )}</>}
                <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage - 1)} 
                        disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                        <button 
                            key={index} 
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? 'active' : ''}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className='arrowpaginate'
                        onClick={() => handlePageChange(currentPage + 1)} 
                        disabled={currentPage === paginatedata.last_page}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </Grid>
            </Grid>
            
        </div>
    </div>
  )
}

export default Notifications

import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../../../services/apiService';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';
function TaskHistory() {
    const taskId = localStorage.getItem('onBoardTaskID');
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const [showhistory, setvalidateHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    

    const fetchData = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const defaultParams = { ...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`/task/${taskId}/history?${queryString}`);
            if (response && response.success === true) {
                setvalidateHistory(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, [taskId]);
    
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchData({ page: newPage });
    };
    useEffect(() => {
        fetchData();
    }, [fetchData,onboardslug]);
  return (
    <div className='container-fluid px-0'>
                    {loading ? 
                    <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                        <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    {showhistory && showhistory.length > 0 ? ( showhistory && showhistory.map((history, index) => (
                        <Box key={index} sx={{ mb: 4, overflow: "auto" }}>
                            <Grid size={{xs:12}} container justifyContent="space-between" className="span_timeshowers mt-3">
                                <span>{history.datetime}</span>
                                <span><b>Updated by</b> {history.updated_by}</span>
                            </Grid>

                        {/* Data Grid */}
                        <Box sx={{ width: "100%", mt: 2 }} className="dtagridhistory_pagi">
                            <DataGrid
                            rows={history.fields.map((field, fieldIndex) => ({
                                id: fieldIndex,
                                field: field.field_name,
                                before: field.before || "N/A",
                                after: field.after || "N/A",
                            }))}
                            columns={[
                                {
                                    field: 'field',
                                    headerName: 'Field',
                                    width: 500,
                                    renderCell: (params) => (
                                        <div className='d-flex align-items-center h-100'>
                                            <span className='firstlett_caps'>
                                            {
                                            params.row.field === "assignment_group_id" ? "Assignment Group" : 
                                            params.row.field === "assigned_user_id" ? "Assigned User" : 
                                            params.row.field === "reason" ? "Reason" : 
                                            params.row.field === "valid_i94_doc" ? "Valid I94 document" : 
                                            params.row.field === "passport_number" ? "Passport Number" :
                                            params.row.field === "first_name" ? "First Name" :
                                            params.row.field === "last_name" ? "Last Name" : 
                                            params.row.field === "jobseeker_type" ? "Jobseeker type" : 
                                            params.row.field === "dob" ? "Date Of Birth" : 
                                            params.row.field === "i94_document" ? "I-94 document" :
                                            params.row.field === "status" ? "Status" :
                                            // Verify member field
                                            onboardslug === "verify-member-details" && params.row.field === "Q00010" ? "Is Referrer in Good Standing" :
                                            onboardslug === "verify-member-details" && params.row.field === "Q00020" ? "Referrer relationship with the Jobseeker" :
                                            onboardslug === "verify-member-details" && params.row.field === "Q00030" ? "Evidence of Contacting the Referrer" :
                                            // Verify member field
                                            onboardslug === "member-confirmation" && params.row.field === "Q00010" ? "Do you know the Jobseeker" :
                                            onboardslug === "member-confirmation" && params.row.field === "Q00020" ? "Agree to potential impact on relationship due to Referee disputes" :
                                            onboardslug === "member-confirmation" && params.row.field === "Q00030" ? "Acknowledge legal action for Pay After Placements disputes?" :
                                            // Referral Investigation
                                            onboardslug === "referral-investigation" && params.row.field === "Q00010" ? "Conclusion Type" :
                                            onboardslug === "referral-investigation" && params.row.field === "Q00020" ? "Report of the Investigation" :
                                            // Engagement Overview
                                            onboardslug === "engagement-overview" && params.row.field === "Q00010" ? "Jobseeker need Placement Solution" :
                                            onboardslug === "engagement-overview" && params.row.field === "Q00020" ? "Applicable Placement Types":
                                            onboardslug === "engagement-overview" && params.row.field === "Q00030" ? "Jobseeker need Immigration or Employment Solution":
                                            onboardslug === "engagement-overview" && params.row.field === "Q00031" ? "Applicable Immigration Solutions":
                                            onboardslug === "engagement-overview" && params.row.field === "Q00032" ? "Communication Eligibility and Communication Training Program":
                                            onboardslug === "engagement-overview" && params.row.field === "Q00033" ? "Technical Training Program":
                                            onboardslug === "engagement-overview" && params.row.field === "Q00034" ? "Admission Fees" :
                                            // Jobseeker Classification
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00010" ? "Referral Verification Status" :
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00020" ? "Referral Verification Decision Change Comments":
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00030" ? "Work Autherization expirartion date":
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00040" ? "Jobseeker seek for a ServiceNow Job":
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00050" ? "Jobseeker previosuly holds atleast 1 servicenow project experience":
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00060" ? "Spouse of the jobseeker a ServiceNow recourse":
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00070" ? "Full name of the Jobseeker's Spouse" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00080" ? "spouse of the Jobseeker hold/held any ServiceNow Certifications" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00090" ? "ServiceNow certifications owned by the spouse of Jobseeker" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00100" ? "LinkedIn URL of the spouse" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00110" ? "Jobseeker hold/held any ServiceNow Certifications" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00120" ? "ServiceNow certifications owned by the Jobseeker" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00130" ? "Start Date of the Project" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00140" ? "End Date of the Project" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00150" ? "Employer" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00160" ? "Vendor(s) or Implementation Partners" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00170" ? "End Client" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00180" ? "Job Title" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00190" ? "Job Duties" : 
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00200" ? "Jobseeker ever on a Placement Contract with Tekpossible" :
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00210" ? "Jobseeker previously holds at least 3 years of Agile experience" :
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00220" ? "Experience of the project(s) where Jobseeker worked on Agile" :
                                            onboardslug === "jobseeker-classification" && params.row.field === "Q00230" ? "Candidate willing to re-locate for the job" :
                                            // setup-servicenow-interview
                                            onboardslug === "setup-servicenow-interview" && params.row.field === "Q00010" ? "Who is the SME?" :
                                            onboardslug === "setup-servicenow-interview" && params.row.field === "Q00020" ? "Interview Start date and time" :
                                            onboardslug === "setup-servicenow-interview" && params.row.field === "Q00030" ? "Interview End date and time" :
                                            // servicenow-interview-evaluation
                                            onboardslug === "servicenow-interview-evaluation" && params.row.field === "Q00010" ? "Believe that the Candidate is a True and Expereinced ServiceNow Resource":
                                            onboardslug === "servicenow-interview-evaluation" && params.row.field === "Q00020" ? "Comments" :
                                            // past-payment-health-approval
                                            onboardslug === "past-payment-health-approval" && params.row.field === "Q00010" ? "Number of delayed payment":
                                            onboardslug === "past-payment-health-approval" && params.row.field === "Q00020" ? "Other Comments" :
                                            // Application Final Review
                                            onboardslug === "application-final-review" && params.row.field === "Q00010" ? "Move forward with the Jobseeker":
                                            onboardslug === "application-final-review" && params.row.field === "Q00020" ? "Jobseeker Classification" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00030" ? "C2C Deal Type" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00031" ? "C2C Fixed Margin For TekP" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00032" ? "C2C Fixed Ratio for TekP on Vendor Hourly Billing Rate" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00033" ? "W2 Deal Type" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00034" ? "W2 Fixed Margin for TekP" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00035" ? "W2 Fixed Ratio for TekP on Employer Hourly Billing Rate (Before Tax)" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00036" ? "FTE Deal Type" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00037" ? "FTE Fixed Margin for TekP" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00038" ? "FTE Fixed Ratio for TekP on Employer Annual Billing Rate (Before Tax)" :
                                            onboardslug === "application-final-review" && params.row.field === "Q00050" ? "Comments" :
                                            // placement agreement
                                            onboardslug === "placement-agreement" && params.row.field === "Q00010" ? "Agree with our Placement Agreement" :
                                            // placement agreement
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00010" ? "Pronounciation": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00020" ? "Reading Speed": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00030" ? "Reading Accuracy": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00040" ? "Casualness and laughter": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00050" ? "Plan B": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00060" ? "Body and Face Language": 
                                            onboardslug === "communication-eligibility" && params.row.field === "Q00070" ? "Result" :
                                            // profile change
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.first_name" ? "First Name": 
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.last_name" ? "Last Name" :
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.phone_number" ? "Phone Number":
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.dob" ? "Date of Birth":
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.gender" ? "Gender":
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.address" ? "Address":
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.state" ? "State" :
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.city" ? "City" :
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.zipcode" ? "Zipcode" :
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.why_enroll" ? "Why enroll with us":
                                            onboardslug === "my_request_profile" && params.row.field === "personal_info.profile" ? "Profile":

                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.citizenship_status" ? "Citizenship Status":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.awaiting_ead_card" ? "Awaiting EAD card":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.citizenship_explanation" ? "Citizenship Explanation":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.legally_authorized_to_work" ? "Legally authorized to work":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.ssn_issue_year" ? "SSN issue year":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.work_authorization_expiry" ? "Work Authorization Expiry":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.renew_authorization_work" ? "Renew Authorization work":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.laws_and_regulations" ? "Laws and Regulations":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.i94_copy" ? "I-94 copy":
                                            onboardslug === "my_request_profile" && params.row.field === "immigration_data.proof_of_work_authorization" ? "Proof of work Authorization":

                                            onboardslug === "my_request_profile" && params.row.field === "emergency_contact.first_name" ? "Emergency contact First Name":
                                            onboardslug === "my_request_profile" && params.row.field === "emergency_contact.last_name" ? "Emergency contact Last Name":
                                            onboardslug === "my_request_profile" && params.row.field === "emergency_contact.phone_number" ? "Emergency contact Phone Number":
                                            onboardslug === "my_request_profile" && params.row.field === "emergency_contact.relation_type" ? "Emergency contact Relation Type":

                                            onboardslug === "my_request_profile" && params.row.field === "referral_info.referrer_name" ? "Referrer Name":
                                            onboardslug === "my_request_profile" && params.row.field === "referral_info.referral_phone_number" ? "Last Name":
                                            onboardslug === "my_request_profile" && params.row.field === "referral_info.referrer_relationship" ? "Last Name"
                                            : ""
                                            }

                                        </span>
                                        </div>
                                    ),
                                    filterable: true
                                },
                                { field: "before", headerName: "Before", width: 500 },
                                { field: "after", headerName: "After", width: 500 },
                            ]}
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar }}
                            />
                        </Box>
                        </Box>
                    ))) : (
                        <div className="w-100 d-flex justify-content-center mt-4">
                            <p>No data to display</p>
                        </div>
                    )}</>}
                    <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
                        <button className='arrowpaginate'
                            onClick={() => handlePageChange(currentPage - 1)} 
                            disabled={currentPage === 1}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </button>
                        {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                            <button 
                                key={index} 
                                onClick={() => handlePageChange(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button className='arrowpaginate'
                            onClick={() => handlePageChange(currentPage + 1)} 
                            disabled={currentPage === paginatedata.last_page}>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </button>
                    </Grid>
        </div>
  )
}

export default TaskHistory

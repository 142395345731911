import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
// import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
import {loadStripe} from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import { Tab, Tabs } from 'react-bootstrap';
import TaskKnowledge from '../I94Validation/TaskKnowledge';
function AdmissionFeesPayment() {
    const onboardslug = localStorage.getItem('onboardingSlugs');
        const { userid } = useParams();
        const Role_user = localStorage.getItem('role');
        const navigate = useNavigate();
        const taskData = localStorage.getItem('onBoardTaskID');
        const [loading, setLoading] = useState(true);
        const [status, setStatus] = useState("");
        const [jobseekerName, setJobseekername] = useState("");
        const [caseids, setcaseid] = useState("");
        const [taskids, settaskid] = useState("");
        const [activeTab, setActiveTab] = useState("overview");
        useEffect(() => {
            const fetchData = async () => {
                try {
                const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
                if (response.success && response.success === true) {
                    localStorage.setItem('onBoardTaskID', response.data.task.id);
                    setJobseekername(response.data.jobseeker.name);
                    setcaseid(response.data.task.case_id);
                    settaskid(response.data.task.task_format_id);
                    setStatus(response.data.status_string);
                    const refetchedData = response.settings;
                    setFormData({
                        onboarding_admission_fee: refetchedData.onboarding_admission_fee,
                        onboarding_service_charge: refetchedData.onboarding_service_charge,
                        onboarding_tax: refetchedData.onboarding_tax,
                        onboarding_total_string: refetchedData.onboarding_total_string,
                        onboarding_total: refetchedData.onboarding_total,
                    });
                    setstripeData({
                        payment_type:  "admission_fees_payment",
                        task_id: taskData,
                        amount: refetchedData.onboarding_total,
                        user_id: userid,
                        success_url: `https://frontend-version-2.benchtrack.in/task/admission-fees-payment/${userid}`,
                        cancel_url: `https://frontend-version-2.benchtrack.in/task/admission-fees-payment/${userid}`
                    });
                    
                }
                } catch (error) {
                console.error("Error fetching onboarding data:", error);
                } finally {
                setLoading(false);
                }
            };
        
            fetchData();
        }, [onboardslug, userid,taskData]);
    
        // useEffect(() => {
        //     const fetchCurrentData = async () => {
        //         try {
        //         const response = await apiService.getRequest(`/current-onboarding/${userid}`);
        //         if (response.success && response.success === true) {
        //             if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug)){
        //                 setShowedit(true);
        //             }else{
        //                 setShowedit(false)
        //             }
        //         }
        //         } catch (error) {
        //         console.error("Error fetching onboarding data:", error);
        //         } finally {
        //         setLoading(false);
        //         }
        //     };
        
        //     fetchCurrentData();
        // }, [ userid,onboardslug,keyReload]);
        const [formData, setFormData] = useState({
            default_currency: '',
            onboarding_admission_fee: '',
            onboarding_service_charge: '',
            onboarding_tax: '',
            onboarding_total: '',
            onboarding_total_string: '',
        });
        const [sripeData, setstripeData] = useState({
            payment_type:  "admission_fees_payment",
            task_id: taskData,
            amount: formData.onboarding_total,
            user_id: userid,
            success_url: `https://frontend-version-2.benchtrack.in/task/admission-fees-payment/${userid}`,
            cancel_url: `https://frontend-version-2.benchtrack.in/task/admission-fees-payment/${userid}`
        });
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
        const [submitloader,setSubmitloader] = useState(false)
        const submitsripe = async (e) => {
            e.preventDefault();
            setSubmitloader(true);
            try {
                const response = await apiService.postRequest(`stripe/create-checkout-session`, sripeData);
        
                // Check response for success
                if (response && response.success && response.id) {
                    const stripe = await stripePromise;
                    await stripe.redirectToCheckout({sessionId:response.id});
                } else {
                    toast("Failed, Please try again");
                    
                }
            } catch (error) {
                console('Failed. Please try again.');
            } finally {
                setSubmitloader(false); // Stop loader
            }
        };
        const [searchParams] = useSearchParams();
        const sessionId = searchParams.get("session_id");
        const fetchData = useCallback(async () => {
            setLoading(true);
            if (sessionId && sessionId.length > 0) {
                const sessionset = { session_id: sessionId };
                try {
                    const response = await apiService.postRequest(`/stripe/success`, sessionset);
                    if (response.success && response.success === true) {
                        toast("Payment Completed");
                    }else{
                        toast.error("Payment Failed");
                    }
                } catch (error) {
                    console.error("Error fetching onboarding data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }, [sessionId]);
    
        useEffect(() => {
            fetchData();
        }, [fetchData]);
  return (
    <div className='container-fluid px-0'>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
            <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>Admission Fees</h3>
                        <b>{jobseekerName}</b>
                    </Grid>
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                {status && status !== "completed" ? <>
                                    <Grid size={{xs:12}} container className="mt-5">
                                        <span><img src={require("../../../../assets/admissionfee.svg").default} alt='Fee'/> This fee covers application processing, registration materials.</span>
                                    </Grid>
                                    <Grid size={{xs:12}} container className="mt-5 admission_shows">
                                        <span>Admission Fee <b>{formData.onboarding_admission_fee}</b></span>
                                        <span>Service Charge <b>{formData.onboarding_service_charge}</b></span>
                                        <span>Tax <b>{formData.onboarding_tax}</b></span>
                                        <span className='totalspan_admissionshow'>Total <b>{formData.onboarding_total_string}</b></span>
                                    </Grid>
                                    {Role_user && Role_user === "job_seeker" ?
                                    <Grid size={{xs:12}} container justifyContent="flex-end">
                                        <button className='registersendbtn2 px-4' style={{width:"max-content"}} onClick={submitsripe}>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Pay Admission Fees'}</button>
                                    </Grid> : ""}
                                    </> : <>
                                    <Grid size={{xs:12}} container justifyContent="center" className="fessthanks my-5">
                                        <FontAwesomeIcon className='main_tickiconpopup' icon={faCircleCheck} />
                                        <h3>Thank you for your payment!</h3>
                                        <small>Your receipt has been emailed to you.</small>
                                        <Grid size={{md:10,xs:12}} container justifyContent="space-evenly" className="thaks_topline">
                                            <span>Amount Paid:<b>{formData.onboarding_total_string}</b></span>
                                            <span>Paid by:<b>Stripe</b></span>
                                        </Grid>
                                    </Grid>
                                    </>}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>
                            </Tabs>
                        </Grid>
                    </>}
                    
                    {/* <Grid size={{xs:12}} container justifyContent="center" className="fessthanks">
                        <FontAwesomeIcon className='main_crossred' icon={faCircleXmark} />
                        <h3>Transaction Failed!</h3>
                        <small>Please try again!</small>
                    </Grid> */}
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default AdmissionFeesPayment

import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid2';
import "./homepage.css";
import ReviewCarosel from '../../components/carosel/ReviewCarosel';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
function Homepage() {
    const [isJobSeekersActive, setIsJobSeekersActive] = useState(true);

    const handleJobSeekersClick = () => {
        setIsJobSeekersActive(true);
    };

    const handleEmployersClick = () => {
        setIsJobSeekersActive(false);
    };

    const [submitloader, setSubmitloader] = useState(false);
    const [value, setValue] = useState(dayjs(null));    
        const [formData, setFormData] = useState({
            first_name: '',
            last_name: '',
            phone_number: '',
            email_id: '',
            request_type: 'report_technical_issue',
            issue: '',
            issue_date: '',
            short_description: '',
            description: '',
        });
        const [errors, setErrors] = useState({});
        const [errorMessage, setErrorMessage] = useState('');
    
        const handleInputChange = (e) => {
            const { name, value } = e.target;
        
            // Clear the error for the specific field being modified
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '',
            }));
    
            setFormData({ ...formData, [name]: value });
        };
        const handleDateChange = (newValue) => {
            if (newValue) {
                setValue(newValue);
                setFormData({ ...formData, issue_date: newValue.format('YYYY-MM-DD') });
        
                // Clear the error for issue_date if a valid date is entered
                if (errors.issue_date) {
                    setErrors({ ...errors, issue_date: '' });
                }
            } else {
                setValue(null);
                setFormData({ ...formData, issue_date: '' }); // Clear the value if null
            }
        };
    
          const [files, setFiles] = useState([]);
            //   const [newName, setNewName] = useState('');
              const [error, setError] = useState('');
              const fileInputRef = useRef(null);
          const MAX_FILES = 10;
          const MAX_TOTAL_SIZE_MB = 2500;
          const MAX_FILE_SIZE_MB = 250;
          const allowedTypes = [
              'image/jpeg', 'image/png', 'image/gif',
              'application/pdf',
              'text/csv',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ];
      
          const handleFileChange = (event) => {
              const selectedFiles = Array.from(event.target.files);
      
              // Check if total number of files exceeds limit
              if (files.length + selectedFiles.length > MAX_FILES) {
                  setError(`You can only upload up to ${MAX_FILES} files.`);
                  return;
              }
      
              // Check each file size and type
              for (const file of selectedFiles) {
                  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                      setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                      return;
                  }
                  if (!allowedTypes.includes(file.type)) {
                      setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                      return;
                  }
              }
      
              // Check total combined size of all files
              const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
              const newSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
              if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                  setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
                  return;
              }
      
              setError('');
              const updatedFiles = selectedFiles.map((file) => ({
                  file,
                  name: file.name,
              }));
              setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
              fileInputRef.current.value = null;
          };
      
          const handleDrop = (event) => {
              event.preventDefault();
              const droppedFiles = Array.from(event.dataTransfer.files);
      
              // Check if total number of files exceeds limit
              if (files.length + droppedFiles.length > MAX_FILES) {
                  setError(`You can only upload up to ${MAX_FILES} files.`);
                  return;
              }
      
              // Check each file size and type
              for (const file of droppedFiles) {
                  if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                      setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                      return;
                  }
                  if (!allowedTypes.includes(file.type)) {
                      setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                      return;
                  }
              }
      
              // Check total combined size of all files
              const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
              const newSize = droppedFiles.reduce((acc, file) => acc + file.size, 0);
              if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                  setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
                  return;
              }
      
              setError('');
              const updatedFiles = droppedFiles.map((file) => ({
                  file,
                  name: file.name,
              }));
              setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
          };
      
      
          const handleRemoveFile = (index) => {
             
              setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
              // Reset the file input to allow the same file to be uploaded again
              fileInputRef.current.value = null;
          };
        const handleDragOver = (event) => {
            event.preventDefault();
        };
        const handlePhoneNumberChange = (e) => {
            const inputElement = e.target;
            const inputValue = inputElement.value;
            const cursorPosition = inputElement.selectionStart;
    
            let formattedInput = inputValue.replace(/\D/g, ''); // Remove non-digit characters
    
            if (formattedInput.length > 3 && formattedInput.length <= 6) {
                formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
            } else if (formattedInput.length > 6) {
                formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
            } else if (formattedInput.length <= 3) {
                formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
            }
    
            setFormData({ ...formData, phone_number: formattedInput });
    
            const newCursorPosition = cursorPosition + (formattedInput.length - inputValue.length);
            setTimeout(() => {
                inputElement.selectionStart = newCursorPosition;
                inputElement.selectionEnd = newCursorPosition;
            }, 0);
    
            // Phone number validation
            let validationErrors = { ...errors };
            if (!formattedInput) {
                validationErrors.phone_number = 'Phone number is required';
            } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
                validationErrors.phone_number = 'Phone number must be in the format: (123) 456-7890';
            } else {
                delete validationErrors.phone_number;
            }
    
            setErrors(validationErrors);
        };
        const validateForm = () => {
            const newErrors = {};
    
            // Check for empty fields
            Object.keys(formData).forEach((key) => {
                if (!formData[key]) {
                    newErrors[key] = `${key.replace('_', ' ')} is required`;
                }else{
                    if (key === "first_name" && formData[key].length < 3) {
                        newErrors[key] = "First Name must be at least 3 characters long";
                    }else if (key === "first_name" && formData[key].length > 200) {
                        newErrors[key] = ['First Name must be less than 200 characters long.'];
                    }
                    if (key === "short_description" && formData[key].length < 3) {
                        newErrors[key] = "Short description must be at least 3 characters long";
                    }else if (key === "short_description" && formData[key].length > 200) {
                        newErrors[key] = ['Short description must be less than 200 characters long.'];
                    }
                    if (key === "description" && formData[key].length < 3) {
                        newErrors[key] = "Description must be at least 3 characters long";
                    }else if (key === "description" && formData[key].length > 4000) {
                        newErrors[key] = ['Description must be less than 4000 characters long.'];
                    }
                    if (key === "phone_number") {
                        if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData[key])) {
                            newErrors[key] = "Phone number must be in the format: (123) 456-7890";
                        }
                    }
                }
            });
            const email = formData.email_id;
            const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailFormat.test(email)) {
                newErrors.email_id = 'Please enter a valid email address.';
            }
            setErrors(newErrors);
            return Object.keys(newErrors).length === 0;
        };
        const handleSubmit = async (e) => {
            e.preventDefault();
            if (!validateForm()) {
                setErrorMessage('Please fix the errors before proceeding 1.');
                return;
            }
            setSubmitloader(true);
            try {
                const formDataToSend = new FormData();

                // Add file attachments
                files.forEach((file) => {
                    formDataToSend.append('attachments[]', file.file);
                });

                // Add other form fields
                for (const key in formData) {
                    if (formData[key] !== undefined && formData[key] !== null) {
                        formDataToSend.append(key, formData[key]);
                    }
                }
                const response = await apiService.postRequest('contact-requests', formDataToSend);
    
                // Check if the response indicates success
                if (response && response.success === true) {
                setErrors({});
                setErrorMessage('');
                toast("Submitted Successfully !")
                } else {
                setErrorMessage('Failed. Please try again.');
                if (response.errors) {
                    setErrors(response.errors);
                }
                }
            } catch (error) {
                setErrorMessage('An unexpected error occurred.'); 
            }finally {
                setSubmitloader(false); // Stop loader
            }
        };
  return (
    <>
    <Header/>
    <Grid container size={{lg:12}} justifyContent="center">
        <Grid container size={{lg:12}} justifyContent="center" className="hero_section mb-5" alignContent="center">
            <Grid container size={{lg:12,md:12,sm:10,xs:11}} justifyContent="center">
                <h1 className='d-lg-block d-none'>Take Control of your Job Search<br/>and Hiring Today</h1>
                <h1 className='d-lg-none'>Take Control of your<br/> Job Search and <br/>Hiring Today</h1>
            </Grid>
            <Grid container size={{lg:8,md:8,sm:11,xs:11}} justifyContent="center" alignItems="center" flexDirection="column">
                <h3>Simplify Your Job Search and Hiring Process with BenchTrack</h3>
                <span>Streamline your recruitment efforts and discover top talent effortlessly with our cutting-edge job consultancy app.</span>
                <button>Enquire Now</button>
            </Grid>
            <img className='hero_float1' src={require('../../assets/hero_float1.png')} alt=''/>
            <img className='hero_float2' src={require('../../assets/hero_float2.png')} alt=''/>
            <img className='hero_float3 d-lg-block d-none' src={require('../../assets/hero_float3.png')} alt=''/>
            <img className='hero_float4 d-lg-block d-none' src={require('../../assets/hero_float4.png')} alt=''/>
            <img className='hero_float5' src={require('../../assets/hero_float5.png')} alt=''/>
            <img className='hero_float6' src={require('../../assets/hero_float6.png')} alt=''/>
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="why_choosebench">
            <Grid size={{xs:11}} container justifyContent="center">
                <h1>Why Choose BenchTrack?</h1>
            </Grid>
            <Grid size={{lg:8,md:10,xs:11}} container justifyContent="space-evenly" alignItems="center" alignContent="center" className="flex-md-row-reverse">
                <Grid size={{sm:4,xs:12}} container alignItems="center" className="imghover_hiders">
                    <img src={require('../../assets/second_hero.png')} alt=''/>
                </Grid>
                <Grid size={{sm:7,xs:12}} container justifyContent="center" alignContent="center">
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Efficient Job Matching</h5>
                        <small>Our advanced algorithms match job seekers with the most suitable opportunities, ensuring both candidates and employers find their perfect fit.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Real-Time Updates</h5>
                        <small>Stay ahead with real-time notifications about new job listings, candidate applications, and interview schedules, all within the app.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>Comprehensive Analytics</h5>
                        <small>Track your hiring process with detailed analytics and reports, making informed decisions easier than ever.</small>
                    </Grid>
                    <Grid size={{xs:12}} container className="whitewhy_body" flexDirection="column">
                        <h5>User-Friendly Interface</h5>
                        <small>Designed with simplicity in mind, Bench Track offers a seamless experience for both job seekers and employers.</small>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="key_featuremain">
            <Grid size={{xs:11}} container justifyContent="center" className="mt-4">
                <h1>Key Features</h1>
            </Grid>
            <Grid size={{xs:12}} container justifyContent="center">
                <div className='button_keyfeatures'>
                    <button className={isJobSeekersActive ? 'activekeyfeture' : ''} onClick={handleJobSeekersClick}>Job Seekers</button>
                    <button className={!isJobSeekersActive ? 'activekeyfeture' : ''} onClick={handleEmployersClick}>Employers</button>
                </div>
            </Grid>
            {isJobSeekersActive ? 
            <Grid size={{ lg:8,md:10,xs:11}} container justifyContent="space-between" className="mt-4 mb-5">
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon1.svg').default} alt=''/>
                    <h5>Smart Job Search</h5>
                    <small>Filter and find job openings that match your skills and preferences.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon2.svg').default} alt=''/>
                    <h5>Instant Notifications</h5>
                    <small>Receive alerts for new job postings, application status, and interview schedules.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon3.svg').default} alt=''/>
                    <h5>Profile Management</h5>
                    <small>Create and update your profile effortlessly, showcasing your experience and skills.</small>
                </Grid>
            </Grid>
            :
            <Grid size={{lg:8,md:10,xs:11}} container justifyContent="space-between" className="mt-4 mb-5">
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon4.svg').default} alt=''/>
                    <h5>Candidate Tracking</h5>
                    <small>Manage your candidate pipeline with ease, from application to hiring.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon5.svg').default} alt=''/>
                    <h5>Customizable Job Listings</h5>
                    <small>Manage your candidate pipeline with ease, from application to hiring.</small>
                </Grid>
                <Grid size={{sm:3.8}} container justifyContent="flex-start" flexDirection="column" className="card_keyfeature my-2">
                    <img src={require('../../assets/keyfeatureicon/icon6.svg').default} alt=''/>
                    <h5>Interview Scheduling</h5>
                    <small>Coordinate interview times and communicate directly with candidates.</small>
                </Grid>
            </Grid>
            }
        </Grid>
        <Grid container size={{lg:12}} justifyContent="center" className="trackworkmain">
            <Grid size={{xs:11}} container justifyContent="center">
                <h1>How Bench Track Works</h1>
            </Grid>
            <Grid container className="w-100 d-md-block d-none">
                <Grid container size={{xs:12}} justifyContent="center">
                    <Grid size={{xs:11}} container justifyContent="center">
                        <Grid size={{lg:3,md:5,sm:5.5}} container className="maintrackminset" alignItems="flex-start">
                            <Grid size={{xs:12}} container className="mintrack_height" alignContent="space-between">
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:2}} container justifyContent="flex-end" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon1.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Sign Up</h5>
                                        <small>Register quickly as a job seeker or employer to get started.</small>
                                    </Grid>
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow1.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:2}} container justifyContent="flex-end" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon3.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Connect</h5>
                                        <small>Job seekers apply directly through the app, while employers review and manage candidates.</small>
                                    </Grid>
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow3.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container className="dash_linetrack"/>
                        <Grid size={{lg:3,md:5,sm:5.5}} container className="maintrackminset" alignItems="flex-end">
                            <Grid size={{xs:12}} container className="mintrack_height" alignContent="space-between">
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-end" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow2.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Explore</h5>
                                        <small>Browse through job listings or search for top talent based on your requirements.</small>
                                    </Grid>
                                    <Grid size={{sm:2}} container justifyContent="flex-start" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon2.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                                <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                                    <Grid size={{sm:3}} className="mb-2" container justifyContent="flex-end" alignItems="flex-end">
                                        <img src={require('../../assets/trackicons/arrow4.svg').default} alt=''/>
                                    </Grid>
                                    <Grid size={{sm:7}} container className="arow_traccontent">
                                        <h5>Hire or Get Hired</h5>
                                        <small>Streamline your hiring process or land your dream job with Bench Track.</small>
                                    </Grid>
                                    <Grid size={{sm:2}} container justifyContent="flex-start" alignItems="flex-start">
                                        <img className='mt-2' src={require('../../assets/trackicons/icon4.svg').default} alt=''/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className="w-100 d-md-none">
                <Grid container size={{xs:12}} justifyContent="center">
                    <Grid size={{xs:11}} container justifyContent="space-evenly" className="">
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/icon1.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Sign Up</h5>
                                <small>Register quickly as a job seeker or employer to get started.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrow1.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/iconmobi2.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Explore</h5>
                                <small>Browse through job listings or search for top talent based on your requirements.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrowmobi2.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/icon3.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Connect</h5>
                                <small>Job seekers apply directly through the app, while employers review and manage candidates.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrow3.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                        <Grid size={{xs:12}} container justifyContent="flex-start" className="content_trackprocess">
                            <Grid size={{xs:1.5}} container justifyContent="flex-end" alignItems="flex-start">
                                <img className='mt-2' src={require('../../assets/trackicons/iconmobi4.svg').default} alt=''/>
                            </Grid>
                            <Grid size={{xs:8}} container className="arow_traccontent">
                                <h5>Hire or Get Hired</h5>
                                <small>Streamline your hiring process or land your dream job with Bench Track.</small>
                            </Grid>
                            <Grid size={{xs:2.5}} className="mb-2" container justifyContent="flex-start" alignItems="flex-end">
                                <img src={require('../../assets/trackicons/arrowmobi4.svg').default} alt=''/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <Grid container size={{xs:12}} justifyContent="center" className="sucess_story">
            <Grid size={{xs:12}} container justifyContent="center">
                <h1>Success Stories</h1>
            </Grid>
            <Grid size={{md:8,xs:11}} container justifyContent="center">
                <ReviewCarosel/>
            </Grid>
        </Grid>
        <Grid container size={{xs:12}} justifyContent="center" className="contact_usreport py-4">
            <Grid size={{xs:11}} container justifyContent="center" className="reporthomemain my-2">
                <h1>Contact Us</h1>
                <h5>Please fill the form if any problem</h5>
                <Grid size={{sm:11,xs:12}} container>
                    <form className='w-100' 
                    onSubmit={handleSubmit}
                    >
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>First name<span style={{color:"red"}}> *</span></span>
                                <input value={formData.first_name} onChange={handleInputChange}
                                    type="text"
                                    name="first_name"
                                    minLength="3"
                                    maxLength="201"
                                />
                                {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Last name<span style={{color:"red"}}> *</span></span>
                                <input value={formData.last_name} onChange={handleInputChange}
                                    type="text"
                                    name="last_name"
                                />
                                {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Phone Number<span style={{color:"red"}}> *</span></span>
                                <input type="text"
                                    name="phone_number"
                                    autoComplete="new-password"
                                    value={formData.phone_number}
                                    onChange={handlePhoneNumberChange}
                                    placeholder="***_***_****"
                                    maxLength="14"
                                    className={errors.phone_number ? 'input-error' : ''}
                                />
                                {errors.phone_number && <p style={{color:"Red"}}>{errors.phone_number}</p>}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>Email<span style={{color:"red"}}> *</span></span>
                                <input value={formData.email_id} onChange={handleInputChange}
                                    type="text"
                                    name="email_id"
                                />
                                {errors.email_id && <p style={{color:"Red"}}>{errors.email_id}</p>}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>What is the issue related to?<span style={{color:"red"}}> *</span></span>
                                <select name="issue" value={formData.issue} onChange={handleInputChange} >
                                    <option value="">Select Issue</option>
                                    <option value="Registration">Registration</option>
                                    <option value="Login">Login</option>
                                    <option value="Forget Password">Forget Password</option>
                                </select>
                                {errors.issue && <p style={{color:"Red"}}>{errors.issue}</p>}
                            </Grid>
                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                <span>When did you first notice the issue ?<span style={{color:"red"}}> *</span></span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="date_pickermux"
                                            value={value}
                                            onChange={handleDateChange}
                                            maxDate={dayjs().subtract(1, 'day')}
                                            renderInput={(params) => <input {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {errors.issue_date && <p style={{color:"Red"}}>{errors.issue_date}</p>}
                            </Grid>
                            <Grid size={{ xs:12}} container>
                                <Grid size={{xs:12}} container flexDirection="column">
                                    <span>Short Description<span style={{color:"red"}}> *</span></span>
                                    <input value={formData.short_description} onChange={handleInputChange}
                                        type="text"
                                        name="short_description"
                                        minLength="3"
                                        maxLength="201"
                                    />
                                    {errors.short_description && <p style={{color:"Red"}}>{errors.short_description}</p>}
                                </Grid>
                            </Grid>
                            <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                <span>Description<span style={{color:"Red"}}> *</span></span>
                                <textarea placeholder='Describe' minLength="3" maxLength="4001" value={formData.description} onChange={handleInputChange} rows="10" name="description"/>
                                {errors.description && <p style={{color:"Red"}}>{errors.description}</p>}
                            </Grid>
                            <Grid size={{xs:12}} container flexDirection="column">
                            <span>Please attach screenshots describing the issue</span>
                            <Grid size={{xs: 12 }} container justifyContent="space-between">
                            <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                className='drag_uploaders align-items-center flex-column d-flex'
                            >
                                <FontAwesomeIcon className='file_uploadericon' icon={faFileLines} />
                                <input
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="file-upload"
                                    ref={fileInputRef} // Attach the ref here
                                />
                                <label htmlFor="file-upload">
                                    Browse Files
                                </label>
                                <p className='w-100 mb-0 mt-3'>or drop file here</p>
                            </div>
                    {error && <div style={{ color: 'red', margin: '10px 0px' }}>{error}</div>}
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        {files.map((file, index) => (
                            <Grid key={index} size={{xs:12}} container justifyContent="space-between" alignItems="center" className="tablelisterattach reportincident">
                                <Grid container className="pinkbgattachreport" alignItems="center">
                                    <span className='spanreport_elipse'>{index + 1}<span className='filenamesshow ms-3'>{file.name}</span></span>
                                    <button onClick={(e) => handleRemoveFile(index)}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                        </Grid>
                        {errorMessage && <div style={{ color: 'red', margin: '10px 0px' }}>{errorMessage}</div>}
                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                            <button type="submit" className='registersendbtn2'>{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Submit'}</button>
                        </Grid>
                    </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    <Footer/>
    </>
  )
}

export default Homepage

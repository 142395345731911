import React from 'react'
import Grid from '@mui/material/Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
function TaskTabs() {
    const location = useLocation();
    const navigate = useNavigate()
    const currentPath = location.pathname;
    const roles = localStorage.getItem('role');
  return (
    <Grid size={{xs:12}} container justifyContent="flex-start">
        <div className='admintab_btnredirect'>
            {roles && roles !== "job_seeker" ? <>
              <button className={currentPath.startsWith("/task/all-task") ? "active_tabbtn" : ""} onClick={() => {
              navigate("/task/all-task"); }}>All Task</button>
              </>:
                ""
            }
            {roles && roles !== "job_seeker" ? "":
                <button className={currentPath === "/task/my-request" ? "active_tabbtn" : ""} onClick={() =>{navigate("/task/my-request")}}>My Request</button>
            }
            <button className={currentPath === "/task/my-task" ? "active_tabbtn" : ""} onClick={() =>{navigate("/task/my-task")}}>My Task</button>
            {roles && roles !== "job_seeker" ? <>
              <button className={currentPath === "/task/group-task" ? "active_tabbtn" : ""} onClick={() => {
              navigate("/task/group-task"); }}>Group Task</button>
              <button className={currentPath === "/task/my-cases" ? "active_tabbtn" : ""} onClick={() => {
              navigate("/task/my-cases"); }}>My Cases</button>
              </>:
                ""
            }
        </div>
    </Grid>
  )
}

export default TaskTabs

import DOMPurify from "dompurify";

// Utility function to wrap text in a span with a given class
const wrapTextWithSpan = (text, className) => {
  const span = document.createElement("span");
  span.className = className;
  span.textContent = text;
  return span;
};

// Main function to compare old and new HTML content
const compareNodes = (oldNode, newNode) => {
  // Handle missing or empty nodes
  if (!oldNode && newNode) {
    // New text added
    if (newNode.nodeType === Node.TEXT_NODE) {
      return wrapTextWithSpan(newNode.textContent, "added_text");
    }
    return newNode.cloneNode(true);
  }

  if (oldNode && !newNode) {
    // Text removed
    if (oldNode.nodeType === Node.TEXT_NODE) {
      return wrapTextWithSpan(oldNode.textContent, "removed_text");
    }
    return oldNode.cloneNode(true);
  }

  // If both are text nodes, compare their content
  if (oldNode.nodeType === Node.TEXT_NODE && newNode.nodeType === Node.TEXT_NODE) {
    const oldText = oldNode.textContent;
    const newText = newNode.textContent;

    if (oldText === newText) {
      return document.createTextNode(newText); // No change
    }

    if (newText.includes(oldText)) {
      // If new text is an extension of the old text, highlight the new part
      const addedText = newText.replace(oldText, "");
      const unchangedText = oldText;
      
      const span = wrapTextWithSpan(addedText, "added_text");
      return [document.createTextNode(unchangedText), span];
    }

    // If completely different, wrap the whole new text in a span
    return wrapTextWithSpan(newText, "changed_artical");
  }

  // If both nodes are elements, recursively compare their children
  if (oldNode.nodeType === Node.ELEMENT_NODE && newNode.nodeType === Node.ELEMENT_NODE) {
    const newElement = document.createElement(newNode.tagName);

    // Copy attributes from the new element
    Array.from(newNode.attributes).forEach((attr) => {
      newElement.setAttribute(attr.name, attr.value);
    });

    const oldChildren = Array.from(oldNode.childNodes);
    const newChildren = Array.from(newNode.childNodes);

    // Compare the children nodes
    newChildren.forEach((newChildNode, index) => {
      const oldChildNode = oldChildren[index];
      const childResult = compareNodes(oldChildNode, newChildNode);
      if (childResult) {
        if (Array.isArray(childResult)) {
          childResult.forEach((item) => newElement.appendChild(item));
        } else {
          newElement.appendChild(childResult);
        }
      }
    });

    return newElement;
  }

  return newNode.cloneNode(true);
};

const highlightDifferences = (oldHTML, newHTML) => {
  const oldDiv = document.createElement("div");
  const newDiv = document.createElement("div");

  oldDiv.innerHTML = oldHTML;
  newDiv.innerHTML = newHTML;

  // Compare the old and new HTML content
  const resultNode = compareNodes(oldDiv, newDiv);

  // Return sanitized HTML
  return resultNode ? DOMPurify.sanitize(resultNode.innerHTML) : newHTML;
};

export default highlightDifferences;

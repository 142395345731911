import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen, faChevronLeft, faChevronRight, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Accordion } from 'react-bootstrap';
import { apiService } from '../../../../services/apiService';
function TaskKnowledge() {
    const taskId = localStorage.getItem('onBoardTaskID');
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const [showKnowledge, setvalidateKnowledge] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paginatedata, setPaginateData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [taskType, setType] = useState("");
      useEffect(() => {
          const fetchData = async () => {
            try {
              const response = await apiService.getRequest(`task/details/${taskId}`);
                if (response.success && response.success === true) {
                    setType(response.data.onboarding_name);
                }
            } catch (error) {
              console.error("Error fetching onboarding data:", error);
            }
          };
      
          fetchData();
      }, [taskId,onboardslug]);
    
    

    const fetchArticles = useCallback(async (params = {}) => {
        setLoading(true);
        try {
            const defaultParams = { task_type: taskType, ...params };
            const queryString = new URLSearchParams(defaultParams).toString();
            const response = await apiService.getRequest(`/task-related-knowledge-articles?${queryString}`);
            if (response && response.success === true) {
                setvalidateKnowledge(response.data);
                setPaginateData(response.meta);
            }
        } catch (error) {
            console.error('Error fetching tab data:', error);
        } finally {
            setLoading(false);
        }
    }, [taskType,]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        fetchArticles({ page: newPage });
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchArticles({ search: searchQuery });
    };

    useEffect(() => {
        fetchArticles();
    }, [fetchArticles,onboardslug]);
    const sanitizeAndDisplayHTML = (html) => {
        const sanitizedHTML = html
        return sanitizedHTML;
    };
  return (
    <>
        {loading ? 
            <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
            </div> :<>
        <Grid size={{md:12,xs:12}} container justifyContent="space-between" alignItems="center" className="my-4">
            <h4 className='sideh4searcheq'>Related Knowledge Article</h4>
            <Grid size={{md:4,xs:12}} container flexDirection="column">
                <div className='d-flex jobseekersearch my-3'>
                    <div className='col-2'>
                        <FontAwesomeIcon className='iconcol' icon={faMagnifyingGlass} />
                    </div>
                    <div className='col-7'>
                        <input type='text' placeholder='search'
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}/>
                    </div>
                    <div className='col-3'>
                        <button onClick={handleSearch}>Search</button>
                    </div>
                </div>
            </Grid>
        </Grid>
        <Grid size={{xs:12}} container className="tast_knowledeaccord">
            <Accordion className='w-100'>
                {showKnowledge && showKnowledge.length > 0 ? ( showKnowledge && showKnowledge.map((article,index) => (
                    <Accordion.Item key={article.id || index} eventKey={index}>
                        <Accordion.Header><FontAwesomeIcon icon={faBookOpen} className='px-3'/>{article.title}</Accordion.Header>
                        <Accordion.Body>
                            <div className='w-100 caliberifontsset mb-4' dangerouslySetInnerHTML={{ __html: sanitizeAndDisplayHTML(article.content) }} />
                            <b className='bold_valikhowledge'>Go To Reference <a href={`/knowledge-article/${article.format_id}`} target="_blank" rel="noopener noreferrer">{article.title}</a></b>
                        </Accordion.Body>
                    </Accordion.Item>
                    ))
                ) : (
                    <div className="w-100 d-flex justify-content-center mt-4">
                        <p>No data to display</p>
                    </div>
                )}
            </Accordion>
        </Grid>
        <Grid size={{xs:12}} container justifyContent="center" className="mt-4 pagination_btn">
            <button className='arrowpaginate'
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            {Array.from({ length: paginatedata && paginatedata.last_page }, (_, index) => (
                <button 
                    key={index} 
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                >
                    {index + 1}
                </button>
            ))}
            <button className='arrowpaginate'
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage === paginatedata.last_page}>
                <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </Grid>
        </>}
    </>
  )
}

export default TaskKnowledge

import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function ImmigrationReview() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>Immigration Review
                            <select>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                            </select>
                        </h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button className="active_tabbtn">Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <form className='w-100 mt-3' 
                        // onSubmit={handleSubmit}
                        >
                        {/* <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                            <button type='submit' className='registersendbtn2 mb-0'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                        </Grid> */}
                        
                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                            <Grid size={{md:5.6,sm:5.8,xs:12}} container flexDirection="column">
                                <span>Upload Supporting Documents<small>*</small></span>
                                <div className="fileUploadInput">
                                    <input
                                        type="file"
                                        name="i94_copy"
                                        id="i94_copy"
                                        // onChange={handleFileChange}
                                        style={{ display: 'none' }} // Hide the actual file input
                                    />
                                    <label htmlFor="i94_copy" style={{ cursor: 'pointer' }}>Upload</label>
                                    <input
                                        type="text"
                                        // value={fileNames.i94_copy || ''}
                                        readOnly
                                        placeholder="No file chosen"
                                    />
                                </div>
                                {/* {errors.first_name && <p style={{color:"Red"}}>{errors.first_name[0]}</p>} */}
                            </Grid>
                            <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                <span>Comments</span>
                                <textarea placeholder='Describe'/>
                                {/* {errors.gender && <p style={{color:"Red"}}>{errors.gender[0]}</p>} */}
                            </Grid>
                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-5">
                                <button type='submit' className='registersendbtn2'>Save</button>
                                <button type='submit' className='registersendbtn1'>Cancel</button>
                                {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                            </Grid>
                        </Grid>
                    </form>
                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                        <Grid size={{md:5.6,sm:5.8,xs:12}} container flexDirection="column">
                            <span>Upload Supporting Documents<small>*</small></span>
                            <p>File.png</p>
                        </Grid>
                        <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                            <span>Additional Comments</span>
                            <p>Lorem commets</p>
                        </Grid>
                        <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                            <button className='registersendbtn2'>Edit</button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default ImmigrationReview

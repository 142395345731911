import React, { useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ValidateCats from '../../../../components/common/ValidateCats';
import Select from 'react-select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { toast } from 'react-toastify';
// import { apiService } from '../../../../services/apiService';
function JobPostLinkedin() {
    // const { requestId } = useParams();
    // const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true);
    const toggleVisibility = () => {
        setIsVisible(!isVisible);
      };
      const closeElement = () => {
        setIsVisible(true);
      };
  
  return (
    <div className='container-fluid px-0'>
        <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Case10000</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer">
                        <h3>Publish Job Post on LinkedIn
                            <select>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                                <option value="">In Progress</option>
                            </select>
                        </h3>
                        <b>Jobseeker Name</b>
                        <button onClick={toggleVisibility} className='d-md-none main_category_btn'>
                            List
                        </button>
                    </Grid>
                    <Grid size={{xs:12}} container justifyContent="flex-start" className="statusselectbtn mt-4">
                        <div className='knowledgetab_btnredirect'>
                            <button className="active_tabbtn">Overview</button>
                            <button>Assignments</button>
                            <button>Discussions</button>
                            <button>Knowledge</button>
                            <button>History</button>
                        </div>
                    </Grid>
                    <form className='w-100' 
                        // onSubmit={handleSubmit}
                        >
                            <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="darfts_inputs for_textarespec mt-4">
                                <Grid size={{sm:5.6, xs:12}} container flexDirection="column">
                                    <span>Title<small>*</small></span>
                                    <input type='text' placeholder='Title'/>
                                    {/* {errors.task_type && <p style={{color:"Red"}}>{errors.task_type}</p>} */}
                                </Grid>
                                <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                    <span>Reason for re-assignment</span>
                                    <textarea placeholder='Write your reason'/>
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{md:5.6,sm:5.8, xs:12}} container flexDirection="column">
                                    <span>Location<small>*</small></span>
                                    <Select
                                        className="basic-single select_withsearch"
                                        classNamePrefix="select"
                                        // value={selectedCatId}
                                        defaultValue={null}
                                        name="category_id"
                                        // options={categoryId.map((cates) => ({
                                        //     label: cates.name,
                                        //     value: cates.id,
                                        // }))}
                                        // onChange={handleCatidChange}
                                    />
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{md:5.6,sm:5.8, xs:12}} container flexDirection="column" className="select_draftsets">
                                    <span>Employment Type<small>*</small></span>
                                    <select>
                                        <option>Fulltime</option>
                                        <option>Fulltime</option>
                                        <option>Fulltime</option>
                                        <option>Fulltime</option>
                                    </select>
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{md:5.6,sm:5.8, xs:12}} container flexDirection="column" className="select_draftsets">
                                    <span>Link to the published Job Requisition<small>*</small></span>
                                    <input type='text' placeholder='Type here'/>
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                <Grid size={{md:5.6,sm:5.8, xs:12}} container flexDirection="column" className="select_draftsets">
                                    <span>Date Posted<small>*</small></span>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            className="date_pickermux"
                                            // value={value}
                                            // onChange={handleDateChange}
                                            // maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                            renderInput={(params) => <input {...params} />}
                                        />
                                    </LocalizationProvider>
                                    {/* {errors.category_id && <p style={{color:"Red"}}>{errors.category_id}</p>} */}
                                </Grid>
                                
                                <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                    <button type='submit' className='registersendbtn2'>Save</button>
                                    {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                                </Grid>
                            </Grid>
                        </form>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default JobPostLinkedin

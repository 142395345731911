import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { apiService } from '../../../../services/apiService';
import TaskKnowledge from '../I94Validation/TaskKnowledge';
import { Tab, Tabs } from 'react-bootstrap';
function PlacementAgreements() {
    const onboardslug = localStorage.getItem('onboardingSlugs'); 
    const { userid } = useParams();
    const Role_user = localStorage.getItem('role');
    const [keyReload, setreloadKey] = useState(0);
    const navigate = useNavigate();
    // const taskData = localStorage.getItem('onBoardTaskID');
    const [showform, setShowform] = useState(true);
    const [showedit, setShowedit] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [jobseekerName, setJobseekername] = useState("");
    const [caseids, setcaseid] = useState("");
    const [taskids, settaskid] = useState("");
    const [activeTab, setActiveTab] = useState("overview");
    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
            if (response.success && response.success === true) {
                localStorage.setItem('onBoardTaskID', response.data.task.id);
                setJobseekername(response.data.jobseeker.name);
                setcaseid(response.data.task.case_id);
                settaskid(response.data.task.task_format_id);
                const refetchedData = response.data.form_data;
                setFormData({  
                    Q00010: refetchedData.Q00010,
                    status: "completed",
                });
                if ((
                    (response && response.data.form_data && Object.keys(response.data.form_data).length === 0) ||
                    (response && response.data.form_data && (!response.data.form_data.Q00010)))
                    && (response && response.data.status_string === "in-progress")
                ){
                setShowform(true); 
                }else{
                setShowform(false);
                }
            }
            } catch (error) {
            console.error("Error fetching onboarding data:", error);
            } finally {
            setLoading(false);
            }
        };
    
        fetchData();
    }, [onboardslug, userid]);

    useEffect(() => {
        const fetchCurrentData = async () => {
            try {
            const response = await apiService.getRequest(`/current-onboarding/${userid}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug) && response.status && response.status["placement-agreement"] === "in-progress"){
                    setShowedit(true);
                }else{
                    setShowedit(false)
                }
            }
            } catch (error) {
            console.error("Error fetching onboarding data:", error);
            } finally {
            setLoading(false);
            }
        };
    
        fetchCurrentData();
    }, [ userid,onboardslug,keyReload]);
    const [formData, setFormData] = useState({
        Q00010: false,
        status: "completed",
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
        // Handle input changes
        const handleInputChange = (e) => {
            const { name, type, value, checked } = e.target;
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === "checkbox" ? checked : value, // Handle checkbox state
            }));
            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '', // Clear errors for the field
            }));
        };

    // Validate form fields
    const validateForm = () => {
    const newErrors = {};
    if (!formData.Q00010) newErrors.Q00010 = "You should Agree with our Placement Agreements";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    };
    // Handle form submission
    const [submitloader,setSubmitloader] = useState(false)
    const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setErrorMessage('Please fix the errors before proceeding.');
        return;
    }
    setSubmitloader(true);
    try { 
        const response = await apiService.postRequest(`onboarding/${onboardslug}/${userid}/submit`, formData);

        // Check response for success
        if (response.success) {
            setErrors({});
            setErrorMessage('');
            setEditMode(false);
            setShowform(false);
            if(response.success && response.status === "rejected"){
                window.location.reload();
            }
            setreloadKey(prevKey => prevKey + 1);  
        } else {
            setErrorMessage(response.message);
            if (response.errors) {
                setErrors(response.errors);
            }
        }
    } catch (error) { 
        setErrorMessage('Registration failed. Please try again.');
    } finally {
        setSubmitloader(false); // Stop loader
    }
    };
  
  return (
    <div className='container-fluid px-0' key={keyReload}>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
            <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>  
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>Placement Agreement</h3>
                        <b>{jobseekerName}</b>
                    </Grid>  
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)} 
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                <p className='mt-5'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                                    {(showform && showform === true) || (editMode && editMode === true) ? <>
                                    <form className='w-100 mt-3'
                                        onSubmit={handleSubmit}
                                    >
                                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                                            <Grid size={{sm:12}} container flexDirection="column">
                                                <span>Agree with our Placement Agreements<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="checkbox"
                                                        name='Q00010'
                                                        checked={formData.Q00010 || ""}
                                                        onChange={handleInputChange} 
                                                    />I Agree with Placement Agreements
                                                </div>
                                                {errors.Q00010 && <p style={{color:"Red"}}>{errors.Q00010}</p>}
                                            </Grid>                        
                                            {errorMessage && <p style={{ color: 'red',width:"100%" }}>{errorMessage}</p>}
                                            {Role_user && Role_user === "job_seeker" ?
                                                <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                    <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                                                    {editMode && 
                                                        <button className='registersendbtn1' onClick={()=> setEditMode(false)}>cancel</button>
                                                    }
                                                </Grid>
                                            : ""}
                                        </Grid>
                                    </form></> :
                                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                                        <Grid size={{xs:12}} container className="mt-3" flexDirection="column">
                                            <span><FontAwesomeIcon className='checkinagreement' icon={faSquareCheck} /> Yes, I Agree with Placement Agreements</span>
                                        </Grid>
                                        {Role_user && Role_user === "job_seeker" ? <>
                                        {showedit && 
                                            <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                                <button className='registersendbtn2' onClick={()=> setEditMode(true)}>Edit</button>
                                            </Grid>
                                        }
                                        </>: ""}
                                    </Grid>}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
        </div>
    </div>
  )
} 

export default PlacementAgreements

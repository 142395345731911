import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import Assignments from '../I94Validation/Assignments';
import TaskDisccuss from '../I94Validation/TaskDisccuss';
import TaskKnowledge from '../I94Validation/TaskKnowledge';
import TaskHistory from '../I94Validation/TaskHistory';
function JobseekersClassification() {
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const { userid } = useParams();
    const [value, setValue] = useState(dayjs(null));
    const [value2, setValue2] = useState(dayjs(null));
    const [value3, setValue3] = useState(dayjs(null));
    const navigate = useNavigate();
    const taskData = localStorage.getItem('onBoardTaskID');
    const [showform, setShowform] = useState(true);
    const [showedit, setShowedit] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("");
    const [jobseekerName, setJobseekername] = useState("");
    const [jobseekerid, setJobseekerid] = useState("");
    const [caseids, setcaseid] = useState("");
    const [taskids, settaskid] = useState("");
    const [activeTab, setActiveTab] = useState("overview");
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
            if (response.success && response.success === true) {
                localStorage.setItem('onBoardTaskID', response.data.task.id);
                setJobseekername(response.data.jobseeker.name);
                setJobseekerid(response.data.jobseeker.id);
                setcaseid(response.data.task.case_id);
                settaskid(response.data.task.task_format_id);
                setStatus(response.data.status_string);
                const refetchedData = response.data.form_data;
                setFileNames({
                    Q00090: refetchedData.Q00090,
                    Q00120: refetchedData.Q00120,
                })
                setFormData({
                    Q00010: refetchedData.Q00010,
                    Q00020: refetchedData.Q00020,
                    Q00040: refetchedData.Q00040,
                    Q00050: refetchedData.Q00050,
                    Q00060: refetchedData.Q00060,
                    Q00070: refetchedData.Q00070,
                    Q00080: refetchedData.Q00080,

                    Q00030: refetchedData.Q00030,
                    Q00130: refetchedData.Q00130,
                    Q00140: refetchedData.Q00140,

                    Q00090: refetchedData.Q00090 || "",
                    Q00120: refetchedData.Q00120 || "",
                    
                    Q00100: refetchedData.Q00100,
                    Q00110: refetchedData.Q00110,

                    Q00150: refetchedData.Q00150,
                    Q00160: refetchedData.Q00160,
                    Q00170: refetchedData.Q00170,
                    Q00180: refetchedData.Q00180,
                    Q00190: refetchedData.Q00190,
                    Q00200: refetchedData.Q00200,
                    Q00210: refetchedData.Q00210,
                    Q00220: refetchedData.Q00220,
                    Q00230: refetchedData.Q00230,
                });
                if (refetchedData.Q00030) {
                    const parsedQ00030 = dayjs(refetchedData.Q00030, 'YYYY-MM-DD');
                    if (parsedQ00030.isValid()) {
                        setValue(parsedQ00030); 
                    }
                }
                if (refetchedData.Q00130) {
                    const parsedQ00130 = dayjs(refetchedData.Q00130, 'YYYY-MM');
                    if (parsedQ00130.isValid()) {
                        setValue2(parsedQ00130); 
                    }
                }
                if (refetchedData.Q00140) {
                    const parsedQ00140 = dayjs(refetchedData.Q00140, 'YYYY-MM');
                    if (parsedQ00140.isValid()) {
                        setValue3(parsedQ00140); 
                    }
                }
                if ((
                    (response && response.data.form_data && Object.keys(response.data.form_data).length === 0) ||
                    (response && response.data.form_data && (!response.data.form_data.Q00010 || Object.keys(response.data.form_data.Q00010).length === 0)))
                    && (response && response.data.status_string === "in-progress")
                ){
                setShowform(true);
              }else{
                if(response && response.data.form_data && response.data.form_data.Q00060 === "Yes"){
                    setOpendata(true);
                }else{
                    setOpendata(false);
                }
                if(response && response.data.form_data && response.data.form_data.Q00040 === "Yes"){
                    setOpendata1(true);
                }else{
                    setOpendata1(false);
                    setOpendata(false);
                    setOpendata1(false);
                    setOpendata2(false);
                    setFormData(prevData => ({
                        ...prevData,
                        Q00050: "",
                        Q00060:"",
                    }));
                }
                if(response && response.data.form_data && response.data.form_data.Q00050 === "Yes"){
                    setOpendata2(true);
                }else{
                    setOpendata2(false);
                }
                if(response && response.data.form_data && response.data.form_data.Q00080 === "Yes"){
                    setOpendata3(true);
                }else{
                    setOpendata3(false);
                }
                if(response && response.data.form_data && response.data.form_data.Q00110 === "Yes"){
                    setOpendata4(true);
                }else{
                    setOpendata4(false);
                }
                if(response && response.data.form_data && response.data.form_data.Q00210 === "Yes"){
                    setOpendata5(true);
                }else{
                    setOpendata5(false);
                }
                setShowform(false);
              }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    }, [onboardslug, userid]);

    useEffect(() => {
        const fetchCurrentData = async () => {
          try {
            const response = await apiService.getRequest(`/current-onboarding/${userid}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug) && response.status && response.status["jobseeker-classification"] === "in-progress"){
                    setShowedit(true);
                }else{
                    setShowedit(false)
                }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchCurrentData();
    }, [ userid,onboardslug]);

    const [fileNames, setFileNames] = useState({
        Q00090: "",
    });
    const [formData, setFormData] = useState({
        Q00010: "",
        Q00040: "",
        Q00020: "",
        Q00050:"",
        Q00060: "",
        Q00070: "",
        Q00080: "",
        Q00100: "",
        Q00110: "",
        // file
        Q00090: null,
        Q00120: null,
        // dates
        Q00030: "",
        Q00130: "",
        Q00140: "",

        Q00150: "",
        Q00160: "",
        Q00170: "",
        Q00180: "",
        Q00190: "",
        Q00200: "",
        Q00210: "",
        Q00220: "",
        Q00230: "",
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [opendata, setOpendata] = useState(false);
    const [opendata1, setOpendata1] = useState(false);
    const [opendata2, setOpendata2] = useState(false);
    const [opendata3, setOpendata3] = useState(false);
    const [opendata4, setOpendata4] = useState(false);
    const [opendata5, setOpendata5] = useState(false);
       // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value ?? "" }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

        // Validate Q00010
        if (name === 'Q00060') {
            if (value === 'Yes') {
                setOpendata(true);
            } else {
                setOpendata(false);
            }
        }
        if (name === 'Q00040') {
            if (value === 'Yes') {
                setOpendata1(true);
            } else {
                setOpendata(false);
                setOpendata1(false);
                setOpendata2(false);
                setFormData(prevData => ({
                    ...prevData,
                    Q00050: "",
                    Q00060:"",
                    
                }));
            }
        }
        if (name === 'Q00050') {
            if (value === 'Yes') {
                setOpendata2(true);
            } else {
                setOpendata2(false);
            }
        }
        if (name === 'Q00080') {
            if (value === 'Yes') {
                setOpendata3(true);
            } else {
                setOpendata3(false);
            }
        }
        if (name === 'Q00110') {
            if (value === 'Yes') {
                setOpendata4(true);
            } else {
                setOpendata4(false);
            }
        }
        if (name === 'Q00210') {
            if (value === 'Yes') {
                setOpendata5(true);
            } else {
                setOpendata5(false);
            }
        }
    };
  // Handle file change
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
        const file = files[0]; // Get the selected file

        // Update the formData state with the new file
        setFormData((prevData) => ({
            ...prevData,
            [name]: file, // Store the file object for submission
        }));
        
        // Update the fileNames state to show the file name
        setFileNames((prevNames) => ({
            ...prevNames,
            [name]: file.name, // Store the file name for display
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name]; // Remove the error for the file field
            return updatedErrors;
        });
    }
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.Q00010) newErrors.Q00010 = "Referral Verification Status is required.";
    if (!formData.Q00020) newErrors.Q00020 = "Referral verification decision change comments is required.";
    if (formData.Q00020 && formData.Q00020.length < 3) {
        newErrors.Q00020 = "Referral verification decision change comments must be at least 3 characters long";
    }else if (formData.Q00020 && formData.Q00020.length > 4000) {
        newErrors.Q00020 = 'Referral verification decision change comments must be less than 4000 characters long.';
    }
        if (!formData.Q00030) newErrors.Q00030 = "Work Autherization expirartion date is required.";
        if (!formData.Q00040) newErrors.Q00040 = "Jobseeker seek for a ServiceNow Job is required.";
        if (opendata1){
            if (!formData.Q00050) newErrors.Q00050 = "Jobseeker previosuly holds atleast 1 servicenow project experience is required.";
            if (!formData.Q00060) newErrors.Q00060 = "spouse of the jobseeker a ServiceNow resourse is required.";
        }
        if (opendata) {
        if (!formData.Q00070) newErrors.Q00070 = "Full name of the Jobseeker's Spouse is required.";
        if (formData.Q00070 && formData.Q00070.length < 3) {
            newErrors.Q00070 = "Data must be at least 3 characters long.";
        }else if (formData.Q00070 && formData.Q00070.length > 100) {
            newErrors.Q00070 = 'Data must be less than 100 characters long.';
        }
        if (!formData.Q00080) newErrors.Q00080 = "spouse of the Jobseeker hold/held any ServiceNow Certifications is required.";
        if (opendata3) {
            if (!formData.Q00090) newErrors.Q00090 = "Evidence of ServiceNow certifications owned by the spouse of Jobseeker is required.";
        }
        if (!formData.Q00100) newErrors.Q00100 = "LinkedIn URL of the spouse is required.";
        if (formData.Q00100 && formData.Q00100.length < 3) {
            newErrors.Q00100 = "Data must be at least 3 characters long.";
        }else if (formData.Q00100 && formData.Q00100.length > 100) {
            newErrors.Q00100 = 'Data must be less than 100 characters long.';
        }
        }
        if (opendata2) {
        if (!formData.Q00110) newErrors.Q00110 = "Jobseeker hold/held any ServiceNow Certifications is required.";
        if (opendata4) {
            if (!formData.Q00120) newErrors.Q00120 = "Evidence of ServiceNow certifications owned by the Jobseeker is required.";
        }
        if (!formData.Q00130) newErrors.Q00130 = "Start Date of the Project is required.";
        if (!formData.Q00140) newErrors.Q00140 = "Start Date of the Project is required.";
        if (!formData.Q00150) newErrors.Q00150 = "Employer is required.";
        if (formData.Q00150 && formData.Q00150.length < 3) {
            newErrors.Q00150 = "Data must be at least 3 characters long.";
        }else if (formData.Q00150 && formData.Q00150.length > 100) {
            newErrors.Q00150 = 'Data must be less than 100 characters long.';
        }
        if (!formData.Q00160) newErrors.Q00160 = "Vendor(s) or Implementation Partners is required.";
        if (formData.Q00160 && formData.Q00160.length < 3) {
            newErrors.Q00160 = "Data must be at least 3 characters long.";
        }else if (formData.Q00160 && formData.Q00160.length > 100) {
            newErrors.Q00160 = 'Data must be less than 100 characters long.';
        }
        if (!formData.Q00170) newErrors.Q00170 = "End Client is required.";
        if (formData.Q00170 && formData.Q00170.length < 3) {
            newErrors.Q00170 = "Data must be at least 3 characters long.";
        }else if (formData.Q00170 && formData.Q00170.length > 100) {
            newErrors.Q00170 = 'Data must be less than 100 characters long.';
        }
        if (!formData.Q00180) newErrors.Q00180 = "Job Title is required.";
        if (formData.Q00180 && formData.Q00180.length < 3) {
            newErrors.Q00180 = "Data must be at least 3 characters long.";
        }else if (formData.Q00180 && formData.Q00180.length > 100) {
            newErrors.Q00180 = 'Data must be less than 100 characters long.';
        }
        if (!formData.Q00190) newErrors.Q00190 = "Job Duties is required.";
        if (formData.Q00190 && formData.Q00190.length < 3) {
            newErrors.Q00190 = "Comments must be at least 3 characters long.";
        }else if (formData.Q00190 && formData.Q00190.length > 4000) {
            newErrors.Q00190 = 'Comments must be less than 4000 characters long.';
        }
        if (!formData.Q00200) newErrors.Q00200 = "Jobseeker ever on a Placement Contract with Tekpossible is required.";
        }
        if (!formData.Q00210) newErrors.Q00210 = "Jobseeker previously holds at least 3 years of Agile experience is required.";
        if (opendata5) {
            if (!formData.Q00220) newErrors.Q00220 = "Experience of the project(s) where Jobseeker worked on Agile is required.";
            if (formData.Q00220 && formData.Q00220.length < 3) {
                newErrors.Q00220 = "Comments must be at least 3 characters long.";
            }else if (formData.Q00020 && formData.Q00020.length > 4000) {
                newErrors.Q00020 = 'Comments must be less than 4000 characters long.';
            }
        }
        if (!formData.Q00230) newErrors.Q00230 = "Candidate willing to re-locate for the job is required.";

        // if (!formData.Q00090) newErrors.Q00090 = "Please upload the I-94 document.";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue(newValue);
            setFormData({ ...formData, Q00030: newValue.format('YYYY-MM-DD') });

            // Clear the error for Q00030 if a valid date is entered
            if (errors.Q00030) {
                setErrors({ ...errors, Q00030: '' });
            }
        } else {
            setValue(null);
            setFormData({ ...formData, Q00030: '' }); // Clear the value if null
        }
    };
    const handleDateChange2 = (newValue) => {
        if (newValue) {
            setValue2(newValue);
            setFormData({ ...formData, Q00130: newValue.format('YYYY-MM') });

            // Clear the error for Q00030 if a valid date is entered
            if (errors.Q00130) {
                setErrors({ ...errors, Q00130: '' });
            }
        } else {
            setValue2(null);
            setFormData({ ...formData, Q00130: '' }); // Clear the value if null
        }
    };
    const handleDateChange3 = (newValue) => {
        if (newValue) {
            setValue3(newValue);
            setFormData({ ...formData, Q00140: newValue.format('YYYY-MM') });

            // Clear the error for Q00030 if a valid date is entered
            if (errors.Q00140) {
                setErrors({ ...errors, Q00140: '' });
            }
        } else {
            setValue3(null);
            setFormData({ ...formData, Q00140: '' }); // Clear the value if null
        }
    };
  // Handle form submission
  const [submitloader,setSubmitloader] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setErrorMessage('Please fix the errors before proceeding.');
        return;
    }
    setSubmitloader(true);
    try {
        const formDataToSend = new FormData();

        Object.keys(formData).forEach((key) => {
            const value = formData[key];
            if (value !== null && value !== undefined && value !== '' && !(typeof value === 'string' && ['Q00090', 'Q00120'].includes(key))) {
                formDataToSend.append(key, value);
            }
            // Only skip appending if the field is a string and is one of the file fields
            // if (value !== null && !(typeof value === 'string' && ['Q00090','Q00120'].includes(key))) {
            //     formDataToSend.append(key, value);
            // }
        });

        // Make the API request
        const response = await apiService.postRequest(`onboarding/${onboardslug}/${userid}/submit`, formDataToSend);

        // Check response for success
        if (response.success) {
            setErrors({});
            setErrorMessage('');
            setEditMode(false);
            setShowform(false);
            if(response.success && response.status === "rejected"){
                window.location.reload();
            }
        } else {
            setErrorMessage(response.message);
            if (response.errors) {
              setErrors(response.errors);
            }
        }
    } catch (error) {
        setErrorMessage('Registration failed. Please try again.');
    } finally {
        setSubmitloader(false); // Stop loader
    }
  };

  const [selectedStatus, setSelectedStatus] = useState("");
  const [show, setShow] = useState(false);
  const handleslectChange = () => {
    setSelectedStatus("completed");
    setShow(true);
  };
  const handleClose = () => setShow(false);

  const [statuserror,setStatuserror] = useState("");
    const [submitloader2,setSubmitloader2] = useState(false);
      const confirmStatusChange = async () => {
      setShow(false); // Close the modal
      setStatus(selectedStatus); // Update the status locally
      setSubmitloader2(true);
      try {
        const response = await apiService.postRequest(
          `/task/update/status/${taskData}`,
          {
            status: selectedStatus,
          }
        );
        if (response && response.success === true) {
          toast("Status updated successfully!");
          window.location.reload();
          setStatuserror("");
        }else{
          setStatuserror(response.message);
          setStatus("in-progress");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status. Please try again.");
      }finally {
          setSubmitloader2(false); // Stop loader
      }
    };
  
  return (
    <div className='container-fluid px-0'>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>Jobseeker Classification <small className='small_headingstatuses'>{status&&status === "completed" ? "Completed":status === "rejected"? "Rejected":"In Progress"}</small></h3>
                        <a className='seekerondetail' href={`/user/jobseeker-profile/${jobseekerid}`} target="_blank" rel="noopener noreferrer">{jobseekerName}</a>
                    </Grid>
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                {(showform && showform === true) || (editMode && editMode === true) ? <>
                                    <form className='w-100 mt-3' 
                                        onSubmit={handleSubmit}
                                        >
                                        {/* <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                                            <button type='submit' className='registersendbtn2 mb-0'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                                        </Grid> */}
                                        
                                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Referral Verification Status ?<small>*</small></span>
                                                <select name="Q00010" 
                                                    value={formData.Q00010 || ""}
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Referrer and Referee claims don't match">Referrer and Referee claims don't match.</option>
                                                    <option value="Claims match, but Referrer disagrees with referral terms">Claims match, but Referrer disagrees with referral terms.</option>
                                                    <option value="Claims match, and Referrer agrees with referral terms">Claims match, and Referrer agrees with referral terms.</option>
                                                </select>
                                                {errors.Q00010 && <p style={{color:"Red"}}>{errors.Q00010}</p>}
                                            </Grid>
                                            <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                                <span>Referral Verification Decision Change Comments<small>*</small></span>
                                                <textarea placeholder='Describe' name="Q00020" minLength="3" maxLength="4001"
                                                    value={formData.Q00020 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00020 && <p style={{color:"Red"}}>{errors.Q00020}</p>}
                                            </Grid>
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                <h3>Immigration Verification</h3>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Work Autherization expirartion date<small>*</small></span>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        className="date_pickermux"
                                                        value={value || ""}
                                                        name='Q00030'
                                                        onChange={handleDateChange}
                                                        minDate={dayjs().add(1, 'day')}
                                                        renderInput={(params) => <input {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                {errors.Q00030 && <p style={{color:"Red"}}>{errors.Q00030}</p>}
                                            </Grid>
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                <h3>ServiceNow</h3>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Does the Jobseeker seek for a ServiceNow Job ?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00040"
                                                        value="Yes"
                                                        checked={formData.Q00040  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00040"
                                                        value="No"
                                                        checked={formData.Q00040  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00040 && <p style={{color:"Red"}}>{errors.Q00040}</p>}
                                            </Grid>
                                            {opendata1 && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Has the jobseeker previously held at least one project experience with ServiceNow?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00050"
                                                        value="Yes"
                                                        checked={formData.Q00050  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00050"
                                                        value="No"
                                                        checked={formData.Q00050  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00050 && <p style={{color:"Red"}}>{errors.Q00050}</p>}
                                            </Grid>
                                            <Grid size={{xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Does the spouse of the jobseeker a ServiceNow resourse ?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00060"
                                                        value="Yes"
                                                        checked={formData.Q00060  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00060"
                                                        value="No"
                                                        checked={formData.Q00060  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00060 && <p style={{color:"Red"}}>{errors.Q00060}</p>}
                                            </Grid>
                                            </>}
                                            {opendata && <>
                                                <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                    <h3>ServiceNow Spouse Skill Verification</h3>
                                                </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                    <span>Full name of the Jobseeker's Spouse<small>*</small></span>
                                                    <input type='text' placeholder='Name' name="Q00070" minLength="3" maxLength="101"
                                                        value={formData.Q00070 || ""}
                                                        onChange={handleInputChange}/>
                                                    {errors.Q00070 && <p style={{color:"Red"}}>{errors.Q00070}</p>}
                                                </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                    <span>Does the spouse of the Jobseeker hold/held any ServiceNow Certifications?<small>*</small></span>
                                                    <div className='d-flex align-items-center main_radioregister'>
                                                        <input
                                                            type="radio" 
                                                            name="Q00080"
                                                            value="Yes"
                                                            checked={formData.Q00080  === "Yes"} 
                                                            onChange={handleInputChange} 
                                                        />Yes
                                                        <input
                                                            type="radio" 
                                                            name="Q00080"
                                                            value="No"
                                                            checked={formData.Q00080  === "No"} 
                                                            onChange={handleInputChange} 
                                                        />No
                                                    </div>
                                                    {errors.Q00080 && <p style={{color:"Red"}}>{errors.Q00080}</p>}
                                                </Grid>
                                                {opendata3 && <>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>Upload evidence of ServiceNow certifications owned by the spouse of Jobseeker<small>*</small></span>
                                                    <div className="fileUploadInput">
                                                        <input
                                                            type="file"
                                                            name="Q00090"
                                                            id="Q00090"
                                                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                            onChange={handleFileChange}
                                                            style={{ display: 'none' }} // Hide the actual file input
                                                        />
                                                        <label htmlFor="Q00090" style={{ cursor: 'pointer' }}>Upload</label>
                                                        <input
                                                            type="text"
                                                            value={fileNames.Q00090 || ''}
                                                            readOnly
                                                            placeholder="No file chosen"
                                                        />
                                                    </div>
                                                    {errors.Q00090 && <p style={{color:"Red"}}>{errors.Q00090}</p>}
                                                </Grid>
                                                </>}
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>LinkedIn URL of the spouse<small>*</small></span>
                                                    <input
                                                        type="text"
                                                        minLength="3" maxLength="101"
                                                        name="Q00100"
                                                        value={formData.Q00100 || ""}
                                                        onChange={handleInputChange} 
                                                        // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                    />
                                                    {errors.Q00100 && <p style={{color:"Red"}}>{errors.Q00100}</p>}
                                                </Grid>
                                            </>}
                                            {opendata2 && <>
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                <h3>ServiceNow Project Experience</h3>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Does the Jobseeker hold/held any ServiceNow Certifications?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00110"
                                                        value="Yes"
                                                        checked={formData.Q00110  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00110"
                                                        value="No"
                                                        checked={formData.Q00110  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00110 && <p style={{color:"Red"}}>{errors.Q00110}</p>}
                                            </Grid>
                                            {opendata4 && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Upload evidence of ServiceNow certifications owned by the Jobseeker<small>*</small></span>
                                                <div className="fileUploadInput">
                                                    <input
                                                        type="file"
                                                        name="Q00120"
                                                        id="Q00120"
                                                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }} // Hide the actual file input
                                                    />
                                                    <label htmlFor="Q00120" style={{ cursor: 'pointer' }}>Upload</label>
                                                    <input
                                                        type="text"
                                                        value={fileNames.Q00120 || ''}
                                                        readOnly
                                                        placeholder="No file chosen"
                                                    />
                                                </div>
                                                {errors.Q00120 && <p style={{color:"Red"}}>{errors.Q00120}</p>}
                                            </Grid></>}
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Start Date of the Project<small>*</small></span>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        views={['month','year']}
                                                        className="date_pickermux"
                                                        name='Q00130'
                                                        value={value2 || ""}
                                                        inputFormat="MM/YYYY"
                                                        onChange={handleDateChange2}
                                                        maxDate={dayjs().subtract(1, 'day')}
                                                        // renderInput={(params) => <input placeholder="Select Month and Year" {...params} />}
                                                        renderInput={(params) => <input {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                {errors.Q00130 && <p style={{color:"Red"}}>{errors.Q00130}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>End Date of the Project<small>*</small></span>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                    views={['month','year']}
                                                        className="date_pickermux"
                                                        name='Q00140'
                                                        value={value3 || ""}
                                                        onChange={handleDateChange3}
                                                        maxDate={dayjs().subtract(1, 'day')}
                                                        renderInput={(params) => <input {...params} />}
                                                    />
                                                </LocalizationProvider>
                                                {errors.Q00140 && <p style={{color:"Red"}}>{errors.Q00140}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Employer<small>*</small></span>
                                                <input type='text' placeholder='Employer' name="Q00150" minLength="3" maxLength="101"
                                                    value={formData.Q00150 || ""}
                                                    onChange={handleInputChange} />
                                                {errors.Q00150 && <p style={{color:"Red"}}>{errors.Q00150}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Vendor(s) or Implementation Partners<small>*</small></span>
                                                <input type='text' placeholder='Vendor(s) or Implementation Partners' name="Q00160" minLength="3" maxLength="101"
                                                    value={formData.Q00160 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00160 && <p style={{color:"Red"}}>{errors.Q00160}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>End Client<small>*</small></span>
                                                <input type='text' placeholder='End Client' name="Q00170" minLength="3" maxLength="101"
                                                    value={formData.Q00170 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00170 && <p style={{color:"Red"}}>{errors.Q00170}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Job Title<small>*</small></span>
                                                <input type='text' placeholder='Title' name="Q00180" minLength="3" maxLength="101"
                                                    value={formData.Q00180 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00180 && <p style={{color:"Red"}}>{errors.Q00180}</p>}
                                            </Grid>
                                            <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                                <span>Job Duties<small>*</small></span>
                                                <textarea placeholder='Describe' name="Q00190" minLength="3" maxLength="4001"
                                                    value={formData.Q00190 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00190 && <p style={{color:"Red"}}>{errors.Q00190}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Was the Jobseeker ever on a Placement Contract with Tekpossible ?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00200"
                                                        value="Yes"
                                                        checked={formData.Q00200  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00200"
                                                        value="No"
                                                        checked={formData.Q00200  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00200 && <p style={{color:"Red"}}>{errors.Q00200}</p>}
                                            </Grid></>}
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                <h3>Agile Experience</h3>
                                            </Grid>
                                            <Grid size={{xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Does the Jobseeker previously holds at least 3 years of Agile experience?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00210"
                                                        value="Yes"
                                                        checked={formData.Q00210  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00210"
                                                        value="No"
                                                        checked={formData.Q00210  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00210 && <p style={{color:"Red"}}>{errors.Q00210}</p>}
                                            </Grid>
                                            {opendata5 && <>
                                            <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                                <span>Describe the Experience of the project(s) where Jobseeker worked on Agile<small>*</small></span>
                                                <textarea placeholder='Describe' name="Q00220" minLength="3" maxLength="4001"
                                                    value={formData.Q00220 || ""}
                                                    onChange={handleInputChange}/>
                                                {errors.Q00220 && <p style={{color:"Red"}}>{errors.Q00220}</p>}
                                            </Grid></>}
                                            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                                <h3>Relocation</h3>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                                <span>Is the Candidate willing to re-locate for the job ?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00230"
                                                        value="Yes"
                                                        checked={formData.Q00230  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00230"
                                                        value="No"
                                                        checked={formData.Q00230  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00230 && <p style={{color:"Red"}}>{errors.Q00230}</p>}
                                            </Grid>
                                            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                            <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                                                {editMode && 
                                                    <button className='registersendbtn1' onClick={()=> setEditMode(false)}>cancel</button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </form> </> :
                                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Referral Verification Status ?</span>
                                            <p>{formData.Q00010}</p>
                                        </Grid>
                                        {formData.Q00020 && 
                                        <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                            <span>Referral Verification Decision Change Comments</span>
                                            <p>{formData.Q00020}</p>
                                        </Grid>
                                        }
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>Immigration Verification</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Work Autherization expirartion date</span>
                                            <p>{formData.Q00030}</p>
                                        </Grid>
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>ServiceNow</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Does the Jobseeker seek for a ServiceNow Job ?</span>
                                            <p>{formData.Q00040}</p>
                                        </Grid>
                                        {opendata1 && <>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Has the jobseeker previously held at least one project experience with ServiceNow?</span>
                                            <p>{formData.Q00050}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Does the spouse of the jobseeker a ServiceNow resourse ?</span>
                                            <p>{formData.Q00060}</p>
                                        </Grid>
                                        </>}
                                        {opendata && <>
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>ServiceNow Spouse Skill Verification</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Full name of the Jobseeker's Spouse</span>
                                            <p>{formData.Q00070}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Does the spouse of the Jobseeker hold/held any ServiceNow Certifications?</span>
                                            <p>{formData.Q00080}</p>
                                        </Grid>
                                        {opendata3 && <>
                                        {fileNames.Q00090 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Upload evidence of ServiceNow certifications owned by the spouse of Jobseeker</span>
                                            <a href={`${fileNames.Q00090}`} target="_blank" rel="noopener noreferrer"><b>View</b></a>
                                        </Grid>
                                        }</>}
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>LinkedIn URL of the spouse</span>
                                            <p>{formData.Q00100}</p>
                                        </Grid>
                                        </>}
                                        {opendata2 && <>
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>ServiceNow Project Experience</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Does the Jobseeker hold/held any ServiceNow Certifications?</span>
                                            <p>{formData.Q00110}</p>
                                        </Grid>
                                        {opendata4 && <>
                                        {fileNames.Q00120 &&
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Upload evidence of ServiceNow certifications owned by the Jobseeker</span>
                                            <a href={`${fileNames.Q00120}`} target="_blank" rel="noopener noreferrer"><b>View</b></a>
                                        </Grid>
                                        }</>}
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Start Date of the Project</span>
                                            <p>{formData.Q00130}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>End Date of the Project</span>
                                            <p>{formData.Q00140}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Employer</span>
                                            <p>{formData.Q00150}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Vendor(s) or Implementation Partners</span>
                                            <p>{formData.Q00160}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>End Client</span>
                                            <p>{formData.Q00170}</p>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Job Title</span>
                                            <p>{formData.Q00180}</p>
                                        </Grid>
                                        {formData.Q00190 && 
                                        <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                            <span>Job Duties</span>
                                            <p>{formData.Q00190}</p>
                                        </Grid>
                                        }
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Was the Jobseeker ever on a Placement Contract with Tekpossible ?</span>
                                            <p>{formData.Q00200}</p>
                                        </Grid>
                                        </>}
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>Agile Experience</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Does the Jobseeker previously holds at least 3 years of Agile experience?</span>
                                            <p>{formData.Q00210}</p>
                                        </Grid>
                                        {opendata5 && <>
                                        {formData.Q00220 &&
                                        <Grid size={{xs:12}} container flexDirection="column" className="coustom_textarea">
                                            <span>Describe the Experience of the project(s) where Jobseeker worked on Agile</span>
                                            <p>{formData.Q00220}</p>
                                        </Grid>
                                        }</>}
                                        <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead pt-4 pb-0 border_botomer">
                                            <h3>Relocation</h3>
                                        </Grid>
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Is the Candidate willing to re-locate for the job ?</span>
                                            <p>{formData.Q00230}</p>
                                        </Grid>
                                        {statuserror && <p style={{color:"Red",width:"100%"}}>{statuserror}</p>}
                                        {showedit && 
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                <button className='registersendbtn1' onClick={()=> setEditMode(true)}>Edit</button>
                                                <button className='registersendbtn2' onClick={handleslectChange} disabled={!showedit}>{submitloader2 ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Complete'}</button>
                                            </Grid>
                                        }
                                    </Grid>}
                                </Tab>
                                <Tab eventKey="assignment" title="Assignments">
                                    {activeTab === "assignment" && <Assignments />}
                                </Tab>
                                <Tab eventKey="discussions" title="Discussions">
                                    {activeTab === "discussions" && <TaskDisccuss />}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>
                                <Tab eventKey="history" title="History" style={{ flexGrow: 1 }}>
                                    {activeTab === "history" && <TaskHistory />}
                                </Tab>
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
            <Modal
                show={show} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 text-center flex-column align-items-center modal_register modal_statuscomplete">
                <FontAwesomeIcon className='statusicons' icon={faCircleExclamation} />
                    <p>
                    Are you sure you want to change the status to{" "}
                    <strong className='uppercasers'>{selectedStatus}</strong>?
                    </p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='registersendbtn1 my-2 mx-4' onClick={handleClose}>
                    Cancel
                </button>
                <button className='registersendbtn2 my-2' onClick={confirmStatusChange}>
                    Confirm
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default JobseekersClassification

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { apiService } from '../../../services/apiService';
function EditRequest() {
    const navigate = useNavigate();
    const [maxDate, setMaxDate] = useState('');
    useEffect(() => {
        const today = dayjs();
        setMaxDate(today.format('YYYY-MM-DD'));
    }, []);
    
    const userId = localStorage.getItem('userid');
    const [loading, setLoading] = useState(false);
    const [submitloader, setSubmitloader] = useState(false);
    const [formData, setFormData] = useState({
        request_type:'correct_my_profile',
        details:'',
        first_name: '',
        last_name: '',
        phone_number: '',
        dob: '',
        gender: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        why_enroll: '',

        citizenship_status: '',
        awaiting_ead_card: '',
        ead_card_applied_date: '',
        citizenship_explanation: '',
        legally_authorized_to_work: '',
        ssn_issue_year: '',
        work_authorization_expiry: '',
        renew_authorization_work:'',
        laws_and_regulations: '',
        i94_copy: null,
        proof_of_work_authorization: null,
        profile: null,

        first_name1: '',
        last_name1: '',
        phone_number1: '',
        relation_type: '',

        referrer_name: '',
        referral_phone_number: '',
        referrer_relationship: '',
    });
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [relationships, setRelationships] = useState([]);
    const [referrers, setReferrers] = useState([]);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [citizenshipStatuses, setCitizenshipStatuses] = useState([]);
    const [value, setValue] = useState(dayjs(null));
    const [value1, setValue1] = useState(dayjs(null));
    const [value2, setValue2] = useState(dayjs(null));
    const [value3, setValue3] = useState(dayjs(null));
    const [opendata, setOpendata] = useState(false);
    const [opendata2, setOpendata2] = useState(false);
    const [opendata3, setOpendata3] = useState(false);
    const [opendata4, setOpendata4] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [fileNames, setFileNames] = useState({
        i94_copy: "",
        proof_of_work_authorization: "",
        profile: "",
    });

    const [refetchedData, setRefetchedData] = useState({});
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/admin/jobseeker/${userId}`);
                if (response.success === true){
                    const refetchedData = response.data;
                    const immigrationData = response.data.immigration_data;
                    const emergencyconData = response.data.emergency_contact;
                    const referrerData = response.data.referral_info;
                    setRefetchedData(refetchedData);
                    setFormData(prevState => {
                        const updatedFormData = {
                            ...prevState,
                            first_name: refetchedData.first_name || '',
                            last_name: refetchedData.last_name || '',
                            phone_number: refetchedData.phone_number || '',
                            email: refetchedData.email || '',
                            dob: refetchedData.dob || '',
                            gender: refetchedData.gender || '',
                            address: refetchedData.address || '',
                            city: refetchedData.city || '',
                            state: refetchedData.state || '',
                            zipcode: refetchedData.zipcode || '',
                            why_enroll: refetchedData.why_enroll || '',

                            citizenship_status: immigrationData.citizenship_status || '',
                            awaiting_ead_card: immigrationData.awaiting_ead_card || '',
                            ead_card_applied_date: immigrationData.ead_card_applied_date || '',
                            citizenship_explanation: immigrationData.citizenship_explanation || '',
                            legally_authorized_to_work: immigrationData.legally_authorized_to_work || '',
                            ssn_issue_year: immigrationData.ssn_issue_year || '',
                            work_authorization_expiry: immigrationData.work_authorization_expiry || '',
                            renew_authorization_work: immigrationData.renew_authorization_work || '',
                            laws_and_regulations: true || '',
                            i94_copy: immigrationData.i94_copy ? immigrationData.i94_copy : null,
                            proof_of_work_authorization: immigrationData.proof_of_work_authorization ? immigrationData.proof_of_work_authorization : null,
                            profile: refetchedData.profile ? refetchedData.profile : null,
                            
                            first_name1: emergencyconData.first_name || '',
                            last_name1: emergencyconData.last_name || '',
                            phone_number1: emergencyconData.phone_number || '',
                            relation_type: emergencyconData.relation_type || '',

                            referrer_name: referrerData.referrer_name || '',
                            referral_phone_number: referrerData.referral_phone_number || '',
                            referrer_relationship: referrerData.referrer_relationship || '',
                        }
                        setFileNames({
                            i94_copy: immigrationData.i94_copy ? immigrationData.i94_copy.split('/').pop() : '',
                            proof_of_work_authorization: immigrationData.proof_of_work_authorization ? immigrationData.proof_of_work_authorization.split('/').pop() : '',
                            profile: refetchedData.profile ? refetchedData.profile.split('/').pop() : ''
                        });
                        handleCitizenshipValidation(updatedFormData); // Custom function for citizenship validation
                        return updatedFormData;
                    });
                    // Set dob
                    if (refetchedData.dob) {
                        const parsedDob = dayjs(refetchedData.dob, 'YYYY-MM-DD');
                        if (parsedDob.isValid()) {
                            setValue(parsedDob); 
                        }
                    }
                    if (immigrationData.ead_card_applied_date) {
                        const parsedEad = dayjs(immigrationData.ead_card_applied_date, 'YYYY-MM-DD');
                        if (parsedEad.isValid()) {
                            setValue2(parsedEad); 
                        }
                    }
                    if (immigrationData.work_authorization_expiry) {
                        const parsedWorkexp = dayjs(immigrationData.work_authorization_expiry, 'YYYY-MM-DD');
                        if (parsedWorkexp.isValid()) {
                            setValue1(parsedWorkexp);
                        }
                    }
                    if (immigrationData.ssn_issue_year) {
                        const yearDate = dayjs().year(immigrationData.ssn_issue_year);
                        setValue3(yearDate);
                    }
                    
                    // Handle setting state and city logic
                    if (refetchedData.state) {
                        const selectedState = states.find((state) => state.state_code === refetchedData.state);
                        if (selectedState) {
                            setSelectedState({ label: selectedState.name, value: selectedState.state_code });
                        }
        
                        if (refetchedData.city) {
                            const responseCities = await apiService.postRequest(`cities/${refetchedData.state}`);
                            if (responseCities.success) {
                                setCities(responseCities.data || []);
                                setTimeout(() => {
                                    const selectedCity = responseCities.data.find((city) => city.city_name === refetchedData.city);
                                    if (selectedCity) {
                                        setSelectedCity({ label: selectedCity.city_name, value: selectedCity.city_name });
                                    }
                                }, 0);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [userId,states]);


    
    const handleCitizenshipValidation = (formData) => {
        const citizenshipStatus = formData.citizenship_status;
        const validationErrors = {};
    
        if (!citizenshipStatus) {
            validationErrors.citizenship_status = ['Citizenship status is required'];
        } else {
            delete validationErrors.citizenship_status;
        }
    
        let openData3 = false;
        let openData4 = false;
        if (formData.legally_authorized_to_work === 'yes' && citizenshipStatus !== 'USC' && citizenshipStatus !== 'GC') {
            openData3 = true;
        } else if (formData.legally_authorized_to_work === 'no') {
            openData4 = true;
        } else {
            formData.work_authorization_expiry = '';
        }
        
        setOpendata3(openData3);
        setOpendata4(openData4);
        
        if (citizenshipStatus === 'OPT EAD' || citizenshipStatus === 'GC EAD' || citizenshipStatus === 'H4 EAD') {
            setOpendata(true);
        } else {
            setOpendata(false);
            setOpendata2(false);
            formData.awaiting_ead_card = '';
            formData.ead_card_applied_date = '';
            delete validationErrors.awaiting_ead_card;
            delete validationErrors.ead_card_applied_date;
        }
    
        if (formData.awaiting_ead_card === 'yes') {
            setOpendata2(true);
        } else {
            setOpendata2(false);
            formData.ead_card_applied_date = '';
            delete validationErrors.ead_card_applied_date;
        }
        const sixMonthsFromNow = dayjs().add(6, 'month');
        // Ensure formData.work_authorization_expiry is a dayjs object
        const workAuthExpiry = dayjs(formData.work_authorization_expiry);

        if (workAuthExpiry.isBefore(sixMonthsFromNow)) {
            setOpendata4(true);
        } else {
            setOpendata4(false);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest('generalsettings-data');
            if (response.success) {
                setStates(response.states);
                setCitizenshipStatuses(response.citizenship_statuses);
                setRelationships(response.relationships);
                setReferrers(response.referrers);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (formData.state) {
            const fetchData = async () => {
                const response = await apiService.postRequest(`cities/${formData.state}`);
                if (response.success) {
                    setCities(response.data || []);
                    setSelectedCity(null);
                }
            };
            fetchData();
        }
    }, [formData.state]);
    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue(newValue);
            setFormData({ ...formData, dob: newValue.format('YYYY-MM-DD') });
    
            // Clear the error for dob if a valid date is entered
            if (errors.dob) {
                setErrors({ ...errors, dob: '' });
            }
        } else {
            setValue(null);
            setFormData({ ...formData, dob: '' }); // Clear the value if null
        }
    };
    const handleDateChange1 = (newValue) => {
        if (newValue) {
            setValue2(newValue);
            setFormData({ ...formData, ead_card_applied_date: newValue.format('YYYY-MM-DD') });
    
            // Clear the error for dob if a valid date is entered
            if (errors.ead_card_applied_date) {
                setErrors({ ...errors, ead_card_applied_date: '' });
            }
        } else {
            setValue2(null);
            setFormData({ ...formData, ead_card_applied_date: '' });
        }
    };
    useEffect(() => {
        if (formData.work_authorization_expiry) {
            const newValue = dayjs(formData.work_authorization_expiry, 'YYYY-MM-DD');
    
            if (newValue.isValid()) {
                const sixMonthsFromNow = dayjs().add(6, 'month');
                if (newValue.isBefore(sixMonthsFromNow)) {
                    setOpendata4(true);
                } else {
                    setOpendata4(false);
                    setFormData(prevState => ({
                        ...prevState,
                        renew_authorization_work: '',
                    }));
                }
            }
        } else {
            setOpendata4(false);
        }
    }, [formData.work_authorization_expiry]);
    
    const handleDateChange2 = (newValue) => {
        if (newValue) {
            setValue1(newValue);
            const formattedDate = newValue.format('YYYY-MM-DD');
            setFormData(prevState => ({
                ...prevState,
                work_authorization_expiry: formattedDate,
            }));
    
            if (errors.work_authorization_expiry) {
                setErrors({ ...errors, work_authorization_expiry: '' });
            }
        } else {
            setValue1(null);
            setFormData(prevState => ({
                ...prevState,
                work_authorization_expiry: '',
            }));
            setOpendata4(false); // Reset if the date is cleared
        }
    };
    const handleDateChange3 = (newValue) => {
        if (newValue) {
            setValue3(newValue);
            setFormData({ ...formData, ssn_issue_year: newValue.format('YYYY') });

            if (errors.ssn_issue_year) {
                setErrors({ ...errors, ssn_issue_year: '' });
            }
        } else {
            setValue3(null);
            setFormData({ ...formData, ssn_issue_year: '' });
        }
    };
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const file = files[0]; // Get the selected file

            // Update the formData state with the new file
            setFormData((prevData) => ({
                ...prevData,
                [name]: file, // Store the file object for submission
            }));
            console.log(fileNames)
            // Update the fileNames state to show the file name
            setFileNames((prevNames) => ({
                ...prevNames,
                [name]: file.name, // Store the file name for display
            }));
        }
    };
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        // Update the form data state
        if (type === 'radio' || type === 'checkbox') {
            setFormData((prevData) => ({
                ...prevData,
                [name]: type === 'radio' ? value : checked,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

        let validationErrors = { ...errors };
    
        // Validate Citizenship Status
        if (name === 'citizenship_status') {
            if (!value) {
                validationErrors.citizenship_status = ['Citizenship status is required'];
            } else {
                delete validationErrors.citizenship_status;  // Remove error if valid
            }
    
            if (value === 'OPT EAD' || value === 'GC EAD' || value === 'H4 EAD') {
                setOpendata(true);
            } else {
                setOpendata(false);
                setOpendata2(false);
                setFormData(prevData => ({
                    ...prevData,
                    awaiting_ead_card: '',
                    ead_card_applied_date: ''
                }));
                delete validationErrors.awaiting_ead_card;
                delete validationErrors.ead_card_applied_date;
            }
        }
    
        // Validate Awaiting EAD Card
        if (name === 'awaiting_ead_card') {
            if (value === 'yes') {
                setOpendata2(true);
            } else {
                setOpendata2(false);
                setFormData(prevData => ({
                    ...prevData,
                    ead_card_applied_date: ''
                }));
                delete validationErrors.ead_card_applied_date;
            }
        }
        // Validate Awaiting EAD Card
    
        // Validate Explanation of Citizenship
        if (name === 'citizenship_explanation') {
            if (!value) {
                validationErrors.citizenship_explanation = ['Please explain your citizenship status'];
            } else {
                delete validationErrors.citizenship_explanation;
            }
        }
    
        // Validate Legal Authorization to Work
        if (name === 'legally_authorized_to_work') {
            if (!value) {
                validationErrors.legally_authorized_to_work = ['Please select if you are legally authorized to work'];
            } else {
                delete validationErrors.legally_authorized_to_work;
            }
        }

        if (name === 'laws_and_regulations') {
            delete validationErrors.laws_and_regulations;
        }
        
        setErrors(validationErrors);
    };
    useEffect(() => {
            if (formData.legally_authorized_to_work === 'yes' && 
                formData.citizenship_status !== 'USC' && 
                formData.citizenship_status !== 'GC') {
                setOpendata3(true);
                setOpendata4(false);
            }else if (formData.legally_authorized_to_work === 'no'){
                setOpendata4(true);
                setOpendata3(false);
            } else {
                setOpendata3(false);
                setOpendata4(false);
                setFormData(prevData => ({
                    ...prevData,
                    work_authorization_expiry: ''
                }));
            }
        }, [formData.legally_authorized_to_work, formData.citizenship_status]);
    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        handleInputChange({
            target: {
                name: 'state',
                value: selectedOption ? selectedOption.value : ''
            }
        });
        // Clear the city field when state is changed
        setSelectedCity(null);
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption); // Update city
        handleInputChange({
            target: {
                name: 'city',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };
    const handlePhoneNumberChange = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        let formattedInput = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, phone_number: formattedInput });

        const newCursorPosition = cursorPosition + (formattedInput.length - inputValue.length);
        setTimeout(() => {
            inputElement.selectionStart = newCursorPosition;
            inputElement.selectionEnd = newCursorPosition;
        }, 0);

        // Phone number validation
        let validationErrors = { ...errors };
        if (!formattedInput) {
            validationErrors.phone_number = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
            validationErrors.phone_number = 'Phone number must be in the format: (123) 456-7890';
        } else {
            delete validationErrors.phone_number;
        }

        setErrors(validationErrors);
    };
    const handlePhoneNumberChange1 = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        let formattedInput = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, phone_number1: formattedInput });

        const newCursorPosition = cursorPosition + (formattedInput.length - inputValue.length);
        setTimeout(() => {
            inputElement.selectionStart = newCursorPosition;
            inputElement.selectionEnd = newCursorPosition;
        }, 0);

        // Phone number validation
        let validationErrors = { ...errors };
        if (!formattedInput) {
            validationErrors.phone_number1 = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
            validationErrors.phone_number1 = 'Phone number must be in the format: (123) 456-7890';
        } else {
            delete validationErrors.phone_number1;
        }

        setErrors(validationErrors);
    };
    const handlePhoneNumberChange2 = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;

        // Remove non-digit characters
        let formattedInput = inputValue.replace(/\D/g, '');

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, referral_phone_number: formattedInput });

        // Phone number validation
        let validationErrors = { ...errors };
        if (!formattedInput) {
            validationErrors.referral_phone_number = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
            validationErrors.referral_phone_number = 'Phone number must be in the format: (123) 456-7890';
        } else {
            delete validationErrors.referral_phone_number;
        }

        setErrors(validationErrors);
    };
    const validate = () => {
        const validationErrors = {};
        // Form 1
        if (!formData.details.trim()) {
            validationErrors.details = 'Reason for changes is required';
        }
        if (!formData.first_name.trim()) {
            validationErrors.first_name = 'First name is required';
        }
        if (!formData.last_name.trim()) {
            validationErrors.last_name = 'Last name is required';
        }
        if (!formData.phone_number.trim()) {
            validationErrors.phone_number = 'Phone number is required';
        }
        else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone_number1)) {
            validationErrors.phone_number1 = 'Phone number must be in the format: (123) 456-7890';
        }
        if (!formData.dob) {
            validationErrors.dob = ['Date of Birth is required'];
        }
        if (!formData.state) {
            validationErrors.state = ['State is required'];
        }
        if (!formData.city) {
            validationErrors.city = ['City is required'];
        }
        if (!formData.gender) {
            validationErrors.gender = ['Gender is required'];
        }
        if (!formData.address.trim()) {
            validationErrors.address = 'Address is required';
        }
        if (!formData.zipcode.trim()) {
            validationErrors.zipcode = 'Zipcode is required';
        }
        if (formData.zipcode) { // Ensure the field exists before validating
            const zipLength = formData.zipcode.length;
            if (zipLength < 5) {
                validationErrors.zipcode = "Zipcode must be at least 5 characters long";
            } else if (zipLength > 5 && zipLength < 9) {
                validationErrors.zipcode = "Zipcode must be either 5 or at least 9 characters long";
            }
        }
        if (!formData.why_enroll.trim()) {
            validationErrors.why_enroll = 'why enroll with us is required';
        }
        // Form 2
        if (!formData.citizenship_status) {
            validationErrors.citizenship_status = ['Citizenship status is required'];
        }
        if (opendata) {
            if (!formData.awaiting_ead_card) {
                validationErrors.awaiting_ead_card = ['Please select if you are awaiting your EAD Card'];
            }
        }
        if (opendata2) {
            if (!formData.ead_card_applied_date) {
                validationErrors.ead_card_applied_date = ['EAD card application date is required'];
            }
        }
        if (!formData.citizenship_explanation) {
            validationErrors.citizenship_explanation = ['Please explain your citizenship status'];
        }
        if (!formData.legally_authorized_to_work) {
            validationErrors.legally_authorized_to_work = ['Please select if you are legally authorized to work'];
        }
        if (!formData.ssn_issue_year) {
            validationErrors.ssn_issue_year = ['SSN issue year is required'];
        }
        if (opendata3) {
            if (!formData.work_authorization_expiry) {
                validationErrors.work_authorization_expiry = ['Work authorization expiry date is required'];
            }
        }
        if (opendata4) {
            if (!formData.renew_authorization_work) {
                validationErrors.renew_authorization_work = ['Work authorization renewal action details is required'];
            }
        }
        if (!formData.laws_and_regulations) {
            validationErrors.laws_and_regulations = ['Please confirm the authorization to verify I-94'];
        }

        // Check if file fields are null or undefined only (skip strings)
        if (!formData.i94_copy && typeof formData.i94_copy !== 'string') {
            validationErrors.i94_copy = ['I-94 Copy is required'];
        }
        if (!opendata2) {
            if (!formData.proof_of_work_authorization && typeof formData.proof_of_work_authorization !== 'string') {
                validationErrors.proof_of_work_authorization = ['Proof of work authorization is required'];
            }
        }
        // Form 3
        if (!formData.first_name1.trim()) {
            validationErrors.first_name1 = 'First name is required';
        }
        if (!formData.last_name1.trim()) {
            validationErrors.last_name1 = 'Last name is required';
        }
        if (!formData.phone_number1.trim()) {
            validationErrors.phone_number1 = 'Phone number is required';
        }
        else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.phone_number1)) {
            validationErrors.phone_number1 = 'Phone number must be in the format: (123) 456-7890';
        }
        if (!formData.relation_type) {
            validationErrors.relation_type = 'Relationship is required';
        }
        // Form 4
        if (!formData.referrer_name.trim()) {
            validationErrors.referrer_name = 'Referrer name is required';
        }
        if (!formData.referral_phone_number.trim()) {
            validationErrors.referral_phone_number = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData.referral_phone_number)) {
            validationErrors.referral_phone_number = 'Phone number must be in the format: (123) 456-7890';
        }
        if (!formData.referrer_relationship) {
            validationErrors.referrer_relationship = 'Referrer relationship is required';
        }
        return validationErrors;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Reset errors
        setErrors({});
        setSubmitloader(true);
        try {
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const formDataObj = new FormData();
    
        // Check and append text fields if changed
        if (formData.details !== refetchedData.details) {
            formDataObj.append('request_type', 'correct_my_profile');
            formDataObj.append('details', formData.details);
        }
    
        if (formData.first_name !== refetchedData.first_name) {
            formDataObj.append("personal_info[first_name]", formData.first_name);
        }
        if (formData.last_name !== refetchedData.last_name) {
            formDataObj.append("personal_info[last_name]", formData.last_name);
        }
        if (formData.phone_number !== refetchedData.phone_number) {
            formDataObj.append("personal_info[phone_number]", formData.phone_number);
        }
        if (formData.dob !== refetchedData.dob) {
            formDataObj.append("personal_info[dob]", formData.dob);
        }
        if (formData.gender !== refetchedData.gender) {
            formDataObj.append("personal_info[gender]", formData.gender);
        }
        if (formData.address !== refetchedData.address) {
            formDataObj.append("personal_info[address]", formData.address);
        }
        if (formData.city !== refetchedData.city) {
            formDataObj.append("personal_info[city]", formData.city);
        }
        if (formData.state !== refetchedData.state) {
            formDataObj.append("personal_info[state]", formData.state);
        }
        if (formData.zipcode !== refetchedData.zipcode) {
            formDataObj.append("personal_info[zipcode]", formData.zipcode);
        }
        if (formData.why_enroll !== refetchedData.why_enroll) {
            formDataObj.append("personal_info[why_enroll]", formData.why_enroll);
        }
        if (formData.profile !== refetchedData.profile) {
            formDataObj.append("personal_info[profile]", formData.profile);
        }
    
        // Immigration data check and append
        if (formData.citizenship_status !== refetchedData.immigration_data?.citizenship_status) {
            formDataObj.append("immigration_data[citizenship_status]", formData.citizenship_status);
        }
        if (formData.awaiting_ead_card !== refetchedData.immigration_data?.awaiting_ead_card) {
            formDataObj.append("immigration_data[awaiting_ead_card]", formData.awaiting_ead_card);
        }
        if (formData.citizenship_explanation !== refetchedData.immigration_data?.citizenship_explanation) {
            formDataObj.append("immigration_data[citizenship_explanation]", formData.citizenship_explanation);
        }
        if (formData.legally_authorized_to_work !== refetchedData.immigration_data?.legally_authorized_to_work) {
            formDataObj.append("immigration_data[legally_authorized_to_work]", formData.legally_authorized_to_work);
        }
        if (formData.ssn_issue_year !== refetchedData.immigration_data?.ssn_issue_year) {
            formDataObj.append("immigration_data[ssn_issue_year]", formData.ssn_issue_year);
        }
        if (formData.work_authorization_expiry !== refetchedData.immigration_data?.work_authorization_expiry) {
            formDataObj.append("immigration_data[work_authorization_expiry]", formData.work_authorization_expiry);
        }
        if (formData.renew_authorization_work !== refetchedData.immigration_data?.renew_authorization_work) {
            formDataObj.append("immigration_data[renew_authorization_work]", formData.renew_authorization_work);
        }
        if (formData.laws_and_regulations !== refetchedData.immigration_data?.laws_and_regulations) {
            formDataObj.append("immigration_data[laws_and_regulations]", formData.laws_and_regulations);
        }
        if (formData.i94_copy !== refetchedData.immigration_data?.i94_copy) {
            formDataObj.append("immigration_data[i94_copy]", formData.i94_copy);
        }
        if (formData.proof_of_work_authorization !== refetchedData.immigration_data?.proof_of_work_authorization) {
            formDataObj.append("immigration_data[proof_of_work_authorization]", formData.proof_of_work_authorization);
        }
    
        // Emergency contact data check and append
        if (formData.first_name1 !== refetchedData.emergency_contact?.first_name) {
            formDataObj.append("emergency_contact[first_name]", formData.first_name1);
        }
        if (formData.last_name1 !== refetchedData.emergency_contact?.last_name) {
            formDataObj.append("emergency_contact[last_name]", formData.last_name1);
        }
        if (formData.phone_number1 !== refetchedData.emergency_contact?.phone_number) {
            formDataObj.append("emergency_contact[phone_number]", formData.phone_number1);
        }
        if (formData.relation_type !== refetchedData.emergency_contact?.relation_type) {
            formDataObj.append("emergency_contact[relation_type]", formData.relation_type);
        }
    
        // Referral info check and append
        if (formData.referrer_name !== refetchedData.referral_info?.referrer_name) {
            formDataObj.append("referral_info[referrer_name]", formData.referrer_name);
        }
        if (formData.referral_phone_number !== refetchedData.referral_info?.referral_phone_number) {
            formDataObj.append("referral_info[referral_phone_number]", formData.referral_phone_number);
        }
        if (formData.referrer_relationship !== refetchedData.referral_info?.referrer_relationship) {
            formDataObj.append("referral_info[referrer_relationship]", formData.referrer_relationship);
        }
    
        // Send only if there are updated fields
        if (formDataObj.has('request_type') && formDataObj.get('request_type') !== null) {
            // Remove the field if the value is null
            for (let key of Array.from(formDataObj.keys())) {
                const value = formDataObj.get(key);
                if (value === null || value === '' || value === 'null') {
                    console.log(`Deleting key: ${key} with value: ${value}`);
                    formDataObj.delete(key);
                }
            }
            
            console.log("After cleanup:", Array.from(formDataObj.entries()));
    
        
            // Proceed with the API request
            const response = await apiService.postRequest('/submit-requests', formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        
            if (response && response.success === true) {
                toast("Request Sent Successfully");
                navigate("/task/my-request");
            }
        }
    }catch (error) {
        setErrorMessage('An unexpected error occurred.'); 
    }finally {
        setSubmitloader(false);
    }
    };
        
      
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Jobseeker Services</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate("/catalogs")}>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> Correct My Profile</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/requestedit.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>Correct My Profile</h3>
                            <span>Use this form to submit request for Correct My Profile</span>
                            <span>Use this form to request changes to your profile, such as updating your contact information, adjusting your preferences, or correcting any errors.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                            {loading ? 
                                <div className='d-flex justify-content-center w-100'>
                                    <img style={{ width: "100px" }} src={require('../../../assets/pinkloader.gif')} alt='Loading...' />
                                </div> :
                            <form className='w-100' 
                            onSubmit={handleSubmit}
                            >
                                <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                                    <button type='submit' className='registersendbtn2 mb-0'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                                </Grid>
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                                    <h5>Personal Questions</h5>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>First Name<small className='red_starimpo'>*</small></span>
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={formData.first_name}
                                            onChange={handleInputChange} 
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                        {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Last Name<small className='red_starimpo'>*</small></span>
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={formData.last_name}
                                            onChange={handleInputChange} 
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                        {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                                    </Grid>
                                    <Grid size={{ xs:12}} container flexDirection="column">
                                        <span>Email<small className='red_starimpo'>*</small></span>
                                        <small>{formData.email}</small>
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Date of Birth<small className='red_starimpo'>*</small></span>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    className="date_pickermux"
                                                    value={value}
                                                    onChange={handleDateChange}
                                                    maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                                    renderInput={(params) => <input {...params} />}
                                                />
                                            </LocalizationProvider>
                                            {errors.dob && <p style={{color:"Red"}}>{errors.dob}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Gender<small className='red_starimpo'>*</small></span>
                                        <select name="gender" 
                                        value={formData.gender}
                                        onChange={handleInputChange} 
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="prefer_not_to_answer">Prefer not to answer</option>
                                        </select>
                                        {errors.gender && <p style={{color:"Red"}}>{errors.gender}</p>}
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Address<small className='red_starimpo'>*</small></span>
                                        <input type="text" name="address"
                                            value={formData.address}
                                            onChange={handleInputChange}
                                        />
                                        {errors.address && <p style={{color:"Red"}}>{errors.address}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>State<small className='red_starimpo'>*</small></span>
                                        <Select
                                            className="basic-single select_withsearch"
                                            classNamePrefix="select"
                                            defaultValue={null}
                                            name="state"
                                            value={selectedState}
                                            options={states.map((state) => ({
                                                label: state.name,
                                                value: state.state_code,
                                            }))}
                                            onChange={handleStateChange}
                                        />
                                        {errors.state && <p style={{color:"Red"}}>{errors.state}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>City<small className='red_starimpo'>*</small></span>
                                        <Select
                                            className="basic-single select_withsearch"
                                            classNamePrefix="select"
                                            defaultValue={null}
                                            value={selectedCity}
                                            name="city"
                                            options={cities.map((city) => ({
                                                label: city.city_name,
                                                value: city.city_name,
                                            }))}
                                            onChange={handleCityChange}
                                        />
                                        {errors.city && <p style={{color:"Red"}}>{errors.city}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Zip Code<small className='red_starimpo'>*</small></span>
                                        <input type="text" name="zipcode"
                                            value={formData.zipcode}
                                            minLength="5" maxLength="9"
                                            onChange={handleInputChange} 
                                        onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}/>
                                        {errors.zipcode && <p style={{color:"Red"}}>{errors.zipcode}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Phone Number<small className='red_starimpo'>*</small></span>
                                        <input
                                                type="text"
                                                name="phone_number"
                                                value={formData.phone_number}
                                                onChange={handlePhoneNumberChange}
                                                placeholder="***_***_****"
                                                maxLength="14" // To ensure no extra characters
                                                className={errors.phone_number ? 'input-error' : ''}
                                            />
                                            {errors.phone_number && <p style={{color:"Red"}}>{errors.phone_number}</p>}
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Why do you want to enroll with us?<small className='red_starimpo'>*</small></span>
                                        <textarea placeholder='Describe' rows="10" name="why_enroll"
                                            value={formData.why_enroll}
                                            onChange={handleInputChange}
                                            />
                                        {errors.why_enroll && <p style={{color:"Red"}}>{errors.why_enroll}</p>}
                                    </Grid>
                                    <h5>Immigration Questions</h5>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Citizenship Status<small className='red_starimpo'>*</small></span>
                                        <select name="citizenship_status" 
                                        onChange={handleInputChange} 
                                        value={formData.citizenship_status}
                                        >
                                            <option value="" disabled>Select Citizenship Status</option>
                                            {citizenshipStatuses.map((status) => (
                                            <option key={status.id} value={status.status}>
                                                {status.status}
                                            </option>
                                            ))}
                                        </select>
                                        {errors.citizenship_status && <p style={{color:"Red"}}>{errors.citizenship_status}</p>}
                                    </Grid>
                                    {opendata &&
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Are you waiting for your EAD Card to Arrive?<small className='red_starimpo'>*</small></span>
                                        <select name="awaiting_ead_card" value={formData.awaiting_ead_card} 
                                        onChange={handleInputChange} 
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        {errors.awaiting_ead_card && <p style={{color:"Red"}}>{errors.awaiting_ead_card}</p>}
                                    </Grid>
                                    }
                                    {opendata2 &&
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>When did you apply for your EAD Card?<small className='red_starimpo'>*</small></span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className="date_pickermux"
                                                value={value2}
                                                onChange={handleDateChange1}
                                                maxDate={dayjs(maxDate)}
                                                renderInput={(params) => <input {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {errors.ead_card_applied_date && <p style={{color:"Red"}}>{errors.ead_card_applied_date}</p>}
                                    </Grid>
                                    }
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Explain your citizenship status<small className='red_starimpo'>*</small></span>
                                        <textarea placeholder='Describe' rows="10" name="citizenship_explanation" value={formData.citizenship_explanation} 
                                        onChange={handleInputChange}
                                        />
                                        {errors.citizenship_explanation && <p style={{color:"Red"}}>{errors.citizenship_explanation}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Are you legally authorized to work in United States ?<small className='red_starimpo'>*</small></span>
                                        <select name="legally_authorized_to_work" value={formData.legally_authorized_to_work} 
                                        onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                        {errors.legally_authorized_to_work && <p style={{color:"Red"}}>{errors.legally_authorized_to_work}</p>}
                                    </Grid>
                                    {opendata3 &&
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>When will your work authorization expire ?<small className='red_starimpo'>*</small></span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                className="date_pickermux"
                                                value={value1}
                                                onChange={handleDateChange2}
                                                minDate={dayjs(maxDate)}
                                                renderInput={(params) => <input {...params} />}
                                            />
                                        </LocalizationProvider>
                                        {errors.work_authorization_expiry && <p style={{color:"Red"}}>{errors.work_authorization_expiry}</p>}
                                    </Grid>
                                    }
                                    {opendata4 &&
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>What actions have you taken or are planning to take to renew your Work Authorization?<small className='red_starimpo'>*</small></span>
                                        <textarea placeholder='Describe' rows="10" name="renew_authorization_work"
                                            value={formData.renew_authorization_work}
                                            onChange={handleInputChange}
                                            />
                                        {errors.renew_authorization_work && <p style={{color:"Red"}}>{errors.renew_authorization_work}</p>}
                                    </Grid>
                                    }
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Which year was your SSN Issued ?<small className='red_starimpo'>*</small></span>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker className="date_pickermux" views={[ 'year']} maxDate={dayjs(maxDate)} value={value3}
                                                onChange={handleDateChange3} 
                                                renderInput={(params) => <input {...params} />}/>
                                        </LocalizationProvider>
                                        {errors.ssn_issue_year && <p style={{color:"Red"}}>{errors.ssn_issue_year}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Upload Most Recent I94 Copy<small className='red_starimpo'>*</small></span>
                                        <div className="fileUploadInput">
                                        <input
                                            type="file"
                                            name="i94_copy"
                                            id="i94_copy"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }} // Hide the actual file input
                                        />
                                        <label htmlFor="i94_copy" style={{ cursor: 'pointer' }}>Upload</label>
                                        <input
                                            type="text"
                                            value={fileNames.i94_copy || ''}
                                            readOnly
                                            placeholder="No file chosen"
                                        />
                                    </div>
                                        {errors.i94_copy && <p style={{color:"Red"}}>{errors.i94_copy}</p>}
                                    </Grid>
                                    <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                        <span>I hereby authorize agents of TekPossible Consulting to access and verify the authenticity of my uploaded I-94 document through the official I-94 website (<a href="https://i94.cbp.dhs.gov/I94" target="_blank" rel="noopener noreferrer">https://i94.cbp.dhs.gov/I94</a>). I understand that this verification will be conducted in accordance with applicable laws and regulations.<small className='red_starimpo'>*</small></span>
                                        <div className='d-flex align-items-center main_radioregister'>
                                            <input
                                                type="checkbox" 
                                                name="laws_and_regulations"
                                                checked={formData.laws_and_regulations} 
                                                onChange={handleInputChange} 
                                            />I agree
                                        </div>
                                        {errors.laws_and_regulations && <p style={{color:"Red"}}>{errors.laws_and_regulations}</p>}
                                    </Grid>
                                    {!opendata2 &&
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>Upload Proof of Work Authorization<small className='red_starimpo'>*</small></span>
                                            <div className="fileUploadInput">
                                                <input
                                                    type="file"
                                                    id="proof_of_work_authorization"
                                                    name="proof_of_work_authorization"
                                                    onChange={handleFileChange}
                                                    style={{ display: 'none' }} // Hide the actual file input
                                                />
                                                <label htmlFor="proof_of_work_authorization" style={{ cursor: 'pointer' }}>Upload</label>
                                                <input
                                                    type="text"
                                                    value={fileNames.proof_of_work_authorization || ''}
                                                    readOnly
                                                    placeholder="No file chosen"
                                                />
                                            </div>
                                            {errors.proof_of_work_authorization && <p style={{color:"Red"}}>{errors.proof_of_work_authorization}</p>}
                                        </Grid>
                                    }
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Upload Profile Picture</span>
                                        <div className="fileUploadInput">
                                        <input
                                            type="file"
                                            id="profile"
                                            name="profile"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                        />
                                        <label htmlFor="profile" style={{ cursor: 'pointer' }}>Upload</label>
                                        <input
                                            type="text"
                                            value={fileNames.profile || ''}
                                            readOnly
                                            placeholder="No file chosen"
                                        />
                                    </div>
                                    
                                    </Grid>
                                    <h5>Emergency Contact</h5>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>First Name<small className='red_starimpo'>*</small></span>
                                        <input type='text' name="first_name1" 
                                            value={formData.first_name1}
                                            onChange={handleInputChange} 
                                            onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                        {errors.first_name1 && <p style={{color:"Red"}}>{errors.first_name1}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Last Name<small className='red_starimpo'>*</small></span>
                                        <input type='text' name="last_name1" 
                                        value={formData.last_name1}
                                         onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                        {errors.last_name1 && <p style={{color:"Red"}}>{errors.last_name1}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Phone Number<small className='red_starimpo'>*</small></span>
                                        <input
                                            type="text"
                                            name="phone_number1"
                                            value={formData.phone_number1}
                                            onChange={handlePhoneNumberChange1}
                                            placeholder="***_***_****"
                                            maxLength="14" // To ensure no extra characters
                                            className={errors.phone_number1 ? 'input-error' : ''}
                                        />
                                        {errors.phone_number1 && <p style={{color:"Red"}}>{errors.phone_number1}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Relationship<small className='red_starimpo'>*</small></span>
                                        <select name="relation_type" 
                                            value={formData.relation_type}
                                         onChange={handleInputChange}
                                        >
                                            <option value="" disabled>Select</option>
                                            {relationships.map((relationship) => (
                                                <option key={relationship.id} value={relationship.value}>
                                                {relationship.value}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.relation_type && <p style={{color:"Red"}}>{errors.relation_type}</p>}
                                    </Grid>
                                    <h5>Referral information</h5>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Referrer Phone Number<small className='red_starimpo'>*</small></span>
                                        <input
                                            type="text"
                                            name="referral_phone_number"
                                            value={formData.referral_phone_number}
                                            onChange={handlePhoneNumberChange2}
                                            placeholder="123-456-7890"
                                            maxLength="14"
                                            className={errors.referral_phone_number ? 'input-error' : ''}
                                        />
                                        {errors.referral_phone_number && <p style={{color:"Red"}}>{errors.referral_phone_number}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>Referrer Name<small className='red_starimpo'>*</small></span>
                                        <input type='text' name="referrer_name" 
                                        value={formData.referrer_name}
                                         onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                        />
                                        {errors.referrer_name && <p style={{color:"Red"}}>{errors.referrer_name}</p>}
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>How do you know the referrer ?<small className='red_starimpo'>*</small></span>
                                        <select name="referrer_relationship" 
                                        value={formData.referrer_relationship}
                                         onChange={handleInputChange}
                                        >
                                        <option value="" disabled>Select Referrer</option>
                                        {referrers.map((referrer) => (
                                            <option key={referrer.id} value={referrer.value}>
                                            {referrer.value}
                                            </option>
                                        ))}
                                        </select>
                                        {errors.referrer_relationship && <p style={{color:"Red"}}>{errors.referrer_relationship}</p>}
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Reason for Changes<span style={{color:"Red"}}> *</span></span>
                                        <textarea placeholder='Describe' rows="10" name="details"
                                            value={formData.details}
                                            onChange={handleInputChange}
                                            />
                                        {errors.details && <p style={{color:"Red"}}>{errors.details}</p>}
                                    </Grid>
                                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                                        <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button>
                                    </Grid>
                                </Grid>
                            </form>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default EditRequest

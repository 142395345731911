import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import dayjs from 'dayjs';
// import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Select from 'react-select';
import RegistrationLogout from '../../components/common/RegistrationLogout';
function RegisterDetail1() {
    const [value, setValue] = useState(dayjs(null));
    const navigate = useNavigate();
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [submitloader, setSubmitloader] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        phone_number: '',
        dob: '',
        gender: '',
        address: '',
        city: '',
        state: '',
        zipcode: '',
        why_enroll: '',
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');

    const refetchFormData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await apiService.postRequest(`auth/user/history`);
            if (response && response.success) {
                const refetchedData = response.data;
    
                // Update the form data with refetched values
                setFormData({
                    first_name: refetchedData.first_name || '',
                    last_name: refetchedData.last_name || '',
                    phone_number: refetchedData.phone_number || '',
                    dob: refetchedData.dob || '',
                    gender: refetchedData.gender || '',
                    address: refetchedData.address || '',
                    city: refetchedData.city || '',
                    state: refetchedData.state || '',
                    zipcode: refetchedData.zipcode || '',
                    why_enroll: refetchedData.why_enroll || '',
                });
    
                // Set dob
                if (refetchedData.dob) {
                    const parsedDob = dayjs(refetchedData.dob, 'YYYY-MM-DD');
                    if (parsedDob.isValid()) {
                        setValue(parsedDob); 
                    }
                }
    
                // Handle setting state and city logic
                if (refetchedData.state) {
                    const selectedState = states.find((state) => state.state_code === refetchedData.state);
                    if (selectedState) {
                        setSelectedState({ label: selectedState.name, value: selectedState.state_code });
                    }
    
                    if (refetchedData.city) {
                        const responseCities = await apiService.postRequest(`cities/${refetchedData.state}`);
                        if (responseCities.success) {
                            setCities(responseCities.data || []);
                            setTimeout(() => {
                                const selectedCity = responseCities.data.find((city) => city.city_name === refetchedData.city);
                                if (selectedCity) {
                                    setSelectedCity({ label: selectedCity.city_name, value: selectedCity.city_name });
                                }
                            }, 0);
                        }
                    }
                }
            } else {

            }
        } catch (error) {
            console.log(error);
        }finally{
            setLoading(false);
        }
    }, [states]);
    
    useEffect(() => {
        refetchFormData();
    }, [ refetchFormData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Clear the error for the specific field being modified
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({ ...formData, [name]: value });
    };
    const handleDateChange = (newValue) => {
        if (newValue) {
            setValue(newValue);
            setFormData({ ...formData, dob: newValue.format('YYYY-MM-DD') });
    
            // Clear the error for dob if a valid date is entered
            if (errors.dob) {
                setErrors({ ...errors, dob: '' });
            }
        } else {
            setValue(null);
            setFormData({ ...formData, dob: '' }); // Clear the value if null
        }
    };

    const handlePhoneNumberChange = (e) => {
        const inputElement = e.target;
        const inputValue = inputElement.value;
        const cursorPosition = inputElement.selectionStart;

        let formattedInput = inputValue.replace(/\D/g, ''); // Remove non-digit characters

        if (formattedInput.length > 3 && formattedInput.length <= 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d+)/, '($1) $2'); // Format (123) 456
        } else if (formattedInput.length > 6) {
            formattedInput = formattedInput.replace(/(\d{3})(\d{3})(\d+)/, '($1) $2-$3'); // Format (123) 456-7890
        } else if (formattedInput.length <= 3) {
            formattedInput = `(${formattedInput}`; // Start formatting with open bracket (123
        }

        setFormData({ ...formData, phone_number: formattedInput });

        const newCursorPosition = cursorPosition + (formattedInput.length - inputValue.length);
        setTimeout(() => {
            inputElement.selectionStart = newCursorPosition;
            inputElement.selectionEnd = newCursorPosition;
        }, 0);

        // Phone number validation
        let validationErrors = { ...errors };
        if (!formattedInput) {
            validationErrors.phone_number = 'Phone number is required';
        } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formattedInput)) {
            validationErrors.phone_number = 'Phone number must be in the format: (123) 456-7890';
        } else {
            delete validationErrors.phone_number;
        }

        setErrors(validationErrors);
    };
    const validateForm = () => {
        const newErrors = {};

        // Check for empty fields
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = `${key.replace('_', ' ')} is required`;
            }else{
                if (key === "first_name" && formData[key].length < 3) {
                    newErrors[key] = "First Name must be at least 3 characters long";
                }else if (key === "first_name" && formData[key].length > 200) {
                    newErrors[key] = ['First Name must be less than 200 characters long.'];
                }
                if (key === "address" && formData[key].length < 3) {
                    newErrors[key] = "Address must be at least 3 characters long";
                }else if (key === "address" && formData[key].length > 200) {
                    newErrors[key] = ['Address must be less than 200 characters long.'];
                }
                if (key === "why_enroll" && formData[key].length < 3) {
                    newErrors[key] = "Why enroll must be at least 3 characters long";
                }else if (key === "why_enroll" && formData[key].length > 4000) {
                    newErrors[key] = ['Why enroll must be less than 4000 characters long.'];
                }
                if (key === "zipcode") {
                    const zipLength = formData[key].length;
                    if (zipLength < 5) {
                        newErrors[key] = "Zipcode must be at least 5 characters long";
                    } else if (zipLength > 5 && zipLength < 9) {
                        newErrors[key] = "Zipcode must be either 5 or at least 9 characters long";
                    }
                }if (key === "phone_number") {
                    if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(formData[key])) {
                        newErrors[key] = "Phone number must be in the format: (123) 456-7890";
                    }
                }
            }
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
        useEffect(() => {
            const fetchData = async () => {
                const response = await apiService.postRequest('generalsettings-data');
                if (response.success) {
                    setStates(response.states);
                }
            };
            fetchData();
        }, []);
        useEffect(() => {
            if (formData.state) {
                const fetchData = async () => {
                    const response = await apiService.postRequest(`cities/${formData.state}`);
                    if (response.success) {
                        setCities(response.data || []);
                        setSelectedCity(null);
                    }
                };
                fetchData();
            }
        }, [formData.state]);
    
        const handleStateChange = (selectedOption) => {
            setSelectedState(selectedOption);
            handleInputChange({
                target: {
                    name: 'state',
                    value: selectedOption ? selectedOption.value : ''
                }
            });
            // Clear the city field when state is changed
            setSelectedCity(null);
        };
    
        const handleCityChange = (selectedOption) => {
            setSelectedCity(selectedOption); // Update city
            handleInputChange({
                target: {
                    name: 'city',
                    value: selectedOption ? selectedOption.value : ''
                }
            });
        };

        const handleKeyDown = (e) => {
            if (e.target.tagName === 'TEXTAREA') {
                if (e.key === 'Enter' && e.shiftKey) {
                  e.preventDefault();
                  const { selectionStart, selectionEnd } = e.target;
                  const newValue = 
                    e.target.value.substring(0, selectionStart) + 
                    '\n' + 
                    e.target.value.substring(selectionEnd);
                  
                  e.target.value = newValue;
                  
                  // Trigger a change event for React to detect the change
                  const event = new Event('input', { bubbles: true });
                  e.target.dispatchEvent(event);
                  
                  // Move the cursor to the correct position
                  setTimeout(() => {
                    e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
                  }, 0);
                }
              } else if (e.key === 'Enter') {
                e.preventDefault(); // Prevents any action when Enter is pressed
            }
        };
      // Handle form submission
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setErrorMessage('Please fix the errors before proceeding.');
            return;
        }
        
          setSubmitloader(true);
          try {
            const jsonParams = {
                "first_name": formData.first_name,
                "last_name": formData.last_name,
                "phone_number": formData.phone_number,
                "dob": formData.dob,
                "gender": formData.gender,
                "address": formData.address,
                "city": formData.city,
                "state": formData.state,
                "zipcode": formData.zipcode,
                "why_enroll": formData.why_enroll,
              };
              const response = await apiService.postRequest('auth/register/step1', jsonParams);

              // Check if the response indicates success
              if (response && response.success === true) {
                const userId = response.user_id;
                localStorage.setItem('userid', userId);
                if(response.reupdate === true){
                    navigate('/registration/immigration-questions', { state: { fromNextPage: true } })
                }else{
                    navigate('/registration/immigration-questions');
                }
                setErrors({});
                setErrorMessage('');
              } else {
                setErrorMessage('Registration failed. Please try again.');
                if (response.errors) {
                  setErrors(response.errors);
                }
              }
            } catch (error) {
                setErrorMessage('An unexpected error occurred.'); 
            }finally {
                setSubmitloader(false); // Stop loader
            }
        };
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="registerdetailsmain">
        <RegistrationLogout/>
        <Grid size={{lg:10,xs:11}} container alignItems="flex-start" justifyContent="center" className="regisdetailwhite">
            <Grid size={{lg:12}} container>
                <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
                <h2>Register</h2>
                <Grid size={{lg:12,xs:12}} container justifyContent="center">
                    <Grid size={{lg:6,md:6,sm:8,xs:12}} container alignItems="center" justifyContent="space-between" className="progressspan">
                        <span className='activeprogress'>1</span>
                        <span>2</span>
                        <span>3</span>
                        <span>4</span>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :
                <form className='w-100' onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                        <h5>Personal Questions</h5>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>First Name<small className='red_starimpo'>*</small></span>
                            <input
                                disabled
                                type="text"
                                name="first_name"
                                minLength="3"
                                maxLength="201"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                            />
                            {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Last Name<small className='red_starimpo'>*</small></span>
                            <input
                                disabled
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                            />
                            {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Date of Birth<small className='red_starimpo'>*</small></span>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className="date_pickermux"
                                        value={value}
                                        onChange={handleDateChange}
                                        maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                        renderInput={(params) => <input {...params} />}
                                    />
                                </LocalizationProvider>
                                {errors.dob && <p style={{ color: "Red" }}>{errors.dob}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Gender<small className='red_starimpo'>*</small></span>
                            <select name="gender" value={formData.gender} onChange={handleInputChange} >
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="prefer_not_to_answer">Prefer not to answer</option>
                            </select>
                            {errors.gender && <p style={{color:"Red"}}>{errors.gender}</p>}
                        </Grid>
                        <Grid size={{sm:12, xs:12}} container flexDirection="column">
                            <span>Address<small className='red_starimpo'>*</small></span>
                            <input type="text" name="address" value={formData.address} onChange={handleInputChange} 
                            minLength="3"
                            maxLength="201"/>
                            {errors.address && <p style={{color:"Red"}}>{errors.address}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>State<small className='red_starimpo'>*</small></span>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedState}
                                defaultValue={null}
                                name="state"
                                options={states.map((state) => ({
                                    label: state.name,
                                    value: state.state_code,
                                }))}
                                onChange={handleStateChange}
                            />
                            {errors.state && <p style={{color:"Red"}}>{errors.state}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>City<small className='red_starimpo'>*</small></span>
                            <Select
                                className="basic-single select_withsearch"
                                classNamePrefix="select"
                                value={selectedCity}
                                defaultValue={null}
                                name="city"
                                options={cities.map((city) => ({
                                    label: city.city_name,
                                    value: city.city_name,
                                }))}
                                onChange={handleCityChange}
                            />
                            {errors.city && <p style={{color:"Red"}}>{errors.city}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Zip Code<small className='red_starimpo'>*</small></span>
                            <input minLength="5" maxLength="9" type="text" name="zipcode" value={formData.zipcode} onChange={handleInputChange} onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}/>
                            {errors.zipcode && <p style={{color:"Red"}}>{errors.zipcode}</p>}
                        </Grid>
                        <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                            <span>Phone Number<small className='red_starimpo'>*</small></span>
                            <input
                                type="text"
                                name="phone_number"
                                autoComplete="new-password"
                                value={formData.phone_number}
                                onChange={handlePhoneNumberChange}
                                placeholder="***_***_****"
                                maxLength="14" // To ensure no extra characters
                                className={errors.phone_number ? 'input-error' : ''}
                            />
                            {errors.phone_number && <p style={{color:"Red"}}>{errors.phone_number}</p>}
                        </Grid>
                        <Grid size={{sm:12, xs:12}} container flexDirection="column">
                            <span>Why do you want to enroll with us?<small className='red_starimpo'>*</small></span>
                            <textarea placeholder='Describe' rows="10" name="why_enroll" minLength="3" maxLength="4001"
                                value={formData.why_enroll}
                                onChange={handleInputChange}
                                />
                            {errors.why_enroll && <p style={{color:"Red"}}>{errors.why_enroll}</p>}
                        </Grid>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-end">
                            <button className='registersendbtn2' type="submit" >{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save & Next'}</button>
                        </Grid>
                    </Grid>
                </form>
                }
            </Grid>
        </Grid>
    </Grid>
  )
}

export default RegisterDetail1

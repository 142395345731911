import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faChevronLeft, faChevronRight, faFilter, faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { faCircleDown } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../../services/apiService';
import Box from '@mui/material/Box';
import { MenuItem, TextField } from '@mui/material';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TaskTabs from '../../../components/common/TaskTabs';
// const profileImage = require('../../../assets/defaultprofile.svg').default;
function MyCases() {
    const navigate = useNavigate();
    const [myrequests, setMyRequests] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchMyRequest = async () => {
        setLoading(true);
          try {
            const response = await apiService.getRequest(`/my-cases`);
            if(response.success === true){
                setMyRequests(response.data);
            }
          } catch (error) {
            console.error('Error fetching jobseeker status list:', error);
          }finally{
            setLoading(false);
          }
        };
        fetchMyRequest();
    }, []);

    const [isGridVisible, setGridVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    
    const toggleGrid = () => {
        setGridVisible((prev) => !prev);
    };

    const [filters, setFilters] = useState([{ operator: 'And', column: 'formatid', operatorType: 'contains', value: '' }]);


    const combineOptions = [
        { value: 'And', label: 'And' },
        { value: 'Or', label: 'Or' },
    ];
    
    const rows = myrequests.map((datas) => ({
        id: datas.id,
        formatid: datas.format_id,
        status: datas.status,
        stage: datas.stage,
        createdOn: datas.created_at,
        created_by: datas.jobseeker.name,
        onboardSlug: datas.onboarding_slug,
        taskId: datas.task_id,
        requestType: datas.user_request_type,
        type: datas.type,
        onboardUserId: datas.jobseeker_id,
    }));

    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        // Reset value if changing the column type
        if (field === 'column') {
            newFilters[index].value = newFilters[index].column === 'date' ? [null, null] : '';
        }
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);
    };

    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };
    const operatorOptions = [
        { value: 'contains', label: 'contains' },
        { value: 'doesNotContain', label: 'does not contain' },
        { value: 'startsWith', label: 'starts with' },
        { value: 'endsWith', label: 'ends with' },
        { value: 'is', label: 'is' },
        { value: 'isNot', label: 'is not' },
        { value: 'isEmpty', label: 'is empty' },
        { value: 'isNotEmpty', label: 'is not empty' },
    ];
    const getOperatorOptions = (column) => {
        if (column === 'group') {
            return [
                { value: 'contains', label: 'contains' },
                { value: 'doesNotContain', label: 'does not contain' },
                { value: 'startsWith', label: 'starts with' },
                { value: 'endsWith', label: 'ends with' },
                { value: 'is', label: 'is' },
                { value: 'isNot', label: 'is not' },
                { value: 'isOneOf', label: 'is one of' },
                { value: 'isNotOneOf', label: 'is not one of' },
                { value: 'isEmpty', label: 'is empty' },
                { value: 'isNotEmpty', label: 'is not empty' },
            ];
        }
        return operatorOptions; // Default options
    };
    
    const filteredRows = rows.filter((row) =>
        filters.reduce((acc, filter, index) => {
            const rowValue = row[filter.column] ? row[filter.column].toString() : '';
            let matches = false;

            if (filter.column === 'duedate' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.duedate);
                matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            }else if (filter.column === 'createdOn' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.createdOn);
                matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            }else if (filter.column === 'updateOn' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.updateOn);
                matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            }else if (filter.column === 'uploadOn' && Array.isArray(filter.value) && filter.value.length === 2) {
                const [startDate, endDate] = filter.value;
                const rowDate = new Date(row.uploadOn);
                matches = (!startDate || rowDate >= new Date(startDate)) && (!endDate || rowDate <= new Date(endDate));
            }else {
                // General filter logic for other columns
                switch (filter.operatorType) {
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':
                        matches = rowValue !== filter.value;
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    default:
                        matches = false;
                }
            }
    
            return index === 0 ? matches : (filter.operator === 'And' ? acc && matches : acc || matches);
        }, true)
    );
    const paginatedRows = filteredRows.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    const downloadCSV = () => {
        const csvData = filteredRows.map(row => ({
            'Task ID' : row.formatid,
            'Case ID' : row.caseId,
            'Catalog Item' : row.catalogItem,
            'Category' : row.category,
            'Task Title' : row.taskTitle,
            'Assignment Group' : row.assGroup,
            'Assigned To' : row.assignTo,
            'State' : row.status,
            'Due Date' : row.duedate,
            'Created On' : row.createdOn,
            'Upload On' : row.uploadOn,
            'Update On' : row.updateOn,
        }));
        const csv = Papa.unparse(csvData); // Parse the data into CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'filtered_jobseekers.csv'); // Trigger file download
    };

    const columns = [
        // {
        //     field: 'formatid',
        //     headerName: 'Case ID',
        //     width: 200,
        //     renderCell: (params) => (
        //         <div className='d-flex align-items-center h-100'>
        //             <span className='link_datatspan' 
        //                 onClick={() => {navigate(`/task/profile-correction/${params.row.id}`);}}
        //             >
        //                 {params.row.formatid}
        //             </span>
        //         </div>
        //     ),
        //     filterable: true
        // },
        {
            field: 'formatid',
            headerName: 'Case ID',
            width: 200,
            filterable: true,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className='link_datatspan' 
                    onClick={() => { 
                        if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "i94-validation") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.id);
                            navigate(`/task/i94-validation/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "verify-member-details") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/verify-member/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "member-confirmation") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/member-confirmation/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "referral-investigation") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/referral-investigation/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "engagement-overview") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/engagement-overview/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "jobseeker-classification") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/jobseekers-classification/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "setup-servicenow-interview") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/setup-interview/${params.row.onboardUserId}`);
                        } else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "servicenow-interview-evaluation") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/servicenow-interview-evaluation/${params.row.onboardUserId}`);
                        } else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "past-payment-health-approval") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/past-payment-health/${params.row.onboardUserId}`);
                        } else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "application-final-review") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/application-final-review/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "placement-agreement") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/placement-agreements/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "communication-eligibility") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/communication-eligibility/${params.row.onboardUserId}`);
                        }else if (params.row.requestType === null && params.row.type === "onboarding" && params.row.onboardSlug === "admission-fees-payment") {
                            localStorage.setItem('onboardingSlugs', params.row.onboardSlug);
                            localStorage.setItem('onboardUserId', params.row.onboardUserId);
                            localStorage.setItem('onBoardTaskID', params.row.taskId);
                            navigate(`/task/admission-fees-payment/${params.row.onboardUserId}`);
                        }else{
                            navigate(`/task/all-task/${params.row.formatid}`);
                        }
                    }}
                        
                    >
                        {params.row.formatid}
                    </span>
                </div>
            ),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 300,
            filterable: true,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span className={params.row.status === "Pending" ? "stautuspending" : params.row.status === "Closed" ? "stautusclosed" : "stautusprocess"}>{params.row.status}</span>
                </div>
            )
        },
        {
            field: 'stage',
            headerName: 'Stage',
            width: 350,
            filterable: true,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span>{params.row.stage}</span>
                </div>
            ),
        },
        {
            field: 'createdOn',
            headerName: 'Created On',
            width: 200,
            filterable: true,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span>{params.row.createdOn}</span>
                </div>
            )
        },
        {
            field: 'created_by',
            headerName: 'Jobseeker',
            width: 300,
            filterable: true,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <span>{params.row.created_by}</span>
                </div>
            )
        },
    ];
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{xs:12}} container className="main_jobseeker">
                <Grid size={{xs:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Task</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Task <FontAwesomeIcon className='angleright' icon={faAngleRight} /> My Cases</span>
                    </Grid>
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" className="statusselectbtn my-4">
                        <Grid size={{sm:12,xs:12}} container>
                            <TaskTabs/>
                        </Grid>
                    </Grid>
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    {(myrequests && myrequests.length === 0) ?
                    <span className='w-100 text-center'>No Data</span> :
                    <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                        {isGridVisible && (
                        <Box sx={{ mb: 2 }} className="my-4">
                            {filters.map((filter, index) => (
                                <Box key={index} className="d-flex align-items-center flex-wrap">
                                    {index > 0 && (
                                        <select
                                            label="Combine"
                                            value={filter.operator}
                                            onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                                            sx={{ mr: 1, minWidth: 80,mb:1 }}
                                        >
                                            {combineOptions.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    )}
                                    <TextField
                                        select
                                        label="Column"
                                        value={filter.column}
                                        onChange={(e) => handleFilterChange(index, 'column', e.target.value)}
                                        sx={{ mr: 1, minWidth: 120,mb:1 }}
                                    >
                                        <MenuItem value="formatid">Case ID</MenuItem>
                                        <MenuItem value="status">Status</MenuItem>
                                        <MenuItem value="stage">Stage</MenuItem>
                                        <MenuItem value="createdOn">Created On</MenuItem>
                                        <MenuItem value="created_by">Jobseeker</MenuItem>
                                    </TextField>
                                    <TextField
                                        select
                                        label="Operator"
                                        value={filter.operatorType}
                                        onChange={(e) => handleFilterChange(index, 'operatorType', e.target.value)}
                                        sx={{ mr: 1, minWidth: 120,mb:1 }}
                                    >
                                        {getOperatorOptions(filter.column).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </TextField>
                                        {/* {filter.column === 'status' ? (
                                            <select
                                                label="Value"
                                                value={filter.value}
                                                onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                                sx={{ mr: 1, minWidth: 120,mb:1 }}
                                            >
                                                <option value="" disabled>Select Status</option>
                                                <option value="Pending">Pending</option>
                                                <option value="Closed">Closed</option>
                                                <option value="Process">Process</option>
                                            </select>
                                        ) :  */}
                                        {(filter.column === 'createdOn') ? (
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker 
                                                        className='filterrages mb-2'
                                                        label="Start Date"
                                                        value={filter.value[0] || null}
                                                        onChange={(newValue) => handleFilterChange(index, 'value', [newValue, filter.value[1]])}
                                                    />
                                                    <DatePicker
                                                        label="End Date"
                                                        className='filterrages mb-2'
                                                        value={filter.value[1] || null}
                                                        onChange={(newValue) => handleFilterChange(index, 'value', [filter.value[0], newValue])}
                                                    />
                                                </LocalizationProvider>
                                            </Box>
                                        ) :(
                                            <input
                                                className='value_inputfilter mb-2'
                                                placeholder='Value'
                                                label="Value"
                                                value={filter.value}
                                                onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                            />
                                        )}
                                    <button className='filterremovebtn mb-2 ms-0' onClick={() => removeFilter(index)}>Remove</button>
                                </Box>
                            ))}
                            <button className='filteraddbtn' onClick={addFilter}>Add Filter</button>
                        </Box>)}
                        <Grid size={{xs:12}} container justifyContent="space-between">
                        <button className='show_filterbtn' onClick={toggleGrid}>
                            {isGridVisible ?<> Hide Filter <FontAwesomeIcon icon={faFilterCircleXmark} /> </> :<> Show Filter <FontAwesomeIcon icon={faFilter} /> </>}
                        </button>
                        <button className='d-none download_csvbtndatatable' onClick={downloadCSV}>Download CSV<FontAwesomeIcon className='ps-2' icon={faCircleDown} /></button>
                        </Grid>
                        <DataGrid
                            rows={paginatedRows}
                            columns={columns}
                            pageSize={pageSize}
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar }}
                        />
                        <div className='d-flex flex-column my-3 pagination_datagrid'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <label>No. of Rows</label>
                                <select
                                    label="Rows per page"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    sx={{ mr: 2 }}
                                >
                                    {[5, 10, 20, 50].map((size) => (
                                        <option key={size} value={size}>{size}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="d-flex justify-content-center my-3">
                                <button
                                    className='nexter_btns'
                                    variant="contained"
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                                    disabled={currentPage === 0}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>

                                {/* Page number buttons */}
                                <div className="d-flex align-items-center mx-2">
                                    {Array.from({ length: Math.ceil(filteredRows.length / pageSize) }, (_, index) => (
                                        <button
                                            key={index}
                                            className={`page-btn ${currentPage === index ? 'active' : ''}`}
                                            onClick={() => setCurrentPage(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>

                                <button
                                    className='nexter_btns'
                                    variant="contained"
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                                    disabled={currentPage >= Math.ceil(filteredRows.length / pageSize) - 1}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </Box>
                    }
                </>}
            </Grid>
        </div>
    </div>
  )
}

export default MyCases

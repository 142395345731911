import React, { useRef, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./catalogs.css";
import { useNavigate } from 'react-router-dom';
import { faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { apiService } from '../../../services/apiService';
import { toast } from 'react-toastify';
function ReportTechIssue() {
    const navigate = useNavigate();
    const [value, setValue] = useState(dayjs(null));    
    const [formData, setFormData] = useState({
        request_type: 'report_technical_issue',
        issue: '',
        issue_date: '',
        short_description: '',
        description: '',
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [submitloader, setSubmitloader] = useState(false);
    

      const [files, setFiles] = useState([]);
        //   const [newName, setNewName] = useState('');
          const [error, setError] = useState('');
          const fileInputRef = useRef(null);
        const handleInputChange = (e) => {
                    const { name, value } = e.target;
                
                    // Clear the error for the specific field being modified
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        [name]: '',
                    }));
            
                    setFormData({ ...formData, [name]: value });
                };
                const handleDateChange = (newValue) => {
                    if (newValue) {
                        setValue(newValue);
                        setFormData({ ...formData, issue_date: newValue.format('YYYY-MM-DD') });
                
                        // Clear the error for issue_date if a valid date is entered
                        if (errors.issue_date) {
                            setErrors({ ...errors, issue_date: '' });
                        }
                    } else {
                        setValue(null);
                        setFormData({ ...formData, issue_date: '' }); // Clear the value if null
                    }
                };
                  const MAX_FILES = 10;
                  const MAX_TOTAL_SIZE_MB = 2500;
                  const MAX_FILE_SIZE_MB = 250;
                  const allowedTypes = [
                      'image/jpeg', 'image/png', 'image/gif',
                      'application/pdf',
                      'text/csv',
                      'application/msword',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ];
              
                  const handleFileChange = (event) => {
                      const selectedFiles = Array.from(event.target.files);
              
                      // Check if total number of files exceeds limit
                      if (files.length + selectedFiles.length > MAX_FILES) {
                          setError(`You can only upload up to ${MAX_FILES} files.`);
                          return;
                      }
              
                      // Check each file size and type
                      for (const file of selectedFiles) {
                          if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                              setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                              return;
                          }
                          if (!allowedTypes.includes(file.type)) {
                              setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                              return;
                          }
                      }
              
                      // Check total combined size of all files
                      const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
                      const newSize = selectedFiles.reduce((acc, file) => acc + file.size, 0);
                      if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                          setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
                          return;
                      }
              
                      setError('');
                      const updatedFiles = selectedFiles.map((file) => ({
                          file,
                          name: file.name,
                      }));
                      setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
                      fileInputRef.current.value = null;
                  };
              
                  const handleDrop = (event) => {
                      event.preventDefault();
                      const droppedFiles = Array.from(event.dataTransfer.files);
              
                      // Check if total number of files exceeds limit
                      if (files.length + droppedFiles.length > MAX_FILES) {
                          setError(`You can only upload up to ${MAX_FILES} files.`);
                          return;
                      }
              
                      // Check each file size and type
                      for (const file of droppedFiles) {
                          if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                              setError(`Each file must be less than ${MAX_FILE_SIZE_MB} MB.`);
                              return;
                          }
                          if (!allowedTypes.includes(file.type)) {
                              setError('Only image files (JPEG, PNG, GIF), CSV, PDF, and Word files are allowed.');
                              return;
                          }
                      }
              
                      // Check total combined size of all files
                      const currentSize = files.reduce((acc, file) => acc + file.file.size, 0);
                      const newSize = droppedFiles.reduce((acc, file) => acc + file.size, 0);
                      if (currentSize + newSize > MAX_TOTAL_SIZE_MB * 1024 * 1024) {
                          setError(`Total file size cannot exceed ${MAX_TOTAL_SIZE_MB} MB.`);
                          return;
                      }
              
                      setError('');
                      const updatedFiles = droppedFiles.map((file) => ({
                          file,
                          name: file.name,
                      }));
                      setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
                  };
              
              
                  const handleRemoveFile = (index) => {
                     
                      setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
                      // Reset the file input to allow the same file to be uploaded again
                      fileInputRef.current.value = null;
                  };
                const handleDragOver = (event) => {
                    event.preventDefault();
                };
                const validateForm = () => {
                    const newErrors = {};
            
                    // Check for empty fields
                    Object.keys(formData).forEach((key) => {
                        if (!formData[key]) {
                            newErrors[key] = `${key.replace('_', ' ')} is required`;
                        }else{
                            if (key === "short_description" && formData[key].length < 3) {
                                newErrors[key] = "Short description must be at least 3 characters long";
                            }else if (key === "short_description" && formData[key].length > 200) {
                                newErrors[key] = ['Short description must be less than 200 characters long.'];
                            }
                            if (key === "description" && formData[key].length < 3) {
                                newErrors[key] = "Description must be at least 3 characters long";
                            }else if (key === "description" && formData[key].length > 4000) {
                                newErrors[key] = ['Description must be less than 4000 characters long.'];
                            }
                        }
                    });
                    setErrors(newErrors);
                    return Object.keys(newErrors).length === 0;
                };
                const handleSubmit = async (e) => {
                    e.preventDefault();
                    if (!validateForm()) {
                        setErrorMessage('Please fix the errors before proceeding 1.');
                        return;
                    }
                    setSubmitloader(true);
                    try {
                        const formDataToSend = new FormData();
        
                        // Add file attachments
                        files.forEach((file) => {
                            formDataToSend.append('attachments[]', file.file);
                        });
        
                        // Add other form fields
                        for (const key in formData) {
                            if (formData[key] !== undefined && formData[key] !== null) {
                                formDataToSend.append(key, formData[key]);
                            }
                        }
                        const response = await apiService.postRequest('submit-requests', formDataToSend);
            
                        // Check if the response indicates success
                        if (response && response.success === true) {
                        setErrors({});
                        setErrorMessage('');
                        toast("Submitted Successfully !")
                        } else {
                        setErrorMessage('Failed. Please try again.');
                        if (response.errors) {
                            setErrors(response.errors);
                        }
                        }
                    } catch (error) {
                        setErrorMessage('An unexpected error occurred.'); 
                    }finally {
                        setSubmitloader(false); // Stop loader
                    }
                };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>Report Technical Issues</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> <b onClick={()=>navigate(-1)}>Catalog</b><FontAwesomeIcon className='angleright' icon={faAngleRight} /> Report Technical Issues</span>
                    </Grid>
                </Grid>
                <Grid size={{md:12,xs:12}} container justifyContent="flex-end" alignItems="center" className="my-4 request_user_edit">
                    <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                        <Grid size={{md:4,sm:3,xs:12}} container className="mt-3">
                            <img src={require('../../../assets/Requestedits/report_tec_issue.png')} alt=''/>
                        </Grid>
                        <Grid size={{md:7,sm:8,xs:12}} container flexDirection="column" justifyContent="flex-start" className="mt-3 requestedit_quat px-3">
                            <h3>Report Technical Issues</h3>
                            <span>To provide a platform for jobseekers to report any issues, bugs, or errors they encounter while using the jobseekers portal. This will help improve the overall user experience and identify areas for improvement.</span>
                        </Grid>
                        <Grid size={{xs:12}} container>
                        <form className='w-100' 
                            onSubmit={handleSubmit}
                            >
                                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs">
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>What is the issue related to?<span style={{color:"red"}}> *</span></span>
                                        <select name="issue" value={formData.issue} onChange={handleInputChange} >
                                            <option value="">Select Issue</option>
                                            <option value="Registration">Registration</option>
                                            <option value="Login">Login</option>
                                            <option value="Forget Password">Forget Password</option>
                                        </select>
                                        {errors.issue && <p style={{color:"Red"}}>{errors.issue}</p>}
                                    </Grid>
                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                        <span>When did you first notice the issue ?<span style={{color:"red"}}> *</span></span>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    className="date_pickermux"
                                                    value={value}
                                                    onChange={handleDateChange}
                                                    maxDate={dayjs().subtract(1, 'day')}
                                                    renderInput={(params) => <input {...params} />}
                                                />
                                            </LocalizationProvider>
                                            {errors.issue_date && <p style={{color:"Red"}}>{errors.issue_date}</p>}
                                    </Grid>
                                    <Grid size={{ xs:12}} container>
                                        <Grid size={{xs:12}} container flexDirection="column">
                                            <span>Short Description<span style={{color:"red"}}> *</span></span>
                                            <input value={formData.short_description} onChange={handleInputChange}
                                                type="text"
                                                name="short_description"
                                                minLength="3"
                                                maxLength="201"
                                            />
                                            {errors.short_description && <p style={{color:"Red"}}>{errors.short_description}</p>}
                                        </Grid>
                                    </Grid>
                                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                                        <span>Description<span style={{color:"Red"}}> *</span></span>
                                        <textarea placeholder='Describe' minLength="3" maxLength="4001" value={formData.description} onChange={handleInputChange} rows="10" name="description"/>
                                        {errors.description && <p style={{color:"Red"}}>{errors.description}</p>}
                                    </Grid>
                                    <Grid size={{xs:12}} container flexDirection="column">
                                    <span>Please attach screenshots describing the issue</span>
                                    <Grid size={{xs: 12 }} container justifyContent="space-between">
                                    <div
                                        onDrop={handleDrop}
                                        onDragOver={handleDragOver}
                                        className='drag_uploaders align-items-center flex-column d-flex'
                                    >
                                        <FontAwesomeIcon className='file_uploadericon' icon={faFileLines} />
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            id="file-upload"
                                            ref={fileInputRef} // Attach the ref here
                                        />
                                        <label htmlFor="file-upload">
                                            Browse Files
                                        </label>
                                        <p className='w-100 mb-0 mt-3'>or drop file here</p>
                                    </div>
                            {error && <div style={{ color: 'red', margin: '10px 0px' }}>{error}</div>}
                            </Grid>
                            <Grid size={{xs:12}} container justifyContent="space-between" className="mt-4">
                                {files.map((file, index) => (
                                    <Grid key={index} size={{xs:12}} container justifyContent="space-between" alignItems="center" className="tablelisterattach reportincident">
                                        <Grid container className="pinkbgattachreport" alignItems="center">
                                            <span className='spanreport_elipse'>{index + 1}<span className='filenamesshow ms-3'>{file.name}</span></span>
                                            <button onClick={(e) => handleRemoveFile(index)}>
                                                <FontAwesomeIcon icon={faXmark} />
                                            </button>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                                </Grid>
                                {errorMessage && <div style={{ color: 'red', margin: '10px 0px' }}>{errorMessage}</div>}
                                <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2">
                                    <button type="submit" className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Submit'}</button>
                                </Grid>
                            </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default ReportTechIssue

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import Assignments from '../I94Validation/Assignments';
import TaskDisccuss from '../I94Validation/TaskDisccuss';
import TaskKnowledge from '../I94Validation/TaskKnowledge';
import TaskHistory from '../I94Validation/TaskHistory';
function ApplicationFinalReview() {
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const { userid } = useParams();
    const navigate = useNavigate();
    const taskData = localStorage.getItem('onBoardTaskID');
    const [showform, setShowform] = useState(true);
    const [showedit, setShowedit] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("");
    const [engagedata, seEngageedata] = useState("");
    const [jobseekerName, setJobseekername] = useState("");
    const [jobseekerid, setJobseekerid] = useState("");
    const [caseids, setcaseid] = useState("");
    const [taskids, settaskid] = useState("");
    const [activeTab, setActiveTab] = useState("overview");
    useEffect(() => {
        const fetchData = async () => {
            try {
            const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
            if (response.success && response.success === true) {
                localStorage.setItem('onBoardTaskID', response.data.task.id);
                setJobseekername(response.data.jobseeker.name);
                setJobseekerid(response.data.jobseeker.id);
                setcaseid(response.data.task.case_id);
                settaskid(response.data.task.task_format_id);
                setStatus(response.data.status_string);
                seEngageedata(response.data.engagement_overview_form_data.Q00020);
                const refetchedData = response.data.form_data;
                setFormData({
                    Q00010: refetchedData.Q00010,
                    Q00020: refetchedData.Q00020,
                    Q00030: refetchedData.Q00030,
                    Q00031: refetchedData.Q00031,
                    Q00032: refetchedData.Q00032,
                    Q00033: refetchedData.Q00033,
                    Q00034: refetchedData.Q00034,
                    Q00035: refetchedData.Q00035,
                    Q00036: refetchedData.Q00036,
                    Q00037: refetchedData.Q00037,
                    Q00038: refetchedData.Q00038,
                    Q00050: refetchedData.Q00050,
                });
                if ((
                    (response && response.data.form_data && Object.keys(response.data.form_data).length === 0) ||
                    (response && response.data.form_data && (!response.data.form_data.Q00010 || Object.keys(response.data.form_data.Q00010).length === 0)))
                    && (response && response.data.status_string === "in-progress")
                ){
                setShowform(true);
                }else{
                    if(response && response.data.form_data && response.data.form_data.Q00010 === "Yes"){
                        setOpendata1(true);
                    }else{
                        setOpendata1(false);
                    }
                    if(response && response.data.form_data && response.data.form_data.Q00010 === "Yes" && (response.data.engagement_overview_form_data.Q00020 && Array.isArray(response.data.engagement_overview_form_data.Q00020) && response.data.engagement_overview_form_data.Q00020.includes("C2C"))){
                        setOpendata(true);
                    }else{
                        setOpendata(false);
                    }
                    if(response && response.data.form_data && response.data.form_data.Q00010 === "Yes" && (response.data.engagement_overview_form_data.Q00020 && Array.isArray(response.data.engagement_overview_form_data.Q00020) && response.data.engagement_overview_form_data.Q00020.includes("W2"))){
                        setOpendata2(true);
                    }else{
                        setOpendata2(false);
                    }
                    if(response && response.data.form_data && response.data.form_data.Q00010 === "Yes" && (response.data.engagement_overview_form_data.Q00020 && Array.isArray(response.data.engagement_overview_form_data.Q00020) && response.data.engagement_overview_form_data.Q00020.includes("FTE"))){
                        setOpendata3(true);
                    }else{
                        setOpendata3(false);
                    }
                setShowform(false);
                }
            }
            } catch (error) {
            console.error("Error fetching onboarding data:", error);
            } finally {
            setLoading(false);
            }
        };
    
        fetchData();
    }, [onboardslug, userid]);

    useEffect(() => {
        const fetchCurrentData = async () => {
            try {
            const response = await apiService.getRequest(`/current-onboarding/${userid}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug) && response.status && response.status["application-final-review"] === "in-progress"){
                    setShowedit(true);
                }else{
                    setShowedit(false)
                }
            }
            } catch (error) {
            console.error("Error fetching onboarding data:", error);
            } finally {
            setLoading(false);
            }
        };
    
        fetchCurrentData();
    }, [ userid,onboardslug]);
    const [formData, setFormData] = useState({
        Q00010: "",
        Q00020: "",
        Q00030: "",
        Q00031: "",
        Q00032: "",
        Q00033: "",
        Q00034: "",
        Q00035: "",
        Q00036: "",
        Q00037: "",
        Q00038: "",
        Q00050: "",
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [opendata, setOpendata] = useState(false);
    const [opendata1, setOpendata1] = useState(false);
    const [opendata2, setOpendata2] = useState(false);
    const [opendata3, setOpendata3] = useState(false);
        // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

        // Validate Q00010
        if (name === 'Q00010') {
            if (value === 'Yes') {
                setOpendata1(true);
            } else {
                setOpendata1(false);
            }
        }
        if (name === 'Q00010') {
            if (value === 'Yes' && (engagedata && Array.isArray(engagedata) && engagedata.includes("C2C"))) {
                setOpendata(true);
            } else {
                setOpendata(false);
            }
        }
        if (name === 'Q00010') {
            if (value === 'Yes' && (engagedata && Array.isArray(engagedata) && engagedata.includes("W2"))) {
                setOpendata2(true);
            } else {
                setOpendata2(false);
            }
        }
        if (name === 'Q00010') {
            if (value === 'Yes' && (engagedata && Array.isArray(engagedata) && engagedata.includes("FTE"))) {
                setOpendata3(true);
            } else {
                setOpendata3(false);
            }
        }
    };

    // Validate form fields
    const validateForm = () => {
    const newErrors = {};
    if (!formData.Q00010) newErrors.Q00010 = "Move forward with the Jobseeker is requied.";
    if(opendata1){
        if (!formData.Q00020) newErrors.Q00020 = "Jobseeker Classification is required.";
    }
    if(opendata){
        if (!formData.Q00030) newErrors.Q00030 = "C2C Deal Type is required.";
        if (!formData.Q00031) newErrors.Q00031 = "C2C Fixed Margin For TekP is required.";
        if (!formData.Q00032) newErrors.Q00032 = "C2C Fixed Ratio for TekP on Vendor Hourly Billing Rate is required.";
    }
    if(opendata2){
        if (!formData.Q00033) newErrors.Q00033 = "W2 Deal Type is required.";
        if (!formData.Q00034) newErrors.Q00034 = "W2 Fixed Margin for TekP is required.";
        if (!formData.Q00035) newErrors.Q00035 = "W2 Fixed Ratio for TekP on Employer Hourly Billing Rate (Before Tax) is required.";
    }
    if(opendata3){
        if (!formData.Q00036) newErrors.Q00036 = "FTE Deal Type is required.";
        if (!formData.Q00037) newErrors.Q00037 = "FTE Fixed Margin for TekP is required.";
        if (!formData.Q00038) newErrors.Q00038 = "FTE Fixed Ratio for TekP on Employer Annual Billing Rate (Before Tax) is required.";
    }
    if (!formData.Q00050) newErrors.Q00050 = "Comments is required.";
    if (formData.Q00050 && formData.Q00050.length < 3) {
        newErrors.Q00050 = "Comments must be at least 3 characters long.";
    }else if (formData.Q00050 && formData.Q00050.length > 4000) {
        newErrors.Q00050 = 'Comments must be less than 4000 characters long.';
    }  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
    };
    // Handle form submission
    const [submitloader,setSubmitloader] = useState(false);
    const handleSubmit = async (e) => { 
    e.preventDefault();
    if (!validateForm()) {
        setErrorMessage('Please fix the errors before proceeding.');
        return;   
    }
    setSubmitloader(true);
    try {
        const response = await apiService.postRequest(`onboarding/${onboardslug}/${userid}/submit`, formData);

        // Check response for success
        if (response.success) {
            setErrors({});
            setErrorMessage('');
            setEditMode(false);
            setShowform(false);
            if(response.success && response.status === "rejected"){
                window.location.reload();
            }
        } else {
            setErrorMessage(response.message);
            if (response.errors) { 
                setErrors(response.errors);
            }
        }
    } catch (error) {
        setErrorMessage('Registration failed. Please try again.');
    } finally {
        setSubmitloader(false); // Stop loader
    }
    };
    

    const [selectedStatus, setSelectedStatus] = useState("");
    const [show, setShow] = useState(false);

    const handleslectChange = () => {
        setSelectedStatus("completed");
        setShow(true);
    };

    const handleClose = () => setShow(false);
   
    const [statuserror,setStatuserror] = useState("");
    const [submitloader2,setSubmitloader2] = useState(false);
    const confirmStatusChange = async () => {
    setShow(false); // Close the modal
    setStatus(selectedStatus); // Update the status locally
    setSubmitloader2(true);
    try {
      const response = await apiService.postRequest(
        `/task/update/status/${taskData}`,
        {  
          status: selectedStatus,
        }
      );
      if (response && response.success === true) {
        toast("Status updated successfully!");
        window.location.reload();
        setStatuserror("");
      }else{
        setStatuserror(response.message);
        setStatus("in-progress");
      }
    } catch (error) { 
      console.error("Error updating status:", error);
      toast.error("Failed to update status. Please try again.");
    }finally {
        setSubmitloader2(false); // Stop loader
    }
  };
  
  return (
    <div className='container-fluid px-0'>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
            <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>Application Final Review <small className='small_headingstatuses'>{status&&status === "completed" ? "Completed":status === "rejected"? "Rejected":"In Progress"}</small></h3>
                        <a className='seekerondetail' href={`/user/jobseeker-profile/${jobseekerid}`} target="_blank" rel="noopener noreferrer">{jobseekerName}</a>
                    </Grid>  
                    {loading ?   
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                {(showform && showform === true) || (editMode && editMode === true) ? <>
                                    <form className='w-100 mt-3'
                                        onSubmit={handleSubmit}
                                        >
                                        
                                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                                            <Grid size={{sm:12}} container flexDirection="column">
                                                <span>Should we move forward with the Jobseeker ?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00010"
                                                        value="Yes"
                                                        checked={formData.Q00010  === "Yes"} 
                                                        onChange={handleInputChange}
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00010"
                                                        value="No"
                                                        checked={formData.Q00010  === "No"} 
                                                        onChange={handleInputChange}
                                                    />No
                                                </div>
                                                {errors.Q00010 && <p style={{color:"Red"}}>{errors.Q00010}</p>}
                                            </Grid>
                                            {opendata1 && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>What is Jobseeker Classification ?<small>*</small></span>
                                                <select name="Q00020" 
                                                value={formData.Q00020}
                                                onChange={handleInputChange} 
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Safe">Safe</option>
                                                    <option value="Risky">Risky</option>
                                                </select>
                                                {errors.Q00020 && <p style={{color:"Red"}}>{errors.Q00020}</p>}
                                            </Grid></>}
                                            {opendata && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>C2C Deal Type<small>*</small></span>
                                                <select name="Q00030" 
                                                    value={formData.Q00030}
                                                    onChange={handleInputChange} 
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Ratio">Ratio</option>
                                                        <option value="Fixed">Fixed</option>
                                                </select>
                                                {errors.Q00030 && <p style={{color:"Red"}}>{errors.Q00030}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>C2C Fixed Margin For TekP{opendata &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00031" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00031}
                                                    onChange={handleInputChange} />
                                                {errors.Q00031 && <p style={{color:"Red"}}>{errors.Q00031}</p>}
                                            </Grid>                           
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>C2C Fixed Ratio for TekP on Vendor Hourly Billing Rate{opendata &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00032" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00032}
                                                    onChange={handleInputChange} />
                                                {errors.Q00032 && <p style={{color:"Red"}}>{errors.Q00032}</p>}
                                            </Grid></>}
                                            {opendata2 && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>W2 Deal Type ?<small>*</small></span>
                                                <select name="Q00033" 
                                                    value={formData.Q00033}
                                                    onChange={handleInputChange} 
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="Ratio">Ratio</option>
                                                        <option value="Fixed">Fixed</option>
                                                </select>
                                                {errors.Q00033 && <p style={{color:"Red"}}>{errors.Q00033}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>W2 Fixed Margin for TekP{opendata2 &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00034" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00034}
                                                    onChange={handleInputChange} />
                                                {errors.Q00034 && <p style={{color:"Red"}}>{errors.Q00034}</p>}
                                            </Grid>                           
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>W2 Fixed Ratio for TekP on Employer Hourly Billing Rate (Before Tax){opendata2 &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00035" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00035}
                                                    onChange={handleInputChange} />
                                                {errors.Q00035 && <p style={{color:"Red"}}>{errors.Q00035}</p>}
                                            </Grid></>}      
                                            {opendata3 && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>FTE Deal Type<small>*</small></span>
                                                <select name="Q00036" 
                                                    value={formData.Q00036}
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select</option>
                                                    <option value="Ratio">Ratio</option>
                                                    <option value="Fixed">Fixed</option>
                                                </select>
                                                {errors.Q00036 && <p style={{color:"Red"}}>{errors.Q00036}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>FTE Fixed Margin for TekP{opendata3 &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00037" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00037}
                                                    onChange={handleInputChange} />
                                                {errors.Q00037 && <p style={{color:"Red"}}>{errors.Q00037}</p>}
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>FTE Fixed Ratio for TekP on Employer Annual Billing Rate (Before Tax){opendata3 &&<small>*</small>}</span>
                                                <input type='text' placeholder='Enter in Number' name="Q00038" 
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    value={formData.Q00038}
                                                    onChange={handleInputChange} />
                                                {errors.Q00038 && <p style={{color:"Red"}}>{errors.Q00038}</p>}
                                            </Grid></>}
                                            <Grid size={{xs:12}} container className="for_textarespec" flexDirection="column">
                                                <span>Comments<small>*</small></span>
                                                <textarea placeholder='Write your comments' minLength="3" maxLength="4001" name='Q00050' value={formData.Q00050} onChange={handleInputChange}/>
                                                {errors.Q00050 && <p style={{color:"Red"}}>{errors.Q00050}</p>}
                                            </Grid>                           
                                            {errorMessage && <p style={{ color: 'red',width:"100%" }}>{errorMessage}</p>}
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                                                {editMode && 
                                                    <button className='registersendbtn1' onClick={()=> setEditMode(false)}>cancel</button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </form></> :
                                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                                        <Grid size={{xs:12}} container className="mt-3" flexDirection="column">
                                            <span>Should we move forward with the Jobseeker ?<small>*</small></span>
                                            <p>{formData.Q00010}</p>
                                        </Grid>
                                        {opendata1 && <>
                                        <Grid size={{xs:12}} container flexDirection="column">
                                            <span>What is Jobseeker Classification ?<small>*</small></span>
                                            <p>{formData.Q00020}</p>
                                        </Grid></>}
                                        {opendata && <>
                                        {formData.Q00030 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>C2C Deal Type<small>*</small></span>
                                            <p>{formData.Q00030}</p>
                                        </Grid>}
                                        {formData.Q00031 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>C2C Fixed Margin For TekP<small>*</small></span>
                                            <p>{formData.Q00031}</p>
                                        </Grid>}
                                        {formData.Q00032 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>C2C Fixed Ratio for TekP on Vendor Hourly Billing Rate<small>*</small></span>
                                            <p>{formData.Q00032}</p>
                                        </Grid>}</>}
                                        {opendata2 && <>
                                        {formData.Q00030 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>W2 Deal Type ?<small>*</small></span>
                                            <p>{formData.Q00033}</p>
                                        </Grid>}
                                        {formData.Q00034 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>W2 Fixed Margin for TekP<small>*</small></span>
                                            <p>{formData.Q00034}</p>
                                        </Grid>}
                                        {formData.Q00035 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>W2 Fixed Ratio for TekP on Employer Hourly Billing Rate (Before Tax)<small>*</small></span>
                                            <p>{formData.Q00035}</p>
                                        </Grid>}</>}
                                        {opendata3 && <>
                                        {formData.Q00036 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>FTE Deal Type<small>*</small></span>
                                            <p>{formData.Q00036}</p>
                                        </Grid>}
                                        {formData.Q00037 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>FTE Fixed Margin for TekP<small>*</small></span>
                                            <p>{formData.Q00037}</p>
                                        </Grid>}
                                        {formData.Q00038 && 
                                        <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                            <span>FTE Fixed Ratio for TekP on Employer Annual Billing Rate (Before Tax)<small>*</small></span>
                                            <p>{formData.Q00038}</p>
                                        </Grid>}</>}
                                        <Grid size={{ xs:12}} container flexDirection="column">
                                            <span>Comments<small>*</small></span>
                                            <p>{formData.Q00050}</p>
                                        </Grid>
                                        {statuserror && <p style={{color:"Red",width:"100%"}}>{statuserror}</p>}
                                        {showedit && 
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                <button className='registersendbtn1' onClick={()=> setEditMode(true)}>Edit</button>
                                                <button className='registersendbtn2' onClick={handleslectChange} disabled={!showedit}>{submitloader2 ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Complete'}</button>
                                            </Grid>
                                        }
                                    </Grid>}
                                </Tab>
                                <Tab eventKey="assignment" title="Assignments">
                                    {activeTab === "assignment" && <Assignments />}
                                </Tab>  
                                <Tab eventKey="discussions" title="Discussions">
                                    {activeTab === "discussions" && <TaskDisccuss />}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>   
                                <Tab eventKey="history" title="History" style={{ flexGrow: 1 }}>
                                    {activeTab === "history" && <TaskHistory />}
                                </Tab> 
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
            <Modal
                show={show} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 text-center flex-column align-items-center modal_register modal_statuscomplete">
                <FontAwesomeIcon className='statusicons' icon={faCircleExclamation} />
                    <p>
                    Are you sure you want to change the status to{" "}
                    <strong className='uppercasers'>{selectedStatus}</strong>?
                    </p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='registersendbtn1 my-2 mx-4' onClick={handleClose}>
                    Cancel
                </button>
                <button className='registersendbtn2 my-2' onClick={confirmStatusChange}>
                    Confirm
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default ApplicationFinalReview

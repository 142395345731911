import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./login.css";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import Modal from 'react-bootstrap/Modal';
function RegisterIds() {
    const navigate = useNavigate();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState('');
    const [emailVerificationError, setEmailVerificationError] = useState('');
    const [emailVerified, setEmailVerified] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);
    const [isVerifying2, setIsVerifying2] = useState(false);
    const [submitloader, setSubmitloader] = useState(false);
    const [show, setShow] = useState(false);
    const [otpResendTimer, setOtpResendTimer] = useState(0);
    const [timerActive, setTimerActive] = useState(false);
    console.log(timerActive,otpSent)
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token === "undefined") {
        } else if (token){
            navigate('/dashboard');
        }
      }, [navigate]);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibility2 = () => {
        setPasswordVisible2(!passwordVisible2);
    };

    useEffect(() => {
        let interval;
        if (timerActive && otpResendTimer > 0) {
          interval = setInterval(() => {
            setOtpResendTimer((prevTime) => prevTime - 1);
          }, 1000);
        } else if (otpResendTimer === 0) {
          setTimerActive(false);
          setEmailVerificationError("");
        }
        return () => clearInterval(interval);
      }, [timerActive, otpResendTimer]);
    
      const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        user_type: 'job_seeker',
    });
        const [errors, setErrors] = useState({});
        const [errorMessage, setErrorMessage] = useState('');
        
        
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    
        if (name === 'email') {
            setEmailVerificationError('');
            setOtpSent(false);
        }

        setFormData({ ...formData, [name]: value });
    };
    const validateForm = () => {
        const newErrors = {};

        // Check for empty fields
        Object.keys(formData).forEach((key) => {
            if (!formData[key]) {
                newErrors[key] = `${key.replace('_', ' ')} is required`;
            }else{
                if (key === "first_name" && formData[key].length < 3) {
                    newErrors[key] = "First Name must be at least 3 characters long";
                }else if (key === "first_name" && formData[key].length > 200) {
                    newErrors[key] = ['First Name must be less than 200 characters long.'];
                }
            }
        });

        // Additional validation for specific fields
        if (formData.password !== confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match!';
        }

        const password = formData.password;
        const passwordFormat = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\\|{};:/?.<>])[\w!@#$%^&*()\-_=+\\|{};:/?.<>]{8,}$/;
        if (!passwordFormat.test(password)) {
            newErrors.password = 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and any one special character.';
        }

        const email = formData.email;
        const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailFormat.test(email)) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if (!emailVerified) {
            newErrors.email = 'Please verify your email before proceeding.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
        // Send OTP API call
        const handleSendOtp = async () => {
            setIsVerifying(true);
            try {
                const response = await apiService.postRequest('auth/send-otp', { email: formData.email });
                if (response && response.success) {
                    setOtpSent(true);
                    setEmailVerificationError('');
                    toast("OTP sent to Your Email!");
                    setTimerActive(false);
                }else {
                    setEmailVerificationError(response.message);
                    if(response && response.remaining_seconds){
                        setTimerActive(true);
                        setOtpResendTimer(response.remaining_seconds);
                    }
                  } 
            } catch (error) {
                setEmailVerificationError('An unexpected error occurred while sending OTP.');
            }finally {
                setIsVerifying(false);
            }
        };

        // Verify OTP API call
        const handleVerifyOtp = async () => {
            setIsVerifying2(true);
            try {
                const response = await apiService.postRequest('auth/verify-otp', { email: formData.email, otp });
                if (response && response.success) {
                    setEmailVerified(true);
                    setOtpError('');
                    setTimerActive(false);
                    setOtpResendTimer(0);
                    setEmailVerificationError("");
                } else {
                    setOtpError(response.message);
                    setEmailVerified(false);
                }
            } catch (error) {
                setOtpError('An unexpected error occurred during OTP verification.');
            }finally {
                setIsVerifying2(false);
            }
        };
        const handleClose = async () => {
            try {
                await apiService.postRequest('/auth/logout');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                localStorage.removeItem('userid');
                navigate('/login');
            } catch (error) {
                console.error('Logout error:', error);
            }
            setShow(false);
        };
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            if (formData.password !== confirmPassword) {
                setErrorMessage('Passwords do not match!');
                return;
              }else {
                    setErrorMessage('Please fix the errors before proceeding.');
                }
            return;
        }
        
          setSubmitloader(true);
          try {
            const jsonParams = {
                "email": formData.email,
                "password": formData.password,
                "first_name": formData.first_name,
                "last_name": formData.last_name,
                "user_type": 'job_seeker',
              };
              const response = await apiService.postRequest('auth/register', jsonParams);

              // Check if the response indicates success
              if (response && response.success === true) {
                const { token } = response.data; 
                const { user_type } = response.data;
                const { user_id } = response.data;
                localStorage.setItem('token', token);
                localStorage.setItem('role', user_type);
                localStorage.setItem('userid', user_id);
                setErrors({});
                setErrorMessage('');
                setShow(true);
              } else {
                setErrorMessage('Registration failed. Please try again.');
                if (response.errors) {
                  setErrors(response.errors);
                }
              }
            } catch (error) {
                setErrorMessage('An unexpected error occurred.'); 
            }finally {
                setSubmitloader(false); // Stop loader
            }
        };
  return (
    <Grid size={{lg:12}} container justifyContent="center" className="registerdetailsmain">
        <Grid size={{lg:8,sm:10,xs:11}} container alignItems="flex-start" justifyContent="center" className="regisdetailwhite">
            <Grid size={{lg:12}} container justifyContent="center">
                <img onClick={() =>{navigate("/")}} src={require('../../assets/logo.svg').default} alt=''/>
                <h2>Create Account</h2>
                <form className='w-100' onSubmit={handleSubmit}>
                    <Grid size={{lg:12,xs:12}} container justifyContent="center" className="regiter_inputs">
                        <Grid size={{sm:8, xs:12}} container flexDirection="column">
                            <span>Email<small className='red_starimpo'>*</small></span>
                            <input type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                disabled={emailVerified}
                                />
                                {errors.email && <p style={{color:"Red"}}>{errors.email}</p>}
                        </Grid>
                        <Grid size={{sm:8, xs:12}} container alignItems="flex-end">
                            {(!otpSent && !emailVerificationError) && (
                                <button type="button" className='registersendbtn2 mb-2' onClick={handleSendOtp}>
                                    {isVerifying ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Get OTP'}
                                </button>
                            )}
                            {emailVerificationError && <p style={{ color: 'red', marginLeft: '10px',width : "100%" }}>{emailVerificationError}</p>}
                            {timerActive ?
                                <small className='resend_otpsmallTimer'>{timerActive ? `Resend OTP in ${formatTime(otpResendTimer)}` : ''}</small>:<>
                                {otpSent && (emailVerified === false) ? (
                                    <button className='registersendbtn1 mb-2 ms-2 px-0' type="button" onClick={handleSendOtp}>
                                        {isVerifying ? <img src={require('../../assets/pinkloader.gif')} alt=''/> : 'Resend OTP'}
                                    </button>
                                ) : (emailVerified === true) ? <small className='verified_small'><FontAwesomeIcon icon={faCircleCheck} /> Verified</small> : ""}
                            </>}
                        </Grid>
                        {(otpSent) && (emailVerified === false) ?  (
                            <>
                            <Grid size={{ sm: 8, xs: 12 }} container flexDirection="column">
                                <span>Enter OTP ( Sent to Email )<small className='red_starimpo'>*</small></span>
                                <input
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="------" maxLength={6} className='otp_input'
                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                />
                            </Grid>
                            <Grid size={{sm:8, xs:12}} container alignItems="flex-end">
                            <button type="button" className='registersendbtn2 mb-2' onClick={handleVerifyOtp}>{isVerifying2 ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Verify OTP'}</button>
                            {otpError && <p style={{ color: 'red',marginLeft:'10px' }}>{otpError}</p>}
                        </Grid>
                        </>
                        ):""}
                        
                        {emailVerified && <>
                            <Grid size={{sm:8, xs:12}} container flexDirection="column">
                            <span>First Name<small className='red_starimpo'>*</small></span>
                            <input
                                type="text"
                                name="first_name"
                                minLength="3"
                                maxLength="201"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                            />
                            {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                        </Grid>
                        <Grid size={{sm:8, xs:12}} container flexDirection="column">
                            <span>Last Name<small className='red_starimpo'>*</small></span>
                            <input
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                            />
                            {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                        </Grid>
                        <Grid size={{sm:8, xs:12}} container flexDirection="column" alignItems="flex-start">
                            <span>Password<small className='red_starimpo'>*</small></span>
                            <div className='d-flex input_witheye'>
                                <input
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    type={passwordVisible ? 'text' : 'password'}
                                    placeholder="Abcd123#"
                                    style={{ width: '100%', paddingRight: '40px' }}
                                />
                                <button type="button" onClick={togglePasswordVisibility}>
                                    {passwordVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                </button>
                            </div>
                            {errors.password && <p style={{color:"Red"}}>{errors.password}</p>}
                        </Grid>
                        <Grid size={{sm:8, xs:12}} container flexDirection="column">
                            <span>Confirm Password<small className='red_starimpo'>*</small></span>
                            <div className='d-flex input_witheye'>
                                <input
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    type={passwordVisible2 ? 'text' : 'password'}
                                    placeholder="Abcd123#"
                                    style={{ width: '100%', paddingRight: '40px' }}
                                />
                                <button type="button" onClick={togglePasswordVisibility2}>
                                    {passwordVisible2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                                </button>
                            </div>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        </Grid>
                        <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-5">
                            <button className='registersendbtn1' type="button" onClick={() =>{navigate("/")}}>Cancel</button>
                            <button className='registersendbtn2' type="submit" >{submitloader ? <img src={require('../../assets/whiteloader.gif')} alt=''/> : 'Save & Next'}</button>
                        </Grid>
                        </>}
                    </Grid>
                </form>
                <Modal
                    show={show} 
                    onHide={handleClose}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >
                    <Modal.Header closeButton style={{border:"none"}}>
                        
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex w-100 text-center flex-column align-items-center modal_register'>
                            <FontAwesomeIcon className='main_tickiconpopup' icon={faCircleCheck} />
                            <h3>Congratulation!</h3>
                            <small>You have successfully created your BenchTrack account.</small>
                            <button onClick={()=> navigate("/registration/personal-questions")}>Go to Next</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </Grid>
        </Grid>
    </Grid>
  )
}

export default RegisterIds

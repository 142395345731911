import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
function TaskDisccuss() {
    const taskId = localStorage.getItem('onBoardTaskID');
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const [loading, setLoading] = useState(true);
    const [commenteddata, setCommentedData] = useState([]);
    const [errors, setErrors] = useState({});
    const [comment, setComment] = useState('');
    const [replyComments, setReplyComments] = useState({});
    const [activeCommentId, setActiveCommentId] = useState(null);

    const fetchPreviewData = useCallback(async () => {
        try {
            const response = await apiService.getRequest(`task/${taskId}/discussions`);
            if (response && response.success === true) {
                setCommentedData(response.data);
            }
        } catch (error) {
            console.error('Error fetching group data:', error);
        } finally {
            setLoading(false);
        }
    }, [taskId]); // Dependency on slug

    // Call fetchPreviewData on mount and whenever slug changes
    useEffect(() => {
        fetchPreviewData();
    }, [fetchPreviewData,onboardslug]);


    const [submitloader,setSubmitloader] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault(); 
        const newErrors = {};
        if (comment.trim() === '') {
        newErrors.comment = "Please enter a comment.";
        }

        // If there are errors, update the errors state and do not submit
        if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
        }
        setSubmitloader(true);
        try{
            const jsonParams = {
                "task_id": taskId,
                "comment": comment,
            }
            const response = await apiService.postRequest(`task/add/discussions`,jsonParams);
            if(response && response.success === true){
                toast("Comment Submitted");
                setComment("");
                fetchPreviewData();
            }
            }catch (error) {
                console.error('Error fetching data:', error);
        }finally {
            setSubmitloader(false); // Stop loader
        }
    };

    const handleReplyChange = (e, commentId) => {
        const { value } = e.target; // Get the current input value

        // Update the state to reflect the current textarea input
        setReplyComments((prevReplies) => ({
            ...prevReplies,
            [commentId]: value, // Set the value for the specific comment
        }));
    };

    const handleReplyClick = useCallback((commentId) => {
        setActiveCommentId(activeCommentId === commentId ? null : commentId);
    }, [activeCommentId]);
    const handleSubmitreply = useCallback(async (e, parentId) => {
        e.preventDefault(); // Prevent default form submission
        const replyText = replyComments[parentId]; // Get the current reply text
        if (!replyText) {
            return; // Prevent submission if empty
        }

        try {
            const jsonParams = {
                task_id : taskId,
                comment: replyText,
                parent_id: parentId,
            };
            const response = await apiService.postRequest(`task/add/discussions`, jsonParams);
            if (response && response.success === true) {
                toast("Comment Submitted");
                setReplyComments((prev) => ({
                    ...prev,
                    [parentId]: ''
                }));
                setActiveCommentId(null);
                fetchPreviewData();
            }
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    }, [replyComments,taskId, fetchPreviewData]);
  return (
    <Grid size={{xs:12}} container>
    <form className='w-100 mt-3' onSubmit={handleSubmit}>
    <Grid size={{sm:12}} container className="mt-4">
        <label className='custom_comonlabel'>Work Notes</label>
        <textarea 
        style={{background:"#fff"}}
        className='comment_textarea' 
        placeholder="Work Notes...." 
        value={comment} 
        onChange={(e) => setComment(e.target.value)} 
        />
        <div className='w-100'>
            {errors.comment && <p style={{color:"red"}}>{errors.comment}</p>}
        </div>
        <button type="submit" className='submit_btn'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
    </Grid>
</form>
<Grid size={{sm:12}} container justifyContent="center" className="mt-4 previous_comments discurssionoverflow">
{loading ? 
<div className='d-flex justify-content-center w-100 mt-5 pt-5'>
    <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
</div> :<>
    {commenteddata && commenteddata.map((comment) => (
        <Grid size={{ sm: 12 }} key={comment.id} container alignItems="center" justifyContent="space-between" className="mb-4 reply_comment_btn dicussion_heightset">
        <small className='pink_small'>{comment.user}</small>
        <small className='datetime_small'>{comment.created_at}</small>
        <span>{comment.comment}</span>
        <button className='reply_btntext' onClick={() => handleReplyClick(comment.id)}>
            {activeCommentId === comment.id ? "Cancel" : "Reply"}
        </button>

        {activeCommentId === comment.id && (
            <div className='d-flex flex-column w-100 mb-2'>
                <textarea
                    value={replyComments[comment.id] || ''}
                    onChange={(e) => handleReplyChange(e, comment.id)}
                    placeholder="Write your reply..."
                />
                <button style={{opacity:!replyComments[comment.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[comment.id]?.trim()}  onClick={(e) => handleSubmitreply(e, comment.id)}>Send Reply</button>
            </div>
        )}
        {comment.replies && comment.replies.map((reply) => (
            <Grid size={{ sm: 12 }} key={reply.id} container alignItems="center" justifyContent="space-between" className="mb-4 ps-5 reply_comment_btn">
                <small className='pink_small'>{reply.user} <small>(@{reply.parentUser})</small></small>
                <small className='datetime_small'>{reply.created_at}</small>
                <span>{reply.comment}</span>
                <button className='reply_btntext' onClick={() => handleReplyClick(reply.id)}>
                    {activeCommentId === reply.id ? "Cancel" : "Reply"}
                </button>
        
                {activeCommentId === reply.id && (
                    <div className='d-flex flex-column w-100'>
                        <textarea
                            value={replyComments[reply.id] || ''}
                            onChange={(e) => handleReplyChange(e, reply.id)}
                            placeholder="Write your reply..."
                        />
                        <button style={{opacity:!replyComments[reply.id]?.trim() ? "0.8":"1"}} disabled={!replyComments[reply.id]?.trim()} onClick={(e) => handleSubmitreply(e, reply.id)}>Send Reply</button>
                    </div>
                )}
            </Grid>
        ))}
    </Grid>
    ))}
</>}
</Grid>
    </Grid>
  )
}

export default TaskDisccuss

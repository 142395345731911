import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiService } from '../../../services/apiService';
import TaskDisccuss from './I94Validation/TaskDisccuss';
import Assignments from './I94Validation/Assignments';
import { Tab, Tabs } from 'react-bootstrap';
function TechReportView() {
    const { requestId } = useParams();
    const roles = localStorage.getItem('role');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [issuedetails, setIssuedetails] = useState([]);
    const [activeTab, setActiveTab] = useState("overview");
    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await apiService.getRequest(`/request-details/${requestId}`);
                if (response.success === true){
                    setIssuedetails(response.request.details);
                    
                }else{
                    toast.error("Retry")
                }
            } catch (error) {
                console.error("Error fetching user data", error);
            }finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [requestId]);
  
    const onApprove = async () => {
        try {
          const response = await apiService.postRequest(`/requests/${requestId}/approve`);
          if (response && response.success === true) {
            toast("Request Completed successfully!");
            navigate("/task/all-task");
          }
        } catch (error) {
          console.error("Error approving the request:", error);
        }
    };
  return (
    <div className='container-fluid px-0'>
        <div className='content_container' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {(roles === 'admin' ? <b onClick={()=>navigate(-1)}>Task</b> : <b onClick={()=>navigate("/task/my-request")}>My Request</b>)}</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker" style={{background:"#fff"}}>
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" alignItems="center" className="jobseekerhead border_botomer">
                        <h3>View Technical Issues</h3>
                        <button className='registersendbtn1 my-0' onClick={()=>navigate(-1)}>Back</button>
                    </Grid>
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" className="jobseekers_profilealls my-4">
                                        {issuedetails.first_name && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>First Name</span>
                                            <p>{issuedetails.first_name}</p>
                                        </Grid></>}
                                        {issuedetails.last_name && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Last Name</span>
                                            <p>{issuedetails.last_name}</p>
                                        </Grid></>}
                                        {issuedetails.email_id && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Email</span>
                                            <p>{issuedetails.email_id}</p>
                                        </Grid></>}
                                        {issuedetails.phone_number && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Phone Number</span>
                                            <p>{issuedetails.phone_number}</p>
                                        </Grid></>}
                                        {issuedetails.issue && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Issue</span>
                                            <p>{issuedetails.issue}</p>
                                        </Grid></>}
                                        {issuedetails.issue_date && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Issue Date</span>
                                            <p>{issuedetails.issue_date}</p>
                                        </Grid></>}
                                        {issuedetails.short_description && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Short Description</span>
                                            <p>{issuedetails.short_description}</p>
                                        </Grid></>}
                                        {issuedetails.issue_date && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Description</span>
                                            <p>{issuedetails.description}</p>
                                        </Grid></>}
                                        {issuedetails.attachments && <>
                                        <Grid size={{sm:5.7,xs:12}} container flexDirection="column" className="seekprof_borders">
                                            <span>Attachments</span>
                                            {issuedetails.attachments && issuedetails.attachments.map((data,index)=>(
                                                <a key={index} href={data} target="_blank" rel="noopener noreferrer">View</a>
                                            ))}
                                        </Grid></>}
                                        <TaskDisccuss/>
                                        <Grid size={{sm:12, xs:12}} container justifyContent="flex-end" className="mt-2 gap-4">
                                            <button className='registersendbtn2' onClick={onApprove}>Closed</button>
                                        </Grid>
                                    </Grid>
                                </Tab>
                                {roles !== "job_seeker" &&
                                <Tab eventKey="assignment" title="Assignments">
                                    {activeTab === "assignment" && <Assignments />}
                                </Tab>}
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
        </div>
    </div>
  )
}

export default TechReportView

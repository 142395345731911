import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import Select from 'react-select';
import { apiService } from '../../../../services/apiService';
import { toast } from 'react-toastify';
function Assignments() {
    const taskId = localStorage.getItem('onBoardTaskID');
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const onbUserId = localStorage.getItem('onboardUserId');
    const [showsave, setShowsave] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [groupId, setGroupId] = useState([]);
    const [userId, setUserId] = useState([]);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const Role_user = localStorage.getItem('role');
    const [showgroupselect,setShowgroupselect] = useState(false);
    const [formData, setFormData] = useState({
        task_id : taskId,
        assignment_group_id: '',
        assigned_user_id: '',
        reason:'',
    });
    useEffect(()=> {
        if(Role_user === "admin" && showsave === true ){
            setShowgroupselect(true);
        }else if(Role_user === "admin" && showsave === false){
            setShowgroupselect(false);
        }
    },[Role_user,showsave])
    useEffect(() => {
        const fetchCurrentData = async () => {
          try {
            const response = await apiService.getRequest(`/current-onboarding/${onbUserId}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug)){
                    setShowsave(true);
                }else{
                    setShowsave(false)
                }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          }
        };
    
        fetchCurrentData();
    }, [ onbUserId,onboardslug ]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await apiService.getRequest(`task/details/${taskId}`);
                if (response.success && response.success === true) {
                    const refetchedData = response.data;
                    setFormData({
                        task_id: taskId,
                        assignment_group_id: refetchedData.assignment_group_id,
                        assigned_user_id: refetchedData.assigned_user_id,
                    });
                    if (!["in-progress", "pending"].includes(refetchedData.status_string)) {
                        setShowsave(false);
                    } else {
                        setShowsave(true);
                    }
                    if (refetchedData.assignment_group_id) {
                        const selectedGroupId = groupId.find(
                            (groupId) => groupId.id === refetchedData.assignment_group_id
                        );
                        if (selectedGroupId) {
                            setSelectedGroupId({
                                label: selectedGroupId.display_name,
                                value: selectedGroupId.id,
                            });
                        }

                        if (refetchedData.assigned_user_id) {
                            const responseAssignRoleId = await apiService.getRequest(
                                `admin/roles-with-users?role_id=${refetchedData.assignment_group_id}`
                            );
                            if (responseAssignRoleId.success) {
                                setUserId(responseAssignRoleId.data || []);
                                setTimeout(() => {
                                    const selectedUserId = responseAssignRoleId.data.find(
                                        (selectedUserId) =>
                                            selectedUserId.id === refetchedData.assigned_user_id
                                    );
                                    if (selectedUserId) {
                                        setSelectedUserId({
                                            label: selectedUserId.name,
                                            value: selectedUserId.id,
                                        });
                                    }
                                }, 0);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching onboarding data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [taskId, groupId]);
    

    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('admin/roles-with-users');
            if (response.success) {
                setGroupId(response.data);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (formData.assignment_group_id) {
            const fetchData = async () => {
                const response = await apiService.getRequest(`admin/roles-with-users?role_id=${formData.assignment_group_id}`);
                if (response.success) {
                    setUserId(response.data || []);
                    setSelectedUserId(null);
                }
            };
            fetchData();
        }
    }, [formData.assignment_group_id]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Clear the error for the specific field being modified
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({ ...formData, [name]: value });
    };
    const handleGroupChange = (selectedOption) => {
        setSelectedGroupId(selectedOption);
        handleInputChange({
            target: {
                name: 'assignment_group_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
        // Clear the city field when state is changed
        setSelectedUserId(null);
    };

    const handleUserIdChange = (selectedOption) => {
        setSelectedUserId(selectedOption); // Update city
        handleInputChange({
            target: {
                name: 'assigned_user_id',
                value: selectedOption ? selectedOption.value : ''
            }
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.assignment_group_id) newErrors.assignment_group_id = "Assigned Group is required.";
        if (!formData.assigned_user_id) newErrors.assigned_user_id = "Assigned to is required.";
        if (!formData.reason) newErrors.reason = "Reason is required.";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };
    const [submitloader,setSubmitloader] = useState(false)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            setErrorMessage('Please fix the errors before proceeding.');
            return;
        }
        setSubmitloader(true);
        try {
    
            // Make the API request
            const response = await apiService.postRequest(`task/add/assignments`, formData);
    
            // Check response for success
            if (response.success) {
                setErrors({});
                setErrorMessage('');
                toast("Assigned Successfully");
            } 
        } catch (error) {
            setErrorMessage('Failed. Please try again.');
        } finally {
            setSubmitloader(false); // Stop loader
        }
      };
  return (
    <>
        {loading ? 
        <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
        </div> :<>
        <form className='w-100' 
            onSubmit={handleSubmit}
            >
                <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="darfts_inputs for_textarespec mt-4">
                    <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                        <span>Assignment Group</span>
                        <Select
                            isDisabled={!showgroupselect}
                            className="basic-single select_withsearch"
                            classNamePrefix="select"
                            value={selectedGroupId}
                            defaultValue={null}
                            name="assignment_group_id"
                            options={groupId.map((state) => ({
                                label: state.display_name,
                                value: state.id,
                            }))}
                            onChange={handleGroupChange}
                        />
                        {errors.assignment_group_id && <p style={{color:"Red"}}>{errors.assignment_group_id}</p>}
                    </Grid>
                    <Grid size={{sm:5.8, xs:12}} container flexDirection="column">
                        <span>Assigned To</span>
                        <Select
                            isDisabled={!showsave}
                            className="basic-single select_withsearch"
                            classNamePrefix="select"
                            value={selectedUserId}
                            defaultValue={null}
                            name="assigned_user_id"
                            options={userId.map((users) => ({
                                label: users.name,
                                value: users.id,
                            }))}
                            onChange={handleUserIdChange}
                        />
                        {errors.assigned_user_id && <p style={{color:"Red"}}>{errors.assigned_user_id}</p>}
                    </Grid>
                    <Grid size={{sm:12, xs:12}} container flexDirection="column">
                        <span>Reason for re-assignment</span>
                        <textarea disabled={!showsave} placeholder='Write your reason' value={formData.reason} name='reason' onChange={handleInputChange} />
                        {errors.reason && <p style={{color:"Red"}}>{errors.reason}</p>}
                    </Grid>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    {showsave && 
                    <Grid size={{sm:12, xs:12}} container justifyContent="space-between" className="mt-2">
                        <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                    </Grid>
                    }
                </Grid>
        </form>
        </>}
                
    </>
  )
}

export default Assignments

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./allheader.css";
import { faBars, faCloudArrowUp, faFileCircleCheck, faFileCirclePlus, faNoteSticky, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, Dropdown } from 'react-bootstrap';
import { apiService } from '../../services/apiService';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import Joyride from "react-joyride";
const profileImage = require('../../assets/defaultprofile.svg').default;
function Allheader() {
    // const [run, setRun] = useState(false);
    // setRun(true);
  const steps = [
    {
      target: ".guid_show1",
      content: "The Profile Management System is comprehensive way to manage their personal, immigration, and emergency details. ensures that all essential information is organized, up-to-date, and easily accessible.",
      placement: "right",  
    },
    {
      target: ".guid_show2",
      content: "You can click here to see dashboard!",
      placement: "bottom",
    },
    {
      target: ".guid_show3",
      content: "The Catalog is Correct My Profile, Marketing Hold Request, and Edit Registration etc.. these features empower users to manage their preferences and information seamlessly while improving operational efficiency..",
      placement: "left",
    },
  ];
    const navigate = useNavigate();
    const isAuthenticated = !!localStorage.getItem('token');
    useEffect(() => {
        if (!isAuthenticated) {
          navigate('/login');
        }
    }, [navigate,isAuthenticated]);
    const location = useLocation();
    const currentPath = location.pathname;
    const [isActive, setIsActive] = useState(false);
    const [userprofile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notifications, setNotifications] = useState([]);

    const userId = localStorage.getItem('userid');
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.postRequest(`profile`);
            if (response.success) {
                if (response && response.pending_signup === true) {
                    switch (response.pending_step) {
                      case 'personal_questions':
                        navigate('/registration/personal-questions');
                        break;
                      case 'immigration':
                        navigate('/registration/immigration-questions');
                        break;
                      case 'emergency':
                        navigate('/registration/emergency-contact');
                        break;
                      default:
                        navigate('/registration/referral-information');
                    }
                }
                setUserProfile(response.user);
                localStorage.setItem('usernames', response.user.first_name);
                localStorage.setItem('notifications', response.has_new_notifications);
                localStorage.setItem('permissions', JSON.stringify(response.enabled_permissions));
            }else{
                if(response && response.authenticate === false){
                    await apiService.postRequest('/auth/logout');
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    localStorage.removeItem('userid');
                    localStorage.removeItem('usernames');
                    localStorage.removeItem('permissions');
                    localStorage.removeItem('hasReloaded');
                    localStorage.removeItem('authenti');
                    localStorage.removeItem('notifications');
                    localStorage.removeItem('onBoardTaskID');
                    localStorage.removeItem('onboardingSlugs');
                    localStorage.removeItem('onboardUserId');
                    navigate('/login');
                }
            }
        };
        fetchData();
    }, [userId,navigate]);
    const handleLogout = async () => {
        try {
            await apiService.postRequest('/auth/logout');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('userid');
            localStorage.removeItem('usernames');
            localStorage.removeItem('permissions');
            localStorage.removeItem('hasReloaded');
            localStorage.removeItem('authenti');
            localStorage.removeItem('notifications');
            localStorage.removeItem('onBoardTaskID');
            localStorage.removeItem('onboardingSlugs');
            localStorage.removeItem('onboardUserId');
            navigate('/login');
        } catch (error) {
            console.error('Logout error:', error);
        }
    };
    
    const handleToggle = () => {
        setIsActive(!isActive);
    };

    const roles = localStorage.getItem('role');
    const notifyer = localStorage.getItem('notifications') === "true";
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
    


    const handleRecentnotify = async () => {
        try {
            const response = await apiService.getRequest(`notifications?recent_notify=true`);
                if(response && response.success === true){
                    setNotifications(response.data);
                }
        } catch (error) {
            console.error('Error Notifiction Data:', error);
        }finally{
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await apiService.deleteRequest(`/notifications/${id}`);
                if (response && response.success === true) {
                    toast("Notification Cleared")
                    handleRecentnotify();
                }
        } catch (error) {
            console.error("Error deleting notification:", error);
        }
    };

    const [showDropdown, setShowDropdown] = useState(false);

  const handleViewMore = () => {
    setShowDropdown(false); // Close the dropdown
    navigate("/notification");
  };
  return (
    <div className='container-fluid px-0'>
        <Joyride
        steps={steps}
        // run={run}
        run={false}
        continuous
        showSkipButton
        styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#C32A7C",
              textColor: "#000",
              zIndex: 10000,
            },
          }}
      />
        <Grid size={{lg:12,xs:12}} container className="flow_headermain" justifyContent="space-between">
            <button className='menubtn_mobile' onClick={handleToggle}><FontAwesomeIcon icon={faBars} /></button>
            <img onClick={() =>{navigate("/")}} className='logosign' src={require('../../assets/logo.svg').default} alt=''/>
            <Grid container alignItems="center" className="setheaderprofile">
                {currentPath === "/notification" ? 
                    "" :
                    <Dropdown show={showDropdown} onToggle={(isOpen) => setShowDropdown(isOpen)}>
                    <Dropdown.Toggle className={notifyer ? 'notification_btn notiafterset' : 'notification_btn'}>
                    <FontAwesomeIcon icon={faBell} 
                    onClick={handleRecentnotify}
                    />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='px-md-2 px-2 dropdown_notify_widthset'>
                        <Grid size={{xs:12}} container justifyContent="flex-end">
                            <button className='notify_viewmore' onClick={handleViewMore}>
                                View More
                            </button>
                        </Grid>
                        {loading ? 
                        <div className='d-flex justify-content-center w-100 mt-5 pt-5'>
                            <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        {notifications && notifications.map(notification => (
                        <Grid size={{xs:12}}
                         key={notification.id} 
                         container justifyContent="center" alignItems="center" className="noti_contentgrid mt-2">
                            <Grid size={{xs:11}} container>
                                <Grid size={{sm:2,xs:2}} container justifyContent="center">
                                    <img src={notification.profile || profileImage} alt=''/>
                                </Grid>
                                <Grid size={{sm:10,xs:10}} container flexDirection='column'>
                                    <span><b>{notification.title}</b>{notification.message}</span>
                                    <small>{notification.created_at}</small>
                                </Grid>
                            </Grid>
                            <Grid size={{xs:1}} container justifyContent="center">
                                <button
                                onClick={() => handleDelete(notification.id)}
                                ><FontAwesomeIcon icon={faXmark} /></button>
                            </Grid>
                        </Grid>
                        ))}
                        </>}
                    </Dropdown.Menu>
                </Dropdown>
                }
                
                <img className='fake_profile guid_show1' src={(userprofile && userprofile.profile_picture) || profileImage} alt=""/>
                <Dropdown>
                    <Dropdown.Toggle className='drop_btnlogout ps-0 d-flex align-items-center' >
                    <font className="d-md-block d-none">{userprofile?.first_name || ''}</font>
                    <font className="d-md-none d-block">{userprofile?.first_name?.length > 5 ? `${userprofile.first_name.slice(0, 5)}...` : userprofile?.first_name || ''}</font>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='px-2'>
                        <button className='suspent_btn' onClick={handleLogout}>Logout</button>
                    </Dropdown.Menu>
                </Dropdown>
            </Grid>
        </Grid>
        <div className='d-flex w-100'>
            <div className={isActive ? 'activeside sidebarfixed' : 'sidebarfixed'}>
                <div className={currentPath === "/dashboard" ? "sidelinks active_side" : "sidelinks"} >
                    <div className='inner_layer guid_show2' onClick={() =>{navigate("/dashboard")}}>
                        <img src={require('../../assets/sidebaricons/icon1.svg').default} alt=''/>
                        <span>Dashboard</span>
                    </div>
                </div>
                {(roles === 'admin' || roles === "sub_admin") && <>
 
                    {permissionstest && permissionstest.manage_users === true ?
                        <div className={currentPath.startsWith("/user") ? "sidelinks active_side" : "sidelinks"} >
                            <div className='inner_layer' 
                                onClick={() => {
                                    if ((permissionstest.manage_jobseeker_reader === true || permissionstest.manage_jobseeker_writer === true) && ( permissionstest.manage_subadmin_reader === true || permissionstest.manage_subadmin_writer === true ) && permissionstest.manage_group_reader === true ) {
                                        navigate("/user/jobseekers");
                                    }if ((permissionstest.manage_jobseeker_reader === true || permissionstest.manage_jobseeker_writer === true) && ( permissionstest.manage_subadmin_reader !== true || permissionstest.manage_subadmin_writer !== true ) && permissionstest.manage_group_reader === true ) {
                                        navigate("/user/jobseekers");
                                    }if ((permissionstest.manage_jobseeker_reader === true || permissionstest.manage_jobseeker_writer === true) && ( permissionstest.manage_subadmin_reader === true || permissionstest.manage_subadmin_writer === true ) && permissionstest.manage_group_reader !== true ) {
                                        navigate("/user/jobseekers");
                                    }if ((permissionstest.manage_jobseeker_reader === true || permissionstest.manage_jobseeker_writer === true) && ( permissionstest.manage_subadmin_reader !== true || permissionstest.manage_subadmin_writer !== true ) && permissionstest.manage_group_reader !== true ) {
                                        navigate("/user/jobseekers");
                                    }else if((permissionstest.manage_jobseeker_reader !== true || permissionstest.manage_jobseeker_writer !== true) && ( permissionstest.manage_subadmin_reader === true || permissionstest.manage_subadmin_writer === true ) && permissionstest.manage_group_reader === true){
                                        navigate("/user/member");
                                    }else if((permissionstest.manage_jobseeker_reader !== true || permissionstest.manage_jobseeker_writer !== true) && ( permissionstest.manage_subadmin_reader === true || permissionstest.manage_subadmin_writer === true ) && permissionstest.manage_group_reader !== true){
                                        navigate("/user/member");
                                    }else if((permissionstest.manage_jobseeker_reader !== true || permissionstest.manage_jobseeker_writer !== true) && ( permissionstest.manage_subadmin_reader !== true || permissionstest.manage_subadmin_writer !== true ) && permissionstest.manage_group_reader === true){
                                        navigate("/user/group");
                                    }
                                }}>
                                <img src={require('../../assets/sidebaricons/icon5.svg').default} alt=''/>
                                <span>User</span>
                            </div>
                        </div>
                    :""}
                    {permissionstest && permissionstest.manage_knowledge_article === true ?
                        <div className={currentPath.startsWith("/knowledge") ? "sidelinks active_side" : "sidelinks"}>
                            <div className='inner_layer'>
                                <img src={require('../../assets/sidebaricons/icon3.svg').default} alt=''/>
                                <span className='sidenewdrshows'>Manage Knowledge
                                    <div className='list_showers'>
                                        <div className='d-flex flex-column'>
                                            <small className={currentPath === "/knowledge" ? "activesmallersb" : ""} onClick={() =>{navigate("/knowledge")}}>List of Articles</small>
                                            <small className='sidebaraccords'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Manage the Knowledge</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='d-flex flex-column'>
                                                            <small className={ currentPath === "/knowledge/draft" || currentPath === "/knowledge/add-draft" ? "active_accordsmall" : ""} onClick={() =>{navigate("/knowledge/draft")}}><FontAwesomeIcon className='icons' icon={faFileCirclePlus} />Draft</small>
                                                            <small className={ currentPath === "/knowledge/under-approval" ? "active_accordsmall" : ""} onClick={() =>{navigate("/knowledge/under-approval")}}><FontAwesomeIcon className='icons' icon={faNoteSticky} />Under Approval</small>
                                                            <small className={ currentPath.startsWith("/knowledge/published") ? "active_accordsmall" : ""} onClick={() =>{navigate("/knowledge/published")}}><FontAwesomeIcon className='icons' icon={faFileCircleCheck} />Published</small>
                                                            <small className={ currentPath.startsWith("/knowledge/retired") ? "active_accordsmall" : ""} onClick={() =>{navigate("/knowledge/retired")}}><FontAwesomeIcon className='icons' icon={faCloudArrowUp} />Retired</small>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            </small>
                                            {roles === 'admin' && <>
                                                <small className={currentPath === "/knowledge/root" ? "activesmallersb" : ""} onClick={() =>{navigate("/knowledge/root")}}>Knowledge Base</small>
                                                <small className={currentPath === "/knowledge/category" ? "activesmallersb" : ""} onClick={() =>{navigate("/knowledge/category")}}>Category</small>
                                                <small className={currentPath === "/knowledge/sub-category" ? "activesmallersb" : ""} onClick={() =>{navigate("/knowledge/sub-category")}}>Sub Category</small>
                                            </>}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        :
                        <div className={currentPath.startsWith("/knowledge") ? "sidelinks active_side" : "sidelinks"} >
                            <div className='inner_layer' onClick={() =>{navigate("/knowledge")}}>
                                <img src={require('../../assets/sidebaricons/icon3.svg').default} alt=''/>
                                <span>Knowledge Article</span>
                            </div>
                        </div>
                    }
                    <div className={currentPath.startsWith("/task") ? "sidelinks active_side" : "sidelinks"}>
                        <div className='inner_layer'
                            onClick={() => {
                                    if (roles && roles !== 'job_seeker' ) {
                                        navigate("/task/my-task");
                                    }else{
                                        navigate("/task/my-request");
                                    }
                                }}>
                            <img src={require('../../assets/sidebaricons/icon6.svg').default} alt=''/>
                            <span>Task</span>
                        </div>
                    </div>
                </>}
                {(roles === 'job_seeker') && <>
                    <div className={currentPath.startsWith("/knowledge") ? "sidelinks active_side" : "sidelinks"} >
                        <div className='inner_layer' onClick={() =>{navigate("/knowledge")}}>
                            <img src={require('../../assets/sidebaricons/icon3.svg').default} alt=''/>
                            <span>Knowledge Article</span>
                        </div>
                    </div>
                    <div className={currentPath.startsWith("/catalogs") ? "sidelinks active_side" : "sidelinks"}>
                        <div className='inner_layer guid_show3' onClick={() =>{navigate("/catalogs")}}>
                            <img src={require('../../assets/sidebaricons/icon2.svg').default} alt=''/>
                            <span>Catalog</span>
                        </div>
                    </div>
                    <div className={currentPath.startsWith("/task") ? "sidelinks active_side" : "sidelinks"}>
                        <div className='inner_layer' onClick={() =>{navigate("/task/my-request")}}>
                            <img src={require('../../assets/sidebaricons/icon6.svg').default} alt=''/>
                            <span>Task</span>
                        </div>
                    </div>
                </>}
                {/* <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon2.svg').default} alt=''/>
                        <span>Catalog</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon4.svg').default} alt=''/>
                        <span>My Requests</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon5.svg').default} alt=''/>
                        <span>Jobseeker</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon7.svg').default} alt=''/>
                        <span>My Approvals</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon8.svg').default} alt=''/>
                        <span>News Letter</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon9.svg').default} alt=''/>
                        <span>My Referrals</span>
                    </div>
                </div>
                <div className="sidelinks">
                    <div className='inner_layer'>
                        <img src={require('../../assets/sidebaricons/icon10.svg').default} alt=''/>
                        <span>Placement Pricing</span>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Allheader

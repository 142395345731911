import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import Assignments from '../I94Validation/Assignments';
import TaskDisccuss from '../I94Validation/TaskDisccuss';
import TaskKnowledge from '../I94Validation/TaskKnowledge';
import TaskHistory from '../I94Validation/TaskHistory';
function MemberConfirmation() {
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const { userid } = useParams();
    const navigate = useNavigate();
    const taskData = localStorage.getItem('onBoardTaskID');
    const [showform, setShowform] = useState(true);
    const [showedit, setShowedit] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("");
    const [jobseekerName, setJobseekername] = useState("");
    const [jobseekerid, setJobseekerid] = useState("");
    const [caseids, setcaseid] = useState("");
    const [taskids, settaskid] = useState("");
    const [activeTab, setActiveTab] = useState("overview");
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
            if (response.success && response.success === true) {
                localStorage.setItem('onBoardTaskID', response.data.task.id);
                setJobseekername(response.data.jobseeker.name);
                setJobseekerid(response.data.jobseeker.id);
                setcaseid(response.data.task.case_id);
                settaskid(response.data.task.task_format_id);
                setStatus(response.data.status_string);
                const refetchedData = response.data.form_data;
                setFileNames({
                    Q00040: refetchedData.Q00040 || "",
                })
                setFormData({
                    Q00010: refetchedData.Q00010,
                    Q00020: refetchedData.Q00020,
                    Q00030: refetchedData.Q00030,
                    Q00040: refetchedData.Q00040,
                });
                if ((
                    (response && response.data.form_data && Object.keys(response.data.form_data).length === 0) ||
                    (response && response.data.form_data && (!response.data.form_data.Q00010 || Object.keys(response.data.form_data.Q00010).length === 0)))
                    && (response && response.data.status_string === "in-progress")
                ){
                setShowform(true);
              }else{
                if(response && response.data.form_data && response.data.form_data.Q00010 === "I don't know the Referee"){
                    setOpendata(true);
                }else{
                    setOpendata(false);
                }
                setShowform(false);
              }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
    }, [onboardslug, userid]);

    useEffect(() => {
        const fetchCurrentData = async () => {
          try {
            const response = await apiService.getRequest(`/current-onboarding/${userid}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug) && response.status && response.status["member-confirmation"] === "in-progress"){
                    setShowedit(true);
                }else{
                    setShowedit(false);
                }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchCurrentData();
    }, [ userid,onboardslug]);
    const [fileNames, setFileNames] = useState({
        Q00040: "",
    }); 
    const [formData, setFormData] = useState({
        Q00010: "",
        Q00020: "",
        Q00030: "",
        Q00040: null,
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [opendata, setOpendata] = useState(false);
       // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
        if (name === 'Q00010') {
            if (value === "I don't know the Referee") {
                setOpendata(true);
            } else {
                setOpendata(false);
                setFormData(prevData => ({
                    ...prevData,
                    Q00020:"",
                    Q00030: "",
                }));
            }
        }
    };
    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files.length > 0) {
            const file = files[0]; // Get the selected file
    
            // Update the formData state with the new file
            setFormData((prevData) => ({
                ...prevData,
                [name]: file, // Store the file object for submission
            }));
            
            // Update the fileNames state to show the file name
            setFileNames((prevNames) => ({
                ...prevNames,
                [name]: file.name, // Store the file name for display
            }));
            setErrors((prevErrors) => {
                const updatedErrors = { ...prevErrors };
                delete updatedErrors[name]; // Remove the error for the file field
                return updatedErrors;
            });
        }
      };
  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.Q00010) newErrors.Q00010 = "How you know jobseeker is required.";
    if (!opendata) {
        if (!formData.Q00020) newErrors.Q00020 = "Please select Yes or No.";
        if (!formData.Q00030) newErrors.Q00030 = "Please select Yes or No.";
        if (!formData.Q00040) newErrors.Q00040 = "Call recording with jobseeker is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  // Handle form submission
  const [submitloader,setSubmitloader] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setErrorMessage('Please fix the errors before proceeding.');
        return;
    }
    setSubmitloader(true);
    try {
        const formDataToSend = new FormData();

        Object.keys(formData).forEach((key) => {
            const value = formData[key];

            // Only skip appending if the field is a string and is one of the file fields
            if (value !== null && !(typeof value === 'string' && ['Q00040'].includes(key))) {
                formDataToSend.append(key, value);
            }
        });
        const response = await apiService.postRequest(`onboarding/${onboardslug}/${userid}/submit`, formDataToSend);

        // Check response for success
        if (response.success) {
            setErrors({});
            setErrorMessage('');
            setEditMode(false);
            setShowform(false);
            if(response.success && response.status === "rejected"){
                window.location.reload();
            }
        } else {
            setErrorMessage(response.message);
            if (response.errors) {
              setErrors(response.errors);
            }
        }
    } catch (error) {
        setErrorMessage('Registration failed. Please try again.');
    } finally {
        setSubmitloader(false); // Stop loader
    }
  };
  
  const [selectedStatus, setSelectedStatus] = useState("");
  const [show, setShow] = useState(false);

  const handleslectChange = () => {
    setSelectedStatus("completed");
    setShow(true);
  };

  const handleClose = () => setShow(false);
  
  const [statuserror,setStatuserror] = useState("");
    const [submitloader2,setSubmitloader2] = useState(false);
      const confirmStatusChange = async () => {
      setShow(false); // Close the modal
      setStatus(selectedStatus); // Update the status locally
      setSubmitloader2(true);
      try {
        const response = await apiService.postRequest(
          `/task/update/status/${taskData}`,
          {
            status: selectedStatus,
          }
        );
        if (response && response.success === true) {
          toast("Status updated successfully!");
          window.location.reload();
          setStatuserror("");
        }else{
          setStatuserror(response.message);
          setStatus("in-progress");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status. Please try again.");
      }finally {
          setSubmitloader2(false); // Stop loader
      }
    };
  
  return (
    <div className='container-fluid px-0'>
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>  
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>Referrel Confirmation <small className='small_headingstatuses'>{status&&status === "completed" ? "Completed":status === "rejected"? "Rejected":"In Progress"}</small></h3>
                        <a className='seekerondetail' href={`/user/jobseeker-profile/${jobseekerid}`} target="_blank" rel="noopener noreferrer">{jobseekerName}</a>
                    </Grid>
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                    {(showform && showform === true) || (editMode && editMode === true) ? <>
                                    <form className='w-100 mt-3' 
                                        onSubmit={handleSubmit}
                                        >
                                        
                                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>How do you know the Jobseeker ?<small>*</small></span>
                                                <select 
                                                    name="Q00010" 
                                                    value={formData.Q00010}
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select</option>
                                                    <option value="2nd degree friend (Friend of a Friend)">2nd degree friend (Friend of a Friend)</option>
                                                    <option value="1st degree friend (you know them pretty well without middle men)">1st degree friend (you know them pretty well without middle men)</option>
                                                    <option value="Immidiate relative (Mother/Father/Sibling/Spouse)">Immidiate relative (Mother/Father/Sibling/Spouse)</option>
                                                    <option value="Non-Immidiate relative">Non-Immidiate relative</option>
                                                    <option value="I don't know the Referee">I don't know the Referee</option>
                                                </select>
                                                {errors.Q00010 && <p style={{color:"Red"}}>{errors.Q00010}</p>}
                                            </Grid>
                                            {!opendata && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Upload call recording with jobseeker<small>*</small></span>
                                                <div className="fileUploadInput">
                                                    <input
                                                        type="file"
                                                        name="Q00040"
                                                        id="Q00040"
                                                        onChange={handleFileChange}
                                                        accept=".mp3,audio/mpeg"
                                                        style={{ display: 'none' }} // Hide the actual file input
                                                    />
                                                    <label htmlFor="Q00040" style={{ cursor: 'pointer' }}>Upload</label>
                                                    <input
                                                        type="text"
                                                        value={fileNames.Q00040 || ''}
                                                        readOnly
                                                        placeholder="No file chosen"
                                                    />
                                                </div>
                                                {errors.Q00040 && <p style={{color:"Red"}}>{errors.Q00040}</p>}
                                            </Grid>
                                            <Grid size={{sm:12}} container className="mt-3" flexDirection="column">
                                                <span>Do you agree that your relationship with Tekpossible may be affected if a dispute arises between the Jobseeker and Tekpossible?<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00020"
                                                        value="Yes"
                                                        checked={formData.Q00020  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00020"
                                                        value="No"
                                                        checked={formData.Q00020  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00020 && <p style={{color:"Red"}}>{errors.Q00020}</p>}
                                            </Grid>
                                            <Grid size={{sm:12}} container className="mt-3" flexDirection="column">
                                                <span>Do you understand that Tekpossible may take legal action against the Jobseeker for any disputes arising from the Pay After Placements agreement or Employment Agreement.<small>*</small></span>
                                                <div className='d-flex align-items-center main_radioregister'>
                                                    <input
                                                        type="radio" 
                                                        name="Q00030"
                                                        value="Yes"
                                                        checked={formData.Q00030  === "Yes"} 
                                                        onChange={handleInputChange} 
                                                    />Yes
                                                    <input
                                                        type="radio" 
                                                        name="Q00030"
                                                        value="No"
                                                        checked={formData.Q00030  === "No"} 
                                                        onChange={handleInputChange} 
                                                    />No
                                                </div>
                                                {errors.Q00030 && <p style={{color:"Red"}}>{errors.Q00030}</p>}
                                            </Grid> 
                                            </>}                          
                                            {errorMessage && <p style={{ color: 'red',width:'100%' }}>{errorMessage}</p>}                         
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                                                {editMode && 
                                                    <button className='registersendbtn1' onClick={()=> setEditMode(false)}>cancel</button>
                                                }
                                            </Grid>
                                        </Grid>
                                    </form>
                                    </> :
                                    <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                                        <Grid size={{xs:12}} container className="my-3" flexDirection="column">
                                            <span>How do you know the Jobseeker ?<small>*</small></span>
                                            <p>{formData.Q00010}</p>
                                        </Grid>
                                        {!opendata && <>
                                        <Grid size={{xs:12}} container flexDirection="column">
                                            <span>Upload call recording with jobseeker<small>*</small></span>
                                            <a href={`${fileNames.Q00040}`} target="_blank" rel="noopener noreferrer"><b>View</b></a>
                                        </Grid>
                                        <Grid size={{xs:12}} container flexDirection="column">
                                            <span>Do you agree that your relationship with Tekpossible may be affected if a dispute arises between the Jobseeker and Tekpossible?<small>*</small></span>
                                            <p>{formData.Q00020}</p>
                                        </Grid>
                                        <Grid size={{xs:12}} container flexDirection="column">
                                            <span>Do you understand that Tekpossible may take legal action against the Jobseeker for any disputes arising from the Pay After Placements agreement or Employment Agreement.<small>*</small></span>
                                            <p>{formData.Q00030}</p>
                                        </Grid>
                                        </>}
                                        {statuserror && <p style={{color:"Red",width:"100%"}}>{statuserror}</p>}
                                        {showedit && 
                                            <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                <button className='registersendbtn1' onClick={()=> setEditMode(true)}>Edit</button>
                                                <button className='registersendbtn2' onClick={handleslectChange} disabled={!showedit}>{submitloader2 ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Complete'}</button>
                                            </Grid>
                                        }
                                    </Grid>
                                    }
                                </Tab>
                                <Tab eventKey="assignment" title="Assignments">
                                    {activeTab === "assignment" && <Assignments />}
                                </Tab>
                                <Tab eventKey="discussions" title="Discussions">
                                    {activeTab === "discussions" && <TaskDisccuss />}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>
                                <Tab eventKey="history" title="History" style={{ flexGrow: 1 }}>
                                    {activeTab === "history" && <TaskHistory />}
                                </Tab>
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
            <Modal
                show={show} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 text-center flex-column align-items-center modal_register modal_statuscomplete">
                <FontAwesomeIcon className='statusicons' icon={faCircleExclamation} />
                    <p>
                    Are you sure you want to change the status to{" "}
                    <strong className='uppercasers'>{selectedStatus}</strong>?
                    </p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='registersendbtn1 my-2 mx-4' onClick={handleClose}>
                    Cancel
                </button>
                <button className='registersendbtn2 my-2' onClick={confirmStatusChange}>
                    Confirm
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default MemberConfirmation

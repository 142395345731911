import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./admin.css";
import "./rolesset.css";
import { faAngleRight, faChevronLeft, faChevronRight, faUserPlus, faFilter, faFilterCircleXmark, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdminRoletabs from '../../components/common/AdminRoletabs';
import { useNavigate } from 'react-router-dom';
import { faCircleDown } from '@fortawesome/free-regular-svg-icons';
import { apiService } from '../../services/apiService';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { useNavigate } from 'react-router-dom';
import { MenuItem, TextField } from '@mui/material';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
const profileImage = require('../../assets/defaultprofile.svg').default;
function UserMember() {
    const navigate = useNavigate();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));

    useEffect(() => {
        const fetchMembers = async () => {
        setLoading(true);
          try {
            const response = await apiService.getRequest('/admin/users');
            if(response.success === true){
                setMembers(response.data);
            }
          } catch (error) {
            console.error('Error fetching jobseeker status list:', error);
          }finally{
            setLoading(false);
          }
        };
        fetchMembers();
    }, []);

    const [isGridVisible, setGridVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [uniqueCityStatuses, setCitizenshipStatuses] = useState([]);
    // const handleShow = () => setShow(true);

    
    const toggleGrid = () => {
        setGridVisible((prev) => !prev);
    };

    const [filters, setFilters] = useState([{ operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);


    const combineOptions = [
        { value: 'And', label: 'And' },
        { value: 'Or', label: 'Or' },
    ];

    const rows = members.map((datas) => ({
        id: datas.id,
        userName: datas.name,
        userImage: datas.profile || profileImage,
        group: datas.roles.map(role => role.display_name).join(', '),
        contactPhone: datas.phone_number,
        contactEmail: datas.email,
        roles: datas.roles.map(role => ({
            display_name: role.display_name,
            color: role.color,
            bg_color: role.bg_color,
        }))
    }));

    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        // Reset value if changing the column type
        if (field === 'column') {
            newFilters[index].value = newFilters[index].column === 'date' ? [null, null] : '';
        }
        newFilters[index][field] = value;
        setFilters(newFilters);
    };

    const addFilter = () => {
        setFilters([...filters, { operator: 'And', column: 'userName', operatorType: 'contains', value: '' }]);
    };

    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiService.getRequest('admin/roles-list');
            if (response.success) {
                setCitizenshipStatuses(response.data);
            }
        };
        fetchData();
    }, []);
    const operatorOptions = [
        { value: 'contains', label: 'contains' },
        { value: 'doesNotContain', label: 'does not contain' },
        { value: 'startsWith', label: 'starts with' },
        { value: 'endsWith', label: 'ends with' },
        { value: 'is', label: 'is' },
        { value: 'isNot', label: 'is not' },
        { value: 'isEmpty', label: 'is empty' },
        { value: 'isNotEmpty', label: 'is not empty' },
    ];
    const getOperatorOptions = (column) => {
        if (column === 'group') {
            return [
                { value: 'contains', label: 'contains' },
                { value: 'doesNotContain', label: 'does not contain' },
                { value: 'startsWith', label: 'starts with' },
                { value: 'endsWith', label: 'ends with' },
                { value: 'is', label: 'is' },
                { value: 'isNot', label: 'is not' },
                { value: 'isOneOf', label: 'is one of' },
                { value: 'isNotOneOf', label: 'is not one of' },
                { value: 'isEmpty', label: 'is empty' },
                { value: 'isNotEmpty', label: 'is not empty' },
            ];
        }
        return operatorOptions; // Default options
    };
    
    const filteredRows = rows.filter((row) =>
        filters.reduce((acc, filter, index) => {
            const rowValue = row[filter.column] ? row[filter.column].toString() : '';
            let matches = false;
    
            if (filter.column === 'group') {
                switch (filter.operatorType) {
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':
                        matches = rowValue !== filter.value;
                        break;
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    case 'isOneOf': // Check if value is one of the provided options
                        matches = filter.value.split(',').includes(rowValue);
                        break;
                    case 'isNotOneOf': // Check if value is not one of the provided options
                        matches = !filter.value.split(',').includes(rowValue);
                        break;
                    default:
                        matches = false;
                }
            } else {
                // General filter logic for other columns
                switch (filter.operatorType) {
                    case 'contains':
                        matches = rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'doesNotContain':
                        matches = !rowValue.toLowerCase().includes(filter.value.toLowerCase());
                        break;
                    case 'startsWith':
                        matches = rowValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        break;
                    case 'endsWith':
                        matches = rowValue.toLowerCase().endsWith(filter.value.toLowerCase());
                        break;
                    case 'is':
                        matches = rowValue === filter.value;
                        break;
                    case 'isNot':   
                        matches = rowValue !== filter.value;
                        break;
                    case 'isEmpty':
                        matches = rowValue === '';
                        break;
                    case 'isNotEmpty':
                        matches = rowValue !== '';
                        break;
                    default:
                        matches = false;
                }
            }
    
            return index === 0 ? matches : (filter.operator === 'And' ? acc && matches : acc || matches);
        }, true)
    );
    const paginatedRows = filteredRows.slice(currentPage * pageSize, currentPage * pageSize + pageSize);

    const downloadCSV = () => {
        const csvData = filteredRows.map(row => ({  
            'Sub Admin': row.userName,
            'Group': row.roles.map(role => role.display_name).join(', '),
            'Email': row.contactEmail,
            'Phone Number': row.contactPhone,
        }));
        const csv = Papa.unparse(csvData); // Parse the data into CSV
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'filtered_jobseekers.csv'); // Trigger file download
    };
    const columns = [
        {
            field: 'userName',
            headerName: 'Sub Admin',
            width: 350,
            renderCell: (params) => (
                <div className='d-flex align-items-center h-100'>
                    <Avatar src={params.row.userImage} alt={params.row.userName} />
                    <span className='ms-3'>{params.row.userName}</span>
                </div>
            ),
            filterable: true
        },
        {
            field: 'group',
            headerName: 'Group',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box display="flex" alignItems="center" height="100%">
                {Array.isArray(params.row.roles) && params.row.roles.length > 0 ? (
                    params.row.roles.map((role, index) => (
                        <Typography
                            key={index}  
                            style={{
                                color: role.color,
                                backgroundColor: role.bg_color,
                                padding: '2px 15px', 
                                borderRadius: '100px',
                                marginRight: '10px',
                            }}
                            className="bg_dynaspangroupjsa"
                        >
                            {role.display_name}
                        </Typography>
                    ))
                ) : (
                    <Typography color="textSecondary">No Group</Typography>
                )}
            </Box>  
            ),
        },
        {
            field: 'contactPhone',
            headerName: 'Phone',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`tel:${params.row.contactPhone}`} target="_blank" rel="noopener noreferrer">{params.row.contactPhone}</a></span></Typography>
                </Box>
            ) 
        },
        {
            field: 'contactEmail',
            headerName: 'Email',
            width: 250,
            filterable: true,
            renderCell: (params) => (
                <Box className="linklistview h-100 d-flex align-items-center">
                    <Typography><span><a href={`mailto:${params.row.contactEmail}`} target="_blank" rel="noopener noreferrer">{params.row.contactEmail}</a></span></Typography>
                </Box>
            )
        },
        ...(permissionstest && permissionstest.manage_subadmin_writer
            ? [
            {
                field: 'action',
                headerName: 'Action',
                width: 200,
                renderCell: (params) => (
                    <Grid size={{ xs: 12 }} container alignItems="center" justifyContent="flex-start" className="gap-md-3 h-100 gap-2">
                        <button className='editbtnroot' onClick={() => navigate(`/user/member-edit/${params.row.id}`)}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                        <button className='trashbtnroot' onClick={() => handleShow(params.row.id)}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </Grid>
                )
            },
        ]
        : []
    ),
    ];

  
    const [show, setShow] = useState(false);
    const [selectedMemberId, setSelectedMemberId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = (memberId) => {
        setSelectedMemberId(memberId);
        setShow(true);
    };

    const handleDelete = async () => {  
        try {
            await apiService.deleteRequest(`/admin/users/${selectedMemberId}`); // Call DELETE API
            setMembers(members.filter(member => member.id !== selectedMemberId)); // Remove deleted member from state
            toast('Member deleted successfully!');
        } catch (err) {
            toast.error("Failed to delete member");
        }
        handleClose();
    };




  return (
    <div className='container-fluid px-0'>
        <div className='content_container'>
            <Grid size={{lg:12}} container className="main_jobseeker">
                <Grid size={{lg:12}} container alignItems="center"> 
                    <Grid size={{md:8,xs:12}} container flexDirection="column" className="jobseekerhead">
                        <h3>User</h3>
                        <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> User <FontAwesomeIcon className='angleright' icon={faAngleRight} /> Sub Admin</span>
                    </Grid>
                    <Grid size={{md:12,xs:12}} container justifyContent="space-between" alignItems="center" className="statusselectbtn my-4">
                        <Grid size={{sm:8,xs:12}} container>
                            <AdminRoletabs/>
                        </Grid> 
                        <Grid container alignItems="center">
                            {permissionstest && permissionstest.manage_subadmin_writer ? 
                            <button className="small_adsbtn" onClick={()=>navigate("/user/member-add")}>
                                <FontAwesomeIcon icon={faUserPlus} />
                            </button>:""}
                        </Grid>
                    </Grid>  
                </Grid>
                {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
                    <Box sx={{ height: 650, width: '100%' }} className="datatable_jobpagi">
                        {isGridVisible && (
                        <Box sx={{ mb: 2 }} className="my-4">
                            {filters.map((filter, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                    {index > 0 && (
                                        <select  
                                            label="Combine" 
                                            value={filter.operator}
                                            onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
                                            sx={{ mr: 1, minWidth: 80 }}
                                        >
                                            {combineOptions.map((option) => (
                                                <option key={option.value} value={option.value}>{option.label}</option>
                                            ))}
                                        </select>
                                    )}
                                    <TextField
                                        select  
                                        label="Column"
                                        value={filter.column}
                                        onChange={(e) => handleFilterChange(index, 'column', e.target.value)}
                                        sx={{ mr: 1, minWidth: 120 }}
                                    > 
                                        <MenuItem value="userName">Sub Admin</MenuItem>
                                        <MenuItem value="group">Group</MenuItem>
                                        <MenuItem value="contactEmail">Email</MenuItem>
                                        <MenuItem value="contactPhone">Phone Number</MenuItem>
                                    </TextField>
                                    <TextField
                                        select    
                                        label="Operator"
                                        value={filter.operatorType}
                                        onChange={(e) => handleFilterChange(index, 'operatorType', e.target.value)}
                                        sx={{ mr: 1, minWidth: 120 }}
                                    >
                                        {getOperatorOptions(filter.column).map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </TextField>
                                        {filter.column === 'group' ? (
                                            <select 
                                                label="Value"
                                                value={filter.value}
                                                onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                                sx={{ mr: 1, minWidth: 120 }}
                                            >
                                                <option value="" disabled>Select Group</option>
                                                {uniqueCityStatuses.map((status) => (
                                                <option key={status.id} value={status.name}>
                                                    {status.name}
                                                </option>
                                                ))}
                                            </select>
                                        ) : (
                                            <input
                                                className='value_inputfilter'
                                                placeholder='Value'
                                                label="Value"  
                                                value={filter.value}
                                                onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                                            />
                                        )}
                                    <button className='filterremovebtn' onClick={() => removeFilter(index)}>Remove</button>
                                </Box>  
                            ))}
                            <button className='filteraddbtn' onClick={addFilter}>Add Filter</button>
                        </Box>)}
                        <Grid size={{xs:12}} container justifyContent="space-between">
                        <button className='show_filterbtn' onClick={toggleGrid}>
                            {isGridVisible ?<> Hide Filter <FontAwesomeIcon icon={faFilterCircleXmark} /> </> :<> Show Filter <FontAwesomeIcon icon={faFilter} /> </>}
                        </button>
                        <button className='download_csvbtndatatable' onClick={downloadCSV}>Download CSV<FontAwesomeIcon className='ps-2' icon={faCircleDown} /></button>
                        </Grid>
                        <DataGrid
                            rows={paginatedRows}
                            columns={columns}  
                            pageSize={pageSize}  
                            disableSelectionOnClick
                            components={{ Toolbar: GridToolbar }}
                        />
                        <div className='d-flex flex-column my-3 pagination_datagrid'>
                            <div className='d-flex justify-content-end align-items-center'>
                                <label>No. of Rows</label>
                                <select
                                    label="Rows per page"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    sx={{ mr: 2 }}
                                >
                                    {[5, 10, 20, 50].map((size) => (
                                        <option key={size} value={size}>{size}</option>
                                    ))}   
                                </select>
                            </div>

                            <div className="d-flex justify-content-center my-3">
                                <button
                                    className='nexter_btns'
                                    variant="contained"
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                                    disabled={currentPage === 0}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </button>

                                {/* Page number buttons */}
                                <div className="d-flex align-items-center mx-2">
                                    {Array.from({ length: Math.ceil(filteredRows.length / pageSize) }, (_, index) => (
                                        <button
                                            key={index}  
                                            className={`page-btn ${currentPage === index ? 'active' : ''}`}
                                            onClick={() => setCurrentPage(index)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))} 
                                </div> 
 
                                <button  
                                    className='nexter_btns'
                                    variant="contained"
                                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(filteredRows.length / pageSize) - 1))}
                                    disabled={currentPage >= Math.ceil(filteredRows.length / pageSize) - 1}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </button>
                            </div>
                        </div>
                    </Box>
                </>}
            </Grid>   
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this Sub Admin?</Modal.Body>
                <Modal.Footer>
                    <button className='modal_cancel' onClick={handleClose}>
                        Cancel
                    </button>
                    <button className='modal_delete' onClick={handleDelete}>
                        Delete
                    </button>   
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default UserMember

import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2';
import "./validations.css"
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
// import ValidateCats from '../../../../components/common/ValidateCats';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// import { toast } from 'react-toastify';
import { apiService } from '../../../../services/apiService';
import { toast } from 'react-toastify';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import Assignments from './Assignments';
import TaskDisccuss from './TaskDisccuss';
import TaskKnowledge from './TaskKnowledge';
import TaskHistory from './TaskHistory';
function I94Validation() {
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const { userid } = useParams();
    const [value, setValue] = useState(dayjs(null));
    const navigate = useNavigate();
    const taskData = localStorage.getItem('onBoardTaskID');
    const [showform, setShowform] = useState(true);
    const [showedit, setShowedit] = useState(true);
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [status, setStatus] = useState("");
    const [jobseekerName, setJobseekername] = useState("");
    const [jobseekerid, setJobseekerid] = useState("");
    const [caseids, setcaseid] = useState("");
    const [taskids, settaskid] = useState("");
    const [i94copy, seti94copy] = useState("");
    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await apiService.getRequest(`/onboarding/${onboardslug}/${userid}`);
            if (response.success && response.success === true) {
                localStorage.setItem('onBoardTaskID', response.data.task.id);
                seti94copy(response.data.i94_copy)
                setJobseekername(response.data.jobseeker.name);
                setJobseekerid(response.data.jobseeker.id);
                setcaseid(response.data.task.case_id);
                settaskid(response.data.task.task_format_id);
                setStatus(response.data.status_string);
                const refetchedData = response.data.form_data;
                setFileNames({
                    i94_document: refetchedData.i94_document || null,
                })
                setFormData({
                    valid_i94_doc: refetchedData.valid_i94_doc || "",
                    passport_number: refetchedData.passport_number || "",
                    jobseeker_type: refetchedData.jobseeker_type || "",
                    first_name:refetchedData.first_name || "",
                    last_name: refetchedData.last_name || "",
                    dob: refetchedData.dob || "",
                    i94_document: refetchedData.i94_document || null,
                });
              if (refetchedData.dob) {
                const parsedDob = dayjs(refetchedData.dob, 'YYYY-MM-DD');
                if (parsedDob.isValid()) {
                    setValue(parsedDob); 
                }
            }
                if (
                    ((response && response.data.form_data && Object.keys(response.data.form_data).length === 0) ||
                    (response && response.data.form_data && (!response.data.form_data.valid_i94_doc || Object.keys(response.data.form_data.valid_i94_doc).length === 0))) 
                    && (response && response.data.status_string === "in-progress")
                ){
                setShowform(true);
              }else{
                if(response && response.data.form_data && response.data.form_data.valid_i94_doc === "Yes"){
                    setOpendata(true);
                }else{
                    setOpendata(false);
                }
                setShowform(false);
              }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
    }, [onboardslug, userid]);

    useEffect(() => {
        const fetchCurrentData = async () => {
          try {
            const response = await apiService.getRequest(`/current-onboarding/${userid}`);
            if (response.success && response.success === true) {
                if(response && Array.isArray(response.onboarding) && response.onboarding.includes(onboardslug) && response.status && response.status["i94-validation"] === "in-progress"){
                    setShowedit(true);
                }else{
                    setShowedit(false);
                }
            }
          } catch (error) {
            console.error("Error fetching onboarding data:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchCurrentData();
    }, [ userid,onboardslug ]);

    const [fileNames, setFileNames] = useState({
        i94_document: "",
    });
    const [formData, setFormData] = useState({
        valid_i94_doc: "",
        passport_number: "",
        jobseeker_type: "",
        first_name:"",
        last_name: "",
        dob: "",
        i94_document: null,
    });
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [opendata, setOpendata] = useState(false);
       // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

        // Validate valid_i94_doc
        if (name === 'valid_i94_doc') {
            if (value === 'Yes') {
                setOpendata(true);
            } else {
                setOpendata(false);
                // setFormData(prevData => ({
                //     ...prevData,
                //     passport_number: "",
                //     first_name:"",
                //     last_name: "",
                //     dob: "",
                //     i94_document: null,
                // }));
                // setValue(dayjs(null));
                // setFileNames({
                //     i94_document: "",
                // });
            }
        }
    };
  // Handle file change
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
        const file = files[0]; // Get the selected file

        // Update the formData state with the new file
        setFormData((prevData) => ({
            ...prevData,
            [name]: file, // Store the file object for submission
        }));
        
        // Update the fileNames state to show the file name
        setFileNames((prevNames) => ({
            ...prevNames,
            [name]: file.name, // Store the file name for display
        }));
        setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[name]; // Remove the error for the file field
            return updatedErrors;
        });
    }
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!formData.valid_i94_doc) newErrors.valid_i94_doc = "Please select Yes or No.";
    if (opendata) {
        if (!formData.jobseeker_type) newErrors.jobseeker_type = "Jobseeker type is required.";
        if (!formData.passport_number) newErrors.passport_number = "Passport number is required.";
        if (!formData.first_name) newErrors.first_name = "First name is required.";
        if (!formData.last_name) newErrors.last_name = "Last name is required.";
        if (!formData.dob) newErrors.dob = "Date of birth is required.";
        if (!formData.i94_document) newErrors.i94_document = "Please upload the I-94 document.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleDateChange = (newValue) => {
    if (newValue) {
        setValue(newValue);
        setFormData({ ...formData, dob: newValue.format('YYYY-MM-DD') });

        // Clear the error for dob if a valid date is entered
        if (errors.dob) {
            setErrors({ ...errors, dob: '' });
        }
    } else {
        setValue(null);
        setFormData({ ...formData, dob: '' }); // Clear the value if null
    }
};
    const [submitloader,setSubmitloader] = useState(false)
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setErrorMessage('Please fix the errors before proceeding.');
        return;
    }
    setSubmitloader(true);
    try {
        const formDataToSend = new FormData();
        if (formData.valid_i94_doc === "No") {
            formDataToSend.append('valid_i94_doc', formData.valid_i94_doc);
        } else {
            Object.keys(formData).forEach((key) => {
                const value = formData[key];

                // Only skip appending if the field is a string and is one of the file fields
                if (value !== null && !(typeof value === 'string' && ['i94_document'].includes(key))) {
                    formDataToSend.append(key, value);
                }
            });
        }

        // Make the API request
        const response = await apiService.postRequest(`onboarding/${onboardslug}/${userid}/submit`, formDataToSend);

        // Check response for success
        if (response.success) {
            setErrors({});
            setErrorMessage('');
            setEditMode(false);
            setShowform(false);
            if(response.success && response.status === "rejected"){
                window.location.reload();
            }
        } else {
            setErrorMessage(response.message);
            if (response.errors) {
              setErrors(response.errors);
            }
        }
    } catch (error) {
        setErrorMessage('Failed. Please try again.');
    } finally {
        setSubmitloader(false); // Stop loader
    }
  };
  

  const [selectedStatus, setSelectedStatus] = useState("");
  const [show, setShow] = useState(false);

  const handleslectChange = () => {
    setSelectedStatus("completed");
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const [statuserror,setStatuserror] = useState("");
    const [submitloader2,setSubmitloader2] = useState(false);
      const confirmStatusChange = async () => {
      setShow(false); // Close the modal
      setStatus(selectedStatus); // Update the status locally
      setSubmitloader2(true);
      try {
        const response = await apiService.postRequest(
          `/task/update/status/${taskData}`,
          {
            status: selectedStatus,
          }
        );
        if (response && response.success === true) {
          toast("Status updated successfully!");
          window.location.reload();
          setStatuserror("");
        }else{
          setStatuserror(response.message);
          setStatus("in-progress");
        }
      } catch (error) {
        console.error("Error updating status:", error);
        toast.error("Failed to update status. Please try again.");
      }finally {
          setSubmitloader2(false); // Stop loader
      }
    };

  return (
    <div className='container-fluid px-0' >
        {/* <div className={isVisible ? 'hide_mobitr' : 'show_mobitr'}>
            <ValidateCats onClose={closeElement}/>
        </div> */}
        <div className='content_container_validate' style={{ flexGrow: 1 }}>
            <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead mb-3">
                <span><b onClick={()=>navigate("/dashboard")}>Dashboard</b> <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {caseids} <FontAwesomeIcon className='angleright' icon={faAngleRight} /> {taskids}</span>
            </Grid>
            <Grid size={{sm:12}} container className="main_jobseeker">
                <Grid size={{sm:12}} container alignItems="center">
                    <Grid size={{md:12,xs:12}} container flexDirection="column" className="jobseekerhead border_botomer mario_fixers">
                        <h3>I94 Validation <small className='small_headingstatuses'>{status&&status === "completed" ? "Completed":status === "rejected"? "Rejected":"In Progress"}</small></h3>
                        <a className='seekerondetail' href={`/user/jobseeker-profile/${jobseekerid}`} target="_blank" rel="noopener noreferrer">{jobseekerName}</a>
                    </Grid>
                    {loading ? 
                        <div className='d-flex justify-content-center w-100'>
                            <img style={{ width: "100px" }} src={require('../../../../assets/pinkloader.gif')} alt='Loading...' />
                        </div> :<>
                        <Grid size={{xs:12}} container flexDirection="column" justifyContent="center" className="tab_controllers">
                            <Tabs
                                activeKey={activeTab}
                                onSelect={(key) => setActiveTab(key)}
                                defaultActiveKey="overview"
                                id="uncontrolled-tab-example" className="mt-4 drafttabs_tabs"
                                >
                                <Tab eventKey="overview" title="Overview">
                                    
                                        {(showform && showform === true) || (editMode && editMode === true) ? <>
                                        <Grid size={{xs:12}} container justifyContent="flex-start" className="mt-4">
                                            <div className='d-flex align-items-start note_bgyesno w-100'>
                                                <b>Note:</b>
                                                <span className='d-flex flex-column'>
                                                    <small>“If you says yes, you are granting approval for Jobseeker to proceed further.”</small>
                                                    <small>“If you says No, you are declining permission for Jobseeker to continue.”</small>
                                                </span>
                                            </div>
                                        </Grid>
                                        <form className='w-100' 
                                            onSubmit={handleSubmit}
                                            >
                                            
                                            <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="regiter_inputs i94validateinput">
                                                <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                                    <span>Did Jobseeker Submit a valid I94 document in the application ? <small>*</small><a className='view_fileshows' href={`${i94copy}`} target="_blank" rel="noopener noreferrer">View</a></span>
                                                    <div className='d-flex align-items-center main_radioregister'>
                                                        <input
                                                            type="radio" 
                                                            name="valid_i94_doc"
                                                            value="Yes"
                                                            checked={formData.valid_i94_doc  === "Yes"} 
                                                            onChange={handleInputChange} 
                                                        />Yes
                                                        <input
                                                            type="radio" 
                                                            name="valid_i94_doc"
                                                            value="No"
                                                            checked={formData.valid_i94_doc  === "No"} 
                                                            onChange={handleInputChange} 
                                                        />No
                                                    </div>
                                                    {errors.valid_i94_doc && <p style={{color:"Red"}}>{errors.valid_i94_doc}</p>}
                                                </Grid>
                                                {opendata && <>
                                                    <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                        <span>Passport Number of Jobseeker<small>*</small></span>
                                                        <input
                                                            type="text"
                                                            name="passport_number"
                                                            value={formData.passport_number}
                                                            onChange={handleInputChange} 
                                                            // onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                        />
                                                        {errors.passport_number && <p style={{color:"Red"}}>{errors.passport_number}</p>}
                                                    </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>Jobseeker type<small>*</small></span>
                                                    <select name="jobseeker_type" 
                                                    value={formData.jobseeker_type}
                                                    onChange={handleInputChange} 
                                                    >
                                                        <option value="">Select Type</option>
                                                        <option value="Existing">Existing</option>
                                                        <option value="New">New</option>
                                                    </select>
                                                    {errors.jobseeker_type && <p style={{color:"Red"}}>{errors.jobseeker_type}</p>}
                                                </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>First name of the Jobseeker as per I-94<small>*</small></span>
                                                    <input
                                                        type="text"
                                                        name="first_name"
                                                        value={formData.first_name}
                                                        onChange={handleInputChange} 
                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                    />
                                                    {errors.first_name && <p style={{color:"Red"}}>{errors.first_name}</p>}
                                                </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>Last name of the Jobseeker as per I-94<small>*</small></span>
                                                    <input
                                                        type="text"
                                                        name="last_name"
                                                        value={formData.last_name}
                                                        onChange={handleInputChange} 
                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, '')}
                                                    />
                                                    {errors.last_name && <p style={{color:"Red"}}>{errors.last_name}</p>}
                                                </Grid>
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                    <span>Date of Birth of the Jobseeker as per I-94<small>*</small></span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                className="date_pickermux"
                                                                value={value}
                                                                onChange={handleDateChange}
                                                                maxDate={dayjs().subtract(18, 'year').subtract(1, 'day')}
                                                                renderInput={(params) => <input {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                        {errors.dob && <p style={{color:"Red"}}>{errors.dob}</p>}
                                                </Grid>                            
                                                <Grid size={{md:5.5,sm:5.8, xs:12}} container alignItems="flex-end" flexDirection="column">
                                                    <span>Upload the latest I-94 of the Jobseeker<small>*</small></span>
                                                    <div className="fileUploadInput">
                                                        <input
                                                            type="file"
                                                            name="i94_document"
                                                            id="i94_document"
                                                            onChange={handleFileChange}
                                                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                                                            style={{ display: 'none' }} // Hide the actual file input
                                                        />
                                                        <label htmlFor="i94_document" style={{ cursor: 'pointer' }}>Upload</label>
                                                        <input
                                                            type="text"
                                                            value={fileNames.i94_document || ''}
                                                            readOnly
                                                            placeholder="No file chosen"
                                                        />
                                                    </div>
                                                    {errors.i94_document && <p style={{color:"Red" , width:"100%"}}>{errors.i94_document}</p>}
                                                </Grid>
                                                </>}
                                                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                                                <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                    <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Save'}</button>
                                                    {editMode && 
                                                        <button className='registersendbtn1' onClick={()=> setEditMode(false)}>cancel</button>
                                                    }
                                                    {/* <button type='submit' className='registersendbtn2'>{submitloader ? <img src={require('../../../assets/whiteloader.gif')} alt=''/> : 'Send Request'}</button> */}
                                                </Grid>
                                            </Grid>
                                        </form>
                                        </> :

                                        <Grid size={{lg:12,xs:12}} container justifyContent="space-between" className="i94viewvalidate">
                                            <Grid size={{sm:12}} container className="my-3" flexDirection="column">
                                                <span>Did Jobseeker Submit a valid I94 document in the application ?<small>*</small> <a className='view_fileshows' href={`${i94copy}`} target="_blank" rel="noopener noreferrer">View</a></span>
                                                <p>{formData.valid_i94_doc}</p>
                                            </Grid>
                                            {opendata && <>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Passport Number of Jobseeker<small>*</small></span>
                                                <p>{formData.passport_number}</p>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Jobseeker type<small>*</small></span>
                                                <p>{formData.jobseeker_type}</p>
                                            </Grid>
                                            
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>First name of the Jobseeker as per I-94<small>*</small></span>
                                                <p>{formData.first_name}</p>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Last name of the Jobseeker as per I-94<small>*</small></span>
                                                <p>{formData.last_name}</p>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Date of Birth of the Jobseeker as per I-94<small>*</small></span>
                                                <p>{formData.dob}</p>
                                            </Grid>
                                            <Grid size={{md:5.5,sm:5.8, xs:12}} container flexDirection="column">
                                                <span>Upload the latest I-94 of the Jobseeker<small>*</small></span>
                                                <a href={`${fileNames.i94_document}`} target="_blank" rel="noopener noreferrer"><b>View</b></a>
                                            </Grid>
                                            </>}
                                            {statuserror && <p style={{color:"Red",width:"100%"}}>{statuserror}</p>}
                                            {showedit && 
                                                <Grid size={{sm:12, xs:12}} container className="mt-2 gap-md-5 gap-3">
                                                    <button className='registersendbtn1' onClick={()=> setEditMode(true)}>Edit</button>
                                                    <button className='registersendbtn2' onClick={handleslectChange} disabled={!showedit}>{submitloader2 ? <img src={require('../../../../assets/whiteloader.gif')} alt=''/> : 'Complete'}</button>
                                                </Grid>
                                            }
                                        </Grid>
                                        }
                                        
                                </Tab>
                                <Tab eventKey="assignment" title="Assignments">
                                    {activeTab === "assignment" && <Assignments />}
                                </Tab>
                                <Tab eventKey="discussions" title="Discussions">
                                    {activeTab === "discussions" && <TaskDisccuss />}
                                </Tab>
                                <Tab eventKey="knowledge" title="Knowledge">
                                    {activeTab === "knowledge" && <TaskKnowledge />}
                                </Tab>
                                <Tab eventKey="history" title="History" style={{ flexGrow: 1 }}>
                                    {activeTab === "history" && <TaskHistory />}
                                </Tab>
                            </Tabs>
                        </Grid>
                    </>}
                </Grid>
            </Grid>
            <Modal
                show={show} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton style={{border:"none"}}>
                    
                </Modal.Header>
                <Modal.Body>
                <div className="d-flex w-100 text-center flex-column align-items-center modal_register modal_statuscomplete">
                <FontAwesomeIcon className='statusicons' icon={faCircleExclamation} />
                    <p>
                    Are you sure you want to change the status to{" "}
                    <strong className='uppercasers'>{selectedStatus}</strong>?
                    </p>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <button className='registersendbtn1 my-2 mx-4' onClick={handleClose}>
                    Cancel
                </button>
                <button className='registersendbtn2 my-2' onClick={confirmStatusChange}>
                    Confirm
                </button>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  )
}

export default I94Validation

import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';

function ValidateCats({onClose}) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const onboardslug = localStorage.getItem('onboardingSlugs');
    const taskId = localStorage.getItem('onBoardTaskID');
    const onbUserId = localStorage.getItem('onboardUserId');
    const Role_user = localStorage.getItem('role');
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
    // Static Slugs
    const i94_validation = "i94-validation";
    const verify_member_details = "verify-member-details";
    const member_confirmation = "member-confirmation";
    const referral_investigation = "referral-investigation";
    const engagement_overview = "engagement-overview";
    const jobseeker_classification = "jobseeker-classification";
    const setup_interview = "setup-servicenow-interview";
    const interview_evaluation = "servicenow-interview-evaluation";
    const past_payment_health = "past-payment-health-approval";
    const app_final_review = "application-final-review";
    const placement_agreement = "placement-agreement";
    const communication_eligibility = "communication-eligibility";
    const admission_fees_payment = "admission-fees-payment";

    const [overviewuserId,setOverviewUserid] = useState([]);
    const [currentOnboard,setCuurentOnboard] = useState('');
    const [onboardStatus,setOnboardingStatus] = useState('');
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await apiService.getRequest(`task/details/${taskId}`);
          if (response.success && response.success === true) {
            setOverviewUserid(response.data.onboarding_user_id);
          }
        } catch (error) {
          console.error("Error fetching onboarding data:", error);
        }finally {
          setLoading(false);
        }
      };
  
      fetchData();
  }, [taskId,navigate]);

  useEffect(() => {
    const fetchCurrentData = async () => {
      try {
        const response = await apiService.getRequest(`/current-onboarding/${onbUserId}`);
        if (response.success && response.success === true) {
            setCuurentOnboard(response.onboarding);
            setOnboardingStatus(response.status);
        }
      } catch (error) {
        console.error("Error fetching onboarding data:", error);
      }
    };

    fetchCurrentData();
}, [ onbUserId ,navigate]);
  return (
    <div>
      <div className='validate_dragers'>
            <div className='d-flex w-100 justify-content-end'>
                <button className='d-md-none closecat_btn' onClick={onClose}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
            {loading ? 
                    <div className='d-flex justify-content-center w-100'>
                        <img style={{ width: "100px" }} src={require('../../../src/assets/pinkloader.gif')} alt='Loading...' />
                    </div> :<>
            {onboardslug && onboardslug === "my_request_profile" ?
            <div className='d-flex flex-column'>
                <h5>Profile Correction</h5>
                <span><img src={require('../../assets/circlecheck.svg').default} alt=""/>Submit Corrections</span>
                <span><img src={require('../../assets/circleprocess.svg').default} alt=""/>PRCA Review</span>
            </div>
            :<>
              <div className='d-flex flex-column'>
                  <h5>Signup</h5>
                  <a href='/termsofservice' target="_blank" rel="noopener noreferrer"><img src={require('../../assets/circlecheck.svg').default} alt=""/>Terms & Conditions</a>
                  <span onClick={() =>{
                      if (!(Role_user && Role_user === "job_seeker") && (permissionstest.manage_i94_validation === true) && onboardStatus?.["i94-validation"]) {
                      navigate(`/task/i94-validation/${overviewuserId}`);
                      localStorage.setItem('onboardingSlugs', i94_validation);}}}
                      className={
                          ((Role_user && Role_user === "job_seeker") || (permissionstest.manage_i94_validation !== true) || (!onboardStatus?.["i94-validation"])) ? 'span_disabledrager' : ""}
                      >
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation) && onboardStatus?.["i94-validation"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation) && onboardStatus?.["i94-validation"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["i94-validation"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    I94 Validation</span>
              </div>
              <div className='d-flex flex-column border_catsvalidate'>
                  <h5>Referral Management</h5>
                  <span className={
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) ||
                      (Role_user && Role_user === "job_seeker") || (permissionstest.manage_verify_member_details !== true) || (!onboardStatus?.["verify-member-details"])
                      ? 'span_disabledrager' : ""}
                    onClick={() =>{
                      if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (Role_user && Role_user === "job_seeker") || (permissionstest.manage_verify_member_details !== true) || (!onboardStatus?.["verify-member-details"])) {
                      }else{
                        localStorage.setItem('onboardingSlugs', verify_member_details);
                          navigate(`/task/verify-member/${overviewuserId}`);
                      }
                    }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details) && onboardStatus?.["verify-member-details"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details) && onboardStatus?.["verify-member-details"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["verify-member-details"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    Verify Referral Details</span>
                  <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (permissionstest.manage_member_confirmation !== true) || (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["member-confirmation"]) ? 'span_disabledrager' : ""}
                    onClick={() =>{
                      if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (!onboardStatus?.["member-confirmation"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details))||
                      (Role_user && Role_user === "job_seeker") || (permissionstest.manage_member_confirmation !== true)
                  ) {
                          
                      }else{
                        localStorage.setItem('onboardingSlugs', member_confirmation);
                          navigate(`/task/member-confirmation/${overviewuserId}`);
                      }
                    }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation) && onboardStatus?.["member-confirmation"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation) && onboardStatus?.["member-confirmation"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["member-confirmation"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    
                    Referrer Confirmation</span>
                    {currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation) && (permissionstest.manage_referral_investigation === true) ?
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["referral-investigation"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(placement_agreement)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(admission_fees_payment)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review) )
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["referral-investigation"]) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview) )||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(placement_agreement)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(admission_fees_payment)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review))) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', referral_investigation);
                              navigate(`/task/referral-investigation/${overviewuserId}`);
                          }
                      }}>

                      {(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker")|| (!onboardStatus?.["referral-investigation"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview) ) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(placement_agreement)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(admission_fees_payment)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ?
                          <img src={require('../../assets/circlelock.svg').default} alt=""/> :
                          currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation) && onboardStatus?.["referral-investigation"] !== "rejected" ? 
                          <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                          currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation) && onboardStatus?.["referral-investigation"] === "rejected"  ? 
                          <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                          <img src={require('../../assets/circlecheck.svg').default} alt=""/>
                      }
                    
                    Investigation</span>
                  :""}
              </div>
              <div className='d-flex flex-column border_catsvalidate'>
                  <h5>Service Selection</h5>
                  <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["engagement-overview"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation) ) || (permissionstest.manage_engagement_overview !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (permissionstest.manage_engagement_overview !== true) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["engagement-overview"]) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation) )) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', engagement_overview);
                              navigate(`/task/engagement-overview/${overviewuserId}`);
                          }
                      }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview) && onboardStatus?.["engagement-overview"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview) && onboardStatus?.["engagement-overview"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["engagement-overview"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    Engagement Overview</span>
              </div>
              <div className='d-flex flex-column border_catsvalidate'>
                  <h5>Placement Onboarding</h5>
                  <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["jobseeker-classification"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview) ) || (permissionstest.manage_jobseeker_classification !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["jobseeker-classification"]) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) || (permissionstest.manage_jobseeker_classification !== true) ) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', jobseeker_classification);
                              navigate(`/task/jobseekers-classification/${overviewuserId}`);
                          }
                      }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification) && onboardStatus?.["jobseeker-classification"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification) && onboardStatus?.["jobseeker-classification"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["jobseeker-classification"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    Jobseeker Classification</span>
                    
                  <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) ||
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["setup-servicenow-interview"]) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification) ) || (permissionstest.manage_setup_servicenow_interview !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["setup-servicenow-interview"]) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification) ) || (permissionstest.manage_setup_servicenow_interview !== true)) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', setup_interview);
                              navigate(`/task/setup-interview/${overviewuserId}`);
                          }
                      }}>
                      { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview) && onboardStatus?.["setup-servicenow-interview"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview) && onboardStatus?.["setup-servicenow-interview"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["setup-servicenow-interview"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    
                    Setup ServiceNow Interview</span>
                  <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["servicenow-interview-evaluation"]) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( setup_interview) ) || (permissionstest.manage_servicenow_interview_evaluation !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["servicenow-interview-evaluation"]) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( setup_interview) ) || (permissionstest.manage_servicenow_interview_evaluation !== true)) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', interview_evaluation);
                              navigate(`/task/servicenow-interview-evaluation/${overviewuserId}`);
                          }
                      }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation) && onboardStatus?.["servicenow-interview-evaluation"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation) && onboardStatus?.["servicenow-interview-evaluation"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["servicenow-interview-evaluation"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }

                    ServiceNow Interview Evaluation</span>
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["past-payment-health-approval"]) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( setup_interview) ) || (permissionstest.manage_past_payment_health_approval !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["past-payment-health-approval"]) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( setup_interview) ) || (permissionstest.manage_past_payment_health_approval !== true)) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', past_payment_health);
                              navigate(`/task/past-payment-health/${overviewuserId}`);
                          }
                      }}>
                      { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) && onboardStatus?.["past-payment-health-approval"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) && onboardStatus?.["past-payment-health-approval"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["past-payment-health-approval"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    
                    Past Payment Health</span>
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["application-final-review"]) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) || (permissionstest.manage_application_final_review !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["application-final-review"]) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification))||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) || (permissionstest.manage_application_final_review !== true)) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', app_final_review);
                              navigate(`/task/application-final-review/${overviewuserId}`);
                          }
                      }}>
                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review) && onboardStatus?.["application-final-review"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review) && onboardStatus?.["application-final-review"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["application-final-review"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }

                    Application Final Review</span>
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || (!onboardStatus?.["placement-agreement"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review) ) || (permissionstest.manage_placement_agreement !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (!onboardStatus?.["placement-agreement"]) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification))||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review) ) || (permissionstest.manage_placement_agreement !== true)
                        ) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', placement_agreement);
                              navigate(`/task/placement-agreements/${overviewuserId}`);
                          }
                      }}>

                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(placement_agreement) && onboardStatus?.["placement-agreement"] !== "rejected" && (onboardStatus?.["placement-agreement"] !== "completed") ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(placement_agreement) && onboardStatus?.["placement-agreement"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["placement-agreement"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }
                    
                    Placement Agreement</span>
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["communication-eligibility"]) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( placement_agreement) ) || (permissionstest.manage_communication_eligibility !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                          (Role_user && Role_user === "job_seeker") || (!onboardStatus?.["communication-eligibility"]) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification))||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( placement_agreement) ) || (permissionstest.manage_communication_eligibility !== true)
                        ) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', communication_eligibility);
                              navigate(`/task/communication-eligibility/${overviewuserId}`);
                          }
                      }}>

                    { currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility) && onboardStatus?.["communication-eligibility"] !== "rejected" ?
                      <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                      currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility) && onboardStatus?.["communication-eligibility"] === "rejected"  ? 
                      <img src={require('../../assets/circleclose.svg').default} alt=""/> :
                      (onboardStatus?.["communication-eligibility"] === "completed") ?
                      <img src={require('../../assets/circlecheck.svg').default} alt=""/> :
                      <img src={require('../../assets/circlelock.svg').default} alt=""/>
                    }

                    Communication Eligibility</span>
                    <span className={(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || (!onboardStatus?.["admission-fees-payment"]) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( placement_agreement) ) || (permissionstest.manage_admission_fees_payment !== true)
                    ? 'span_disabledrager' : ""}
                      onClick={() =>{
                          if ((currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || (!onboardStatus?.["admission-fees-payment"]) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification))||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health) ) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) ||
                          (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( placement_agreement) ) || (permissionstest.manage_admission_fees_payment !== true)
                        ) {
                              
                          }else{
                          localStorage.setItem('onboardingSlugs', admission_fees_payment);
                              navigate(`/task/admission-fees-payment/${overviewuserId}`);
                          }
                      }}>
                        
                      {(currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(i94_validation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(verify_member_details)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(member_confirmation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(referral_investigation)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(engagement_overview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(jobseeker_classification)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(setup_interview)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(interview_evaluation)) || 
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(past_payment_health)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(app_final_review)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(communication_eligibility)) ||
                      (currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes( placement_agreement) 
                    ) ? 
                    <img src={require('../../assets/circlelock.svg').default} alt=""/> :
                    currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(admission_fees_payment) && (onboardStatus?.["admission-fees-payment"] !== "rejected" &&
                      onboardStatus?.["admission-fees-payment"] !== "completed") ? 
                    <img src={require('../../assets/circleprocess.svg').default} alt=""/> :
                    onboardStatus?.["admission-fees-payment"] === "rejected"  ? 
                    <img src={require('../../assets/circleclose.svg').default} alt=""/> : 
                    currentOnboard && Array.isArray(currentOnboard) && currentOnboard.includes(admission_fees_payment) && onboardStatus?.["admission-fees-payment"] === "completed" ? 
                    <img src={require('../../assets/circlecheck.svg').default} alt=""/> : ""
                      }
                    Admission Fees</span>
              </div>
            </>}
            </>}
        </div>
    </div>
  )
}

export default ValidateCats

import React from 'react'
import Grid from '@mui/material/Grid2';
import "./common.css"
import { useLocation, useNavigate } from 'react-router-dom';
function AdminRoletabs() {
    const location = useLocation();
    const navigate = useNavigate()
    const currentPath = location.pathname;
    const permissionstest = JSON.parse(localStorage.getItem('permissions'));
  return (
    <>  
        <Grid size={{xs:12}} container justifyContent="flex-start">
            <div className='admintab_btnredirect'>
              {(permissionstest && permissionstest.manage_users === true && permissionstest.manage_jobseeker_reader === true) || (permissionstest && permissionstest.manage_users === true && permissionstest.manage_jobseeker_writer === true) ? 
                  <button className={currentPath === "/user/jobseekers" ? "active_tabbtn" : ""} onClick={() =>{navigate("/user/jobseekers")}}>Jobseekers</button>
              : ""}
              {(permissionstest && permissionstest.manage_users === true && permissionstest.manage_subadmin_reader === true) || (permissionstest && permissionstest.manage_users === true && permissionstest.manage_subadmin_writer === true) ? 
                  <button className={currentPath === "/user/member" ? "active_tabbtn" : ""} onClick={() =>{navigate("/user/member")}}>Sub Admin</button>
              :""}
              {permissionstest && permissionstest.manage_users === true && permissionstest.manage_group_reader === true ? 
                  <button className={currentPath === "/user/group" ? "active_tabbtn" : ""} onClick={() =>{navigate("/user/group")}} >Group</button>
              :""}
            </div>
        </Grid>  
    </>
  )
}

export default AdminRoletabs
